import ResponseMapperBase from './ResponseMapperBase';

export default class OpenQuestionResponseMapper extends ResponseMapperBase {
  /**
   * @param {object} question
   */
  map({ isAnswered, response }: any) {
    return isAnswered ? response.answeredText : undefined;
  }

  /**
   * @param {object} userAnswer
   * @param {object} question
   */
  unMap(userAnswer: any, { id }: any) {
    return {
      id,
      answeredText: userAnswer
    };
  }
}
