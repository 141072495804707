import initialState from 'store/initialState';
import { ActionTypes, SectionsActionTypes } from './types';

export default function sectionsReducer(
  state = initialState.sections,
  action: SectionsActionTypes
) {
  switch (action.type) {
    case ActionTypes.SECTIONS_LOADED:
      return { ...state, ...action.payload };
    case ActionTypes.SECTION_SCORE_UPDATED:
      state[action.payload.id] = {
        ...state[action.payload.id],
        score: action.payload.score,
        status: action.payload.status
      };
      return { ...state };
    case ActionTypes.SECTION_IS_PASSED:
      state[action.payload.id] = {
        ...state[action.payload.id],
        status: action.payload.status
      };
      return { ...state };
    case ActionTypes.SECTION_ANSWERED:
      state[action.payload.id] = {
        ...state[action.payload.id],
        isSectionAnswered: action.payload.isSectionAnswered
      };
      return { ...state };
    case ActionTypes.SECTION_ANSWERED_SCORE:
      state[action.payload.id] = {
        ...state[action.payload.id],
        sectionProgress: action.payload.sectionProgress
      };
      return { ...state };
    default:
      return state;
  }
}
