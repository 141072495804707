import ResponseMapperBase from './ResponseMapperBase';

export default class RankingTextResponseMapper extends ResponseMapperBase {
  mapResponse(question: any) {
    if (!this.hasQuestionResponse(question)) {
      return [];
    }

    return question.response.map((item: any) => item.text);
  }

  /**
   * @param {object} userAnswer
   * @param {object} question
   */
  unMap(userAnswer: any, { answers }: any) {
    if (userAnswer === 100) {
      return answers;
    }

    if (this.isEmptyAnswer(userAnswer)) {
      return [];
    }

    const questionAnswers = answers.map(({ id, text }: any) => ({
      id,
      text,
      hasBeenSelected: false
    }));

    return userAnswer.map((text: any) => {
      const answerItem = questionAnswers.find(
        (questionAnswer: any) => questionAnswer.text === text && !questionAnswer.hasBeenSelected
      );

      if (!answerItem) {
        throw new Error(`Ranking text: text answer wan not found: '${text}'`);
      }

      answerItem.hasBeenSelected = true; // ???????
      return {
        id: answerItem.id,
        text
      };
    });
  }
}
