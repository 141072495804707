import styled from 'styled-components';
import Icon from 'components/common/Icon';

export const Container = styled.div`
  margin-left: 8px;
`;

export const PauseIcon = styled(Icon)`
  margin-left: 8px;
  font-size: 8px;
`;
