export enum ActionTypes {
    OPEN_QUESTION_ANSWER_IS_SELECTED = 'OPEN_QUESTION_ANSWER_IS_SELECTED'
}

type AnswerIsSelected = {
    type: ActionTypes.OPEN_QUESTION_ANSWER_IS_SELECTED,
    payload: {
        questionId: string,
        answeredText: string
    }
}

export type OpenQuestionActionTypes = AnswerIsSelected;
