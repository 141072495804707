import {
  hasQueryStringParameter,
  getValueFromUrl,
  getCurrentUrl,
  removeQueryStringParam
} from 'utils/window';
import { LOGIN_PATH } from 'constants/navigation';
import requester from 'core/http/requester';
import {
  EMAIL_PASSWORD_COMBINATION_NOT_EXISTS,
  PASSWORD_RESET_SENDING_FAILED,
  UNKNOWN_ERROR,
  STATUS_OK
} from 'constants/progressStorage';

import { CONTENT_TYPES } from 'constants/common';
import { IdToUuid } from 'utils/string';

const constants = {
  tokenKey: 'token.learn',
  shortTermAccessKey: 'shortTermAccess.auth.template'
};

class ExternalStorageAuth {
  courseTitle: string;

  authServiceUrl: string;

  learnServiceUrl: string;

  courseLink: string;

  constructor(courseTitle: string, authServiceUrl: string, learnServiceUrl: string) {
    this.courseTitle = courseTitle;
    this.authServiceUrl = authServiceUrl;
    this.learnServiceUrl = learnServiceUrl;
    this.courseLink = `${getCurrentUrl()}#${LOGIN_PATH}`;
  }

  set shortTermAccess(value: any) {
    if (value.toString().length) {
      sessionStorage.setItem(constants.shortTermAccessKey, value);
    } else {
      sessionStorage.removeItem(constants.shortTermAccessKey);
    }
  }

  get shortTermAccess() {
    return sessionStorage.getItem(constants.shortTermAccessKey) === 'true';
  }

  set token(value: string | any) {
    if (value) {
      sessionStorage.setItem(constants.tokenKey, value);
    } else {
      sessionStorage.removeItem(constants.tokenKey);
    }
  }

  get token(): string | any {
    return sessionStorage.getItem(constants.tokenKey);
  }

  getHeaders({ contentType, bearerToken }: any) {
    const headers: any = {};
    if (contentType) {
      headers['Content-Type'] = contentType;
    }
    if (bearerToken) {
      headers.Authorization = `Bearer ${bearerToken}`;
    }

    return headers;
  }

  isAuthenticated() {
    return !!this.token;
  }

  async getAuthToken() {
    if (hasQueryStringParameter('token')) {
      const token = getValueFromUrl('token');
      removeQueryStringParam('token');
      return token;
    }

    return this.getTokenFromAuthService();
  }

  async getTokenFromAuthService() {
    try {
      const getTokenResponse: any = await requester.get(
        `${this.authServiceUrl}/api/account/token`,
        {
          credentials: 'include',
          headers: {},
          retryOptions: {
            retries: 0,
            retryOn: [503]
          }
        }
      );

      if (getTokenResponse.status < 400) {
        const responseData = await getTokenResponse.json();
        return responseData && responseData.token;
      }

      const isIframe = window.top !== window.self;
      if (isIframe) {
        return this.token;
      }
      return '';
    } catch (e) {
      return this.token;
    }
  }

  async identify() {
    try {
      this.token = await this.getAuthToken();

      const userInfoResponse: any = await requester.get(`${this.learnServiceUrl}/api/learner/me`, {
        headers: this.getHeaders({ bearerToken: this.token })
      });

      if (userInfoResponse.status === 401 || userInfoResponse.status === 403) {
        return this.logout();
      }

      return userInfoResponse.json();
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  async logout() {
    try {
      const response = await requester.post(`${this.authServiceUrl}/api/account/logout`, {
        credentials: 'include',
        headers: {}
      });

      if (response.status === 201 || response.status === 200) {
        this.token = '';
        this.shortTermAccess = '';
      }
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async exists(email: string) {
    const response: any = await requester.get(`${this.authServiceUrl}/api/account/exists`, {
      headers: this.getHeaders({ contentType: CONTENT_TYPES.JSON }),
      query: {
        email
      },
      nocache: true,
      credentials: 'include'
    });

    return response.json();
  }

  async authorize({ email, password, shortTermAccess = true }: { [key: string]: any }) {
    const response = await requester.post(`${this.authServiceUrl}/api/auth/credentials`, {
      data: { email, password, shortTermAccess },
      headers: this.getHeaders({ contentType: CONTENT_TYPES.JSON }),
      nocache: true,
      credentials: 'include'
    });
    return this._setAuthenticationData(response, shortTermAccess);
  }

  async register({ name, email, password, shortTermAccess = true }: { [key: string]: any }) {
    const response = await requester.post(`${this.authServiceUrl}/api/credentials/register`, {
      data: { email, password, name, shortTermAccess },
      headers: this.getHeaders({ contentType: CONTENT_TYPES.JSON }),
      nocache: true,
      credentials: 'include'
    });

    return this._setAuthenticationData(response, shortTermAccess);
  }

  async resetPassword(email: string) {
    const response = await requester.post(
      `${this.authServiceUrl}/api/credentials/forgot-password`,
      {
        data: { email, returnUrl: this.courseLink },
        headers: this.getHeaders({ contentType: CONTENT_TYPES.JSON }),
        nocache: true,
        credentials: 'include'
      }
    );

    if (response.status !== 201) {
      return PASSWORD_RESET_SENDING_FAILED;
    }

    return STATUS_OK;
  }

  async sendSecretLink(courseId: any) {
    const response = await requester.post(
      `${this.learnServiceUrl}/api/learner/me/courses/${IdToUuid(courseId)}/send-secret-link`,
      {
        data: {},
        headers: this.getHeaders({ contentType: CONTENT_TYPES.JSON, bearerToken: this.token }),
        nocache: true
      }
    );

    if (response.status !== 200) {
      return UNKNOWN_ERROR;
    }

    return STATUS_OK;
  }

  async _setAuthenticationData(response: any, shortTermAccess: any) {
    if (response.status === 403 || response.status === 401) {
      return EMAIL_PASSWORD_COMBINATION_NOT_EXISTS;
    }
    if (response.status >= 400) {
      return UNKNOWN_ERROR;
    }

    const data = await response.json();
    if (!data || (data && !data.token)) {
      return UNKNOWN_ERROR;
    }

    this.token = data.token;
    this.shortTermAccess = shortTermAccess;

    return STATUS_OK;
  }
}

export default ExternalStorageAuth;
