import { schema } from 'normalizr';
import { QuestionChildren } from './question';

const content = new schema.Entity(
  'contents',
  {},
  {
    processStrategy: (value: any, parent: any, key: string) => {
      const result = {
        ...value,
        value: undefined
      }

      if (result.children as QuestionChildren) {
        result.children = result.children.map((item: any) => item.id) as Array<string>;
      }

      switch (key) {
        case 'instructions':
        case 'hints':
        case 'correctFeedback':
        case 'incorrectFeedback':
        case 'children':
          result.url = `${parent.sectionId}/${parent.id}/${value.id}.html`;
          break;
        case 'questionContent':
          result.url = `${parent.sectionId}/${parent.id}/content.html`;
          break;
        default:
          throw new Error('Content entity key is not supported');
      }
      return result;
    }
  }
);

export default content;
