import { ActionTypes, FillInTheBlankActionTypes } from './types';
import { QuestionsState } from '../types';

export default function reducer(state: QuestionsState, action: FillInTheBlankActionTypes) {
  switch (action.type) {
    case ActionTypes.FILL_IN_THE_BLANK_ANSWER:
      return {
        data: action.payload.data
      };
    default:
      return state;
  }
}
