import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { localize } from 'core/localization';
import { RootAppState } from 'store/types';
import Trophy from 'assets/figures/Trophy';
import { Text, Button } from 'components/common';
import { getLearnServiceUrl, isPreviewMode } from 'store/settings/selectors';
import * as navigationActions from 'store/navigation/actions';
import { Figure, TextElement, ButtonsWrapper, Wrapper } from './ThankForFeedback.styled';
import { isLtiInitialized } from 'store/modules/selectors';

type ThankForFeedbackProps = {
  backTo(): void;
  closeCourse(): void;
  fromDownloadCertificateButton: boolean;
  isScormMode: boolean;
  isLti: boolean;
  previewMode: boolean;
  learnServiceUrl?: string;
  navigationActions: { [key: string]: any };
};

export class ThankForFeedback extends React.PureComponent<ThankForFeedbackProps, {}> {
  static defaultProps = {
    fromDownloadCertificateButton: false,
    isScormMode: false,
    previewMode: false
  };

  render() {
    const {
      fromDownloadCertificateButton,
      backTo,
      closeCourse,
      isScormMode,
      isLti,
      previewMode,
      navigationActions,
      learnServiceUrl
    } = this.props;

    return (
      <Wrapper>
        <Figure>
          <Trophy />
        </Figure>
        <Text appearance="h3">{localize('[thank for feedback]')}</Text>
        {!isScormMode && !previewMode && !fromDownloadCertificateButton && (
          <Wrapper>
            <TextElement appearance="p" size={14} font="secondaryFont">
              {isLti ? localize('[close lti course text]') : localize('[close course text]')}
            </TextElement>
            {!isLti && (
              <ButtonsWrapper>
                <Button
                  data-test="my-courses-button"
                  onClick={() => navigationActions.goToUrl(learnServiceUrl)}
                  layout="primary"
                  tabIndex={1}
                >
                  {localize('[my courses]')}
                </Button>
              </ButtonsWrapper>
            )}
          </Wrapper>
        )}

        {isScormMode && (
          <Button onClick={closeCourse} layout="secondary" tabIndex={0}>
            {localize('[close course]')}
          </Button>
        )}

        {fromDownloadCertificateButton && (
          <ButtonsWrapper>
            {!isLti && (
              <Button
                onClick={() => navigationActions.goToUrl(learnServiceUrl)}
                layout="primary"
                tabIndex={1}
              >
                {localize('[my courses]')}
              </Button>
            )}
            <Button onClick={backTo} layout="primary" tabIndex={1}>
              {localize('[back to the results]')}
            </Button>
            <Button onClick={closeCourse} layout="secondary" tabIndex={1}>
              {localize('[close course]')}
            </Button>
          </ButtonsWrapper>
        )}
      </Wrapper>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    learnServiceUrl: getLearnServiceUrl(state),
    previewMode: isPreviewMode(state),
    isLti: isLtiInitialized(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    navigationActions: bindActionCreators(navigationActions, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ThankForFeedback);
