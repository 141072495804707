import idShortener from '../idShortener';

export default class FillInTheBlankResponseMapper extends idShortener {
  map({ data }: {[key: string]: any}) {
    return this._map(data, this.convertToShort.bind(this));
  }

  unMap({ data }: {[key: string]: any}) {
    return this._map(data, this.convertToOriginal.bind(this));
  }

  _map(data: any, convert: any) {
    const ids = Object.keys(data);
    let userResponse: {[key: string]: any} = {};

    ids.forEach((id: any) => {
      const convertedId = convert(id);
      userResponse[convertedId] = data[id];
    });

    return {
      data: userResponse
    };
  }
}
