import * as questionTypes from 'constants/questionTypes';

import DragAndDropText from './DragAndDropText';
import FillInTheBlank from './FillInTheBlank';
import HotspotMapper from './HotspotMapper';
import InformationContent from './InformationContent';
import MultipleChoice from './MultipleChoice';
import OpenQuestion from './OpenQuestion';
import RankingText from './RankingText';
import Scenario from './Scenario';
import SingleChoice from './SingleChoice';
import SingleChoiceImage from './SingleChoiceImage';
import StatementChoice from './StatementChoice';
import TextMatching from './TextMatching';


const questions: { [key: string]: any } = {
  [questionTypes.DRAG_AND_DROP_TEXT]: new DragAndDropText(),
  [questionTypes.FILL_IN_THE_BLANK]: new FillInTheBlank(),
  [questionTypes.HOTSPOT]: new HotspotMapper(),
  [questionTypes.INFORMATION_CONTENT]: new InformationContent(),
  [questionTypes.MULTIPLE_CHOICE]: new MultipleChoice(),
  [questionTypes.OPEN_QUESTION]: new OpenQuestion(),
  [questionTypes.RANKING_TEXT]: new RankingText(),
  [questionTypes.SCENARIO]: new Scenario(),
  [questionTypes.SINGLE_CHOICE]: new SingleChoice(),
  [questionTypes.SINGLE_CHOICE_IMAGE]: new SingleChoiceImage(),
  [questionTypes.STATEMENT_CHOICE]: new StatementChoice(),
  [questionTypes.TEXT_MATCHING]: new TextMatching()
};
export default questions;
