import { ActionTypes, HotspotActionTypes } from './types';
import { QuestionsState } from '../types';

export default function reducer(state: QuestionsState, action: HotspotActionTypes) {
  switch (action.type) {
    case ActionTypes.HOTSPOT_QUESTION_ANSWER:
      return action.payload.data;
    default:
      return state;
  }
}
