export enum ActionTypes {
    QUESTION_RANKING_ANSWERS_ARE_SELECTED = 'QUESTION_RANKING_ANSWERS_ARE_SELECTED'
}

type AnswersSelected = {
    type: ActionTypes.QUESTION_RANKING_ANSWERS_ARE_SELECTED,
    payload: {
        answers: any,
        questionId: string
    }
}

export type RankingTextActionTypes = AnswersSelected;
