import ResponseMapperBase from './ResponseMapperBase';

export default class StatementChoiceResponseMapper extends ResponseMapperBase {
  /**
   * @param {object} question
   */
  mapResponse({ answers, response }: any) {
    let result: { [key: string]: any } = {};

    response.forEach(({ id, isCorrect }: any) => {
      const answerIndex = answers.findIndex((answer: any) => answer.id === id);
      result[answerIndex] = +isCorrect;
    });

    return result;
  }

  /**
   * @param {object} userAnswer
   * @param {object} question
   */
  unMap(userAnswer: any, { answers }: any) {
    if (this.isAnsweredCorrectly(userAnswer)) {
      return answers.map(({ id, isCorrect }: any) => ({
        id,
        isCorrect
      }));
    }

    if (this.isEmptyAnswer(userAnswer)) {
      return [];
    }

    let result: any[] = [];
    const userAnswerKeys = Object.keys(userAnswer);
    userAnswerKeys.forEach(key => {
      const option = userAnswer[key];
      const { id } = answers[key];

      result.push({
        id,
        isCorrect: option === 1
      });
    });

    return result;
  }
}
