import styled from 'styled-components';

/* eslint-disable import/prefer-default-export */

type FigureProps = {
  arialabel: string;
  role: string
}
export const Figure = styled.svg.attrs((props: FigureProps) => ({
  role: props.role,
  'aria-label': props.arialabel
})) <FigureProps>`
  max-width: 100%;
`;
