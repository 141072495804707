import { createGlobalStyle } from 'styled-components';
import resetCss from 'styled-reset';
import commonCss from './common';
import animationsCss from './animations';
import './icons';

const BaseStyles = createGlobalStyle`
  ${resetCss};
  ${commonCss};
  ${animationsCss};
`;

export default BaseStyles;
