import { toggleArrayStatementElement } from 'utils/object';
import { ActionTypes, StatementChoiceActionTypes } from './types';
import { QuestionsState } from '../types';

export default function reducer(state: QuestionsState, action: StatementChoiceActionTypes) {
  switch (action.type) {
    case ActionTypes.QUESTION_STATEMENT_ANSWER_IS_SELECTED:
      return toggleArrayStatementElement(state as any, {
        id: action.payload.answerId,
        isCorrect: action.payload.isCorrect
      });
    default:
      return state;
  }
}
