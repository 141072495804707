export type ModulesState = {
    xapi: {
        isInitialized: boolean;
        isLrsTrackingOn: boolean;
        isNpsTrackingOn: boolean;
    },
    scorm: {
        isInitialized: boolean
    };
    progressStorage: {
        isProgressStorageOn: boolean;
    };
    review: {
        isReviewModeEnabled: boolean;
        reviewApiUrl: string;
    };
    lti: {
        isInitialized: boolean;
    }
}
export enum ActionTypes {
    MODULE_XAPI_INITIALIZED = 'MODULE_XAPI_INITIALIZED',
    MODULE_XAPI_LRS_ON = 'MODULE_XAPI_LRS_ON',
    MODULE_XAPI_LRS_OFF = 'MODULE_XAPI_LRS_OFF',
    MODULE_XAPI_NPS_ON = 'MODULE_XAPI_NPS_ON',
    MODULE_XAPI_NPS_OFF = 'MODULE_XAPI_NPS_OFF',
    MODULE_SCORM_INITIALIZED = 'MODULE_SCORM_INITIALIZED',
    MODULE_PROGRESS_STORAGE_ON = 'MODULE_PROGRESS_STORAGE_ON',
    MODULE_PROGRESS_STORAGE_OFF = 'MODULE_PROGRESS_STORAGE_OFF',
    ENABLE_REVIEW_MODE = 'ENABLE_REVIEW_MODE',
    MODULE_LTI_INITIALIZED = 'MODULE_LTI_INITIALIZED'
}

type InitializeXApi = {
    type: ActionTypes.MODULE_XAPI_INITIALIZED 
}

type SubscribeLRS = {
    type: ActionTypes.MODULE_XAPI_LRS_ON
}

type SubscribeNPS = {
    type: ActionTypes.MODULE_XAPI_NPS_ON
}

type SubscribePS = {
    type: ActionTypes.MODULE_PROGRESS_STORAGE_ON
}

type SubscribeLTI = {
    type: ActionTypes.MODULE_LTI_INITIALIZED
}

type UnsubscribeLrs = {
    type: ActionTypes.MODULE_XAPI_LRS_OFF
}

type UnsubscribeNPS = {
    type: ActionTypes.MODULE_XAPI_NPS_OFF
}

type UnsubscribePS = {
    type: ActionTypes.MODULE_PROGRESS_STORAGE_OFF
}

type ScrormInitialize = {
    type: ActionTypes.MODULE_SCORM_INITIALIZED
}

type EnableReview = {
    type: ActionTypes.ENABLE_REVIEW_MODE,
    payload: {
        reviewApiUrl: string
    }
}

export type  ModulesActionTypes = InitializeXApi | SubscribeLRS | SubscribeNPS | SubscribePS | SubscribeLTI | UnsubscribeLrs | UnsubscribeNPS | UnsubscribePS | ScrormInitialize | EnableReview;

