import initialState from 'store/initialState';
import { ActionTypes, ContentsActionTypes } from './types';

export default function contentReducer(state = initialState.contents, action: ContentsActionTypes) {
  switch (action.type) {
    case ActionTypes.CONTENTS_LOADED:
      return { ...state, ...action.payload };
    case ActionTypes.CONTENT_LOADED:
      return {
        ...state,
        [action.payload.contentId]: {
          ...state[action.payload.contentId],
          value: action.payload.value
        }
      };
    default:
      return state;
  }
}
