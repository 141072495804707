import { css } from 'styled-components';
import { transparentize } from 'utils/color';
import { MEDIA_FONT_SIZES } from 'constants/common';
import media from './media';

const defaultMainFont = 'PT Serif, Serif';
const defaultSecondaryFont = 'Arial, Sans-Serif';

export const fontFamilyWithFallback = (fontFamily: any, fallback: any) =>
  `${fontFamily || 'Arial'}, ${fallback}`;

export const mainFont = css`
  font-family: ${props =>
    fontFamilyWithFallback(props.theme.fonts.mainFont.fontFamily, defaultMainFont)};
`;

export const secondaryFont = css`
  font-family: ${props =>
    fontFamilyWithFallback(props.theme.fonts.secondaryFont.fontFamily, defaultSecondaryFont)};
`;

const styledColors = (fontStyles: any, isInverted: any, InvertedColor: any) => css`
  color: ${isInverted ? InvertedColor : fontStyles.color};
`;

const styledFonts = (fontStyles: any) => css`
  font-family: ${fontFamilyWithFallback(fontStyles.fontFamily, defaultMainFont)};
  font-size: ${fontStyles.size}px;
  font-style: ${fontStyles.fontStyle};
  font-weight: ${fontStyles.fontWeight};
  text-decoration: ${fontStyles.textDecoration};
  background-color: ${fontStyles.textBackgroundColor};
`;

const mediaFontSize = (fontSize: any, percentage: any, limitSize: any = null) => {
  let result = fontSize * percentage;

  if (limitSize && result > limitSize) {
    result = limitSize;
  }

  return css`
    font-size: ${result}px;
  `;
};

export const bold = css`
  font-weight: 600;
`;

export const mainFontH1 = css`
  ${(props: any) => styledFonts(props.theme.fonts.heading1)};
  line-height: 1.4;
  ${(props: any) =>
    styledColors(
      props.theme.fonts.heading1,
      props.inverted,
      props.theme.colors.coverBgOverlayTextColor
    )};
  ${media.smallFontRatio`
    ${(props: any) =>
      mediaFontSize(props.theme.fonts.heading1.size, MEDIA_FONT_SIZES.heading1.MULTIPLIER)};
  `}
  ${media.tablet`${(props: any) =>
    mediaFontSize(
      props.theme.fonts.heading1.size,
      MEDIA_FONT_SIZES.heading1.MULTIPLIER,
      MEDIA_FONT_SIZES.heading1.MAXIMUM_VALUE
    )}`}
`;

export const mainFontH2 = css`
  ${(props: any) => styledFonts(props.theme.fonts.heading2)};
  line-height: 1.4;
  ${(props: any) =>
    styledColors(
      props.theme.fonts.heading2,
      props.inverted,
      props.theme.colors.coverBgOverlayTextColor
    )};
  ${media.large`
    ${(props: any) =>
      mediaFontSize(props.theme.fonts.heading2.size, MEDIA_FONT_SIZES.heading2.MULTIPLIER)};
  ${media.tablet`${(props: any) =>
    mediaFontSize(
      props.theme.fonts.heading2.size,
      MEDIA_FONT_SIZES.heading2.MULTIPLIER,
      MEDIA_FONT_SIZES.heading2.MAXIMUM_VALUE
    )}`}
  `};
`;

export const mainFontH3 = css`
  ${(props: any) => styledFonts(props.theme.fonts.heading3)};
  line-height: 1.4;
  ${(props: any) =>
    styledColors(
      props.theme.fonts.heading3,
      props.inverted,
      props.theme.colors.coverBgOverlayTextColor
    )};
  ${media.large`
    ${(props: any) =>
      mediaFontSize(props.theme.fonts.heading3.size, MEDIA_FONT_SIZES.heading3.MULTIPLIER)};
  `};
  ${media.tablet`${(props: any) =>
    mediaFontSize(
      props.theme.fonts.heading3.size,
      MEDIA_FONT_SIZES.heading3.MULTIPLIER,
      MEDIA_FONT_SIZES.heading3.MAXIMUM_VALUE
    )}`};
`;

export const mainFontH4 = css`
  ${(props: any) => styledFonts(props.theme.fonts.heading4)};
  line-height: 1.4;
  ${(props: any) =>
    styledColors(
      props.theme.fonts.heading4,
      props.inverted,
      props.theme.colors.coverBgOverlayTextColor
    )};
  ${media.large`
    ${(props: any) =>
      mediaFontSize(props.theme.fonts.heading4.size, MEDIA_FONT_SIZES.heading4.MULTIPLIER)};
  `};
  ${media.tablet`${(props: any) =>
    mediaFontSize(
      props.theme.fonts.heading4.size,
      MEDIA_FONT_SIZES.heading4.MULTIPLIER,
      MEDIA_FONT_SIZES.heading4.MAXIMUM_VALUE
    )}`};
`;

export const mainFontText = css`
  ${mainFont};
  font-size: 18px;
  ${bold};
  line-height: 1.6;
`;

export const mainFontSmallText = css`
  ${mainFont};
  font-size: 14px;
  ${bold};
  line-height: 28px;
  ${media.xlarge`
    font-size: 12px;
  `};
`;

export const secondaryFontH1 = css`
  ${secondaryFont};
  font-size: 36px;
  font-weight: 100;
  line-height: 54px;
  ${media.mobile`
    font-size: 32px;
    line-height: 48px;
  `};
`;

export const secondaryFontText = css`
  ${(props: any) => styledFonts(props.theme.fonts.normal)};
  line-height: 1.6;
  ${(props: any) =>
    styledColors(
      props.theme.fonts.normal,
      props.inverted,
      props.theme.colors.coverBgOverlayTextColor
    )};
  ${media.large`
    ${(props: any) =>
      mediaFontSize(props.theme.fonts.normal.size, MEDIA_FONT_SIZES.paragraph.MULTIPLIER)};
  `};
  ${media.tablet`${(props: any) =>
    mediaFontSize(
      props.theme.fonts.normal.size,
      MEDIA_FONT_SIZES.paragraph.MULTIPLIER,
      MEDIA_FONT_SIZES.paragraph.MAXIMUM_VALUE
    )}`};
`;

export const secondaryFontSmallText = css`
  ${secondaryFont};
  font-size: 14px;
  line-height: 28px;
  ${media.xlarge`
    font-size: 12px;
  `};
  ${media.mobile`
    font-size: 11px;
    line-height: 26px;
  `};
`;

export const quote = css`
  ${(props: any) => styledFonts(props.theme.fonts.quote)};
  line-height: 1.6;
  ${(props: any) =>
    styledColors(
      props.theme.fonts.quote,
      props.inverted,
      props.theme.colors.coverBgOverlayTextColor
    )};
  ${media.large`
    ${(props: any) =>
      mediaFontSize(props.theme.fonts.quote.size, MEDIA_FONT_SIZES.quote.MULTIPLIER)};
  `};
  ${media.tablet`${(props: any) =>
    mediaFontSize(
      props.theme.fonts.quote.size,
      MEDIA_FONT_SIZES.quote.MULTIPLIER,
      MEDIA_FONT_SIZES.quote.MAXIMUM_VALUE
    )}`};
`;

export const highlighted = css`
  ${(props: any) => styledFonts(props.theme.fonts.highlighted)};
  line-height: 2;
  ${(props: any) =>
    styledColors(
      props.theme.fonts.highlighted,
      props.inverted,
      props.theme.colors.coverBgOverlayTextColor
    )};
  background-color: ${(props: any) => props.theme.fonts.highlighted.textBackgroundColor};
  ${media.xlarge`
    ${(props: any) => mediaFontSize(props.theme.fonts.highlighted.size, 0.88)};
  `};
  ${media.mobile`
    ${(props: any) => mediaFontSize(props.theme.fonts.highlighted.size, 0.82)};
  `};
`;

export const code = css`
  color: #000;
  font-family: 'PT-Mono', Courier, monospace;
  padding: 8px;
  border-color: #cccccc;
  border-radius: 2px;
  background-clip: padding-box;
  background-color: #f5f5f5;
`;

export const links = css`
  ${(props: any) => styledFonts(props.theme.fonts.links)};
  line-height: 2;
  color: ${(props: any) =>
    props.inverted
      ? transparentize(props.theme.colors.coverBgOverlayTextColor, 0.7)
      : transparentize(props.theme.fonts.links.color, 0.7)};
  ${media.xlarge`
    ${(props: any) => mediaFontSize(props.theme.fonts.links.size, 1)};
  `};
  ${media.medium`
    ${(props: any) => mediaFontSize(props.theme.fonts.links.size, 0.88)};
  `};

  &:hover {
    ${(props: any) =>
      styledColors(
        props.theme.fonts.links,
        props.inverted,
        props.theme.colors.coverBgOverlayTextColor
      )};
  }
`;
