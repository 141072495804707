import ResponseMapperBase from './ResponseMapperBase';

export default class ScenarioResponseMapper extends ResponseMapperBase {
  mapResponse(question: any) {
    if (!this.hasQuestionResponse(question)) {
      return 0;
    }

    return question.response.score;
  }

  /**
   * @param {object} userAnswer
   */
  unMap(userAnswer: any) {
    return { score: userAnswer, isFinished: userAnswer === 100 };
  }
}
