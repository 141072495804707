import { css } from 'styled-components';

export const centerAbsoluteElement = css`
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

export const centerAbsoluteElementY = css`
  top: 50%;
  transform: translate(0, -50%);
`;
