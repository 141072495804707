import { ActionTypes } from './types';
import { ThunkResult } from '../types';

export const openPopup = (settings: { [key: string]: any }): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.OPEN_POPUP, payload: { settings } });
};

export const closePopup = (): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.CLOSE_POPUP });
};
