import shuffle from 'lodash.shuffle';

export const shuffleAnswerOptions = (answerOptions: any[]): any[] => {
  if (answerOptions.length <= 1) {
    return answerOptions;
  }

  let shuffledArray = shuffle(answerOptions);
  let iterationCount = 0;

  const MAX_RETRIES = 10;

  while (
    JSON.stringify(shuffledArray) === JSON.stringify(answerOptions) &&
    iterationCount < MAX_RETRIES
  ) {
    iterationCount++;
    shuffledArray = shuffle(shuffledArray);
  }

  return shuffledArray;
};

export default shuffleAnswerOptions;
