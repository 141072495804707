import * as React from 'react';
import { getTimeFromMilliseconds, SettingsTime } from 'utils/timer';
import { DigitContainer, Digits, Container, Separator } from './DigitalTimer.styled';

type DigitProps = {
  value: number;
};

const Digit: React.FunctionComponent<DigitProps> = ({ value }: DigitProps) => {
  const digit = value >= 10 ? value.toString() : `0${value}`;
  return (
    <DigitContainer>
      <Digits>{digit}</Digits>
    </DigitContainer>
  );
}

type DigitalTimerProps = {
  timeLeft: number;
  isTimerStopped: boolean;
};

export const DigitalTimer: React.FunctionComponent<DigitalTimerProps> = ({
  timeLeft,
  isTimerStopped
}: DigitalTimerProps) => {
  const { hours = 0 , minutes = 0, seconds = 0 } = getTimeFromMilliseconds(timeLeft) as SettingsTime;
  return (
    <Container isTimerStopped={isTimerStopped}>
      <Digit value={hours} />
      <Separator>:</Separator>
      <Digit value={minutes} />
      <Separator>:</Separator>
      <Digit value={seconds} />
    </Container>
  );
}

export default DigitalTimer;
