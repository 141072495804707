export type QuestionsState = {
  [key: string]: any;
};
export enum ActionTypes {
  QUESTIONS_LOADED = 'QUESTIONS_LOADED',
  QUESTION_UPDATE_SCORE = 'QUESTION_UPDATE_SCORE',
  QUESTION_ANSWERED = 'QUESTION_ANSWERED',
  QUESTION_ANSWERED_BEFORE_SUBMIT = 'QUESTION_ANSWERED_BEFORE_SUBMIT',
  QUESTION_PROGRESS_RESET = 'QUESTION_PROGRESS_RESET',
  QUESTION_PROGRESS_RESET_KEEP_ANSWER = 'QUESTION_PROGRESS_RESET_KEEP_ANSWER',
  QUESTION_PROGRESS_RESET_ATTEMPT = 'QUESTION_PROGRESS_RESET_ATTEMPT',
  QUESTION_OPENED = 'QUESTION_OPENED',
  QUESTION_PROGRESS_RESTORE = 'QUESTION_PROGRESS_RESTORE',
  QUESTION_RESTORE_RESPONSE = 'QUESTION_RESTORE_RESPONSE',
  QUESTION_RESTORE_SHOW_PREV_ANSWER = 'QUESTION_RESTORE_SHOW_PREV_ANSWER',
  FEEDBACK_ANIMATING = 'FEEDBACK_ANIMATING',
  QUESTION_UPDATE_RETRY = 'QUESTION_UPDATE_RETRY'
}

type QuestionLoaded = {
  type: ActionTypes.QUESTIONS_LOADED;
  payload: {
    questions: any; // TODO: add type
  };
};

type QuestionAnswered = {
  type: ActionTypes.QUESTION_ANSWERED;
  payload: {
    id: string;
    score: number;
    attempt: number;
  };
};

type QuestionAnsweredBeforeSubmit = {
  type: ActionTypes.QUESTION_ANSWERED_BEFORE_SUBMIT;
  payload: {
    id: string;
    attempt: number;
  };
};

type QuestionUpdateScore = {
  type: ActionTypes.QUESTION_UPDATE_SCORE;
  payload: {
    id: string;
    score: number;
  };
};

type QuestionOpened = {
  type: ActionTypes.QUESTION_OPENED;
  payload: {
    hasBeenOpened: boolean;
    questionId: string;
  };
};

type QuestionProgressReset = {
  type: ActionTypes.QUESTION_PROGRESS_RESET;
  payload: {
    id: string;
    isPoolQuestion: boolean;
    type: string;
  };
};

type QuestionProgressResetKeepAnswer = {
  type: ActionTypes.QUESTION_PROGRESS_RESET_KEEP_ANSWER;
  payload: {
    id: string;
  };
};

type QuestionProgressResetAttempt = {
  type: ActionTypes.QUESTION_PROGRESS_RESET_ATTEMPT;
  payload: {
    id: string;
  };
};

type QuestionRestoreResponse = {
  type: ActionTypes.QUESTION_RESTORE_RESPONSE;
  payload: {
    id: string;
    response: any;
    attempt: number;
  };
};

type QuestionRestoreShowPrevAnswer = {
  type: ActionTypes.QUESTION_RESTORE_SHOW_PREV_ANSWER;
  payload: {
    id: string;
    response: any;
  };
};

type QuestionProgressRestore = {
  type: ActionTypes.QUESTION_PROGRESS_RESTORE;
  payload: {
    id: string;
    score: number;
    attempt: number;
  };
};

type FeedbackAnimating = {
  type: ActionTypes.FEEDBACK_ANIMATING;
  payload: {
    id: string;
    isFeedbackAnimating: boolean;
  };
};

type QuestionUpdateRetry = {
  type: ActionTypes.QUESTION_UPDATE_RETRY;
  payload: {
    questionId: string;
    retries: number;
  };
};

export type QuestionActionTypes =
  | FeedbackAnimating
  | QuestionOpened
  | QuestionLoaded
  | QuestionAnswered
  | QuestionUpdateScore
  | QuestionAnsweredBeforeSubmit
  | QuestionProgressReset
  | QuestionProgressResetKeepAnswer
  | QuestionProgressResetAttempt
  | QuestionRestoreResponse
  | QuestionRestoreShowPrevAnswer
  | QuestionProgressRestore
  | QuestionUpdateRetry;
