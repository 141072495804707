import styled, { css } from 'styled-components';
import media from 'components/mixins/media';
import { ShapedIcon, Text } from 'components/common';
import { transparentize } from 'utils/color';

type IconProps = {
  ariaLabel?: string;
  name: string;
  size: any;
  shape: any;
  shapeColor: any;
};

export const Figure = styled.div`
  margin: 40px 0 55px 0;
  text-align: center;
  ${media.mobile`
    ${css`
      margin: 40px 0 30px;
    `}
  `}
`;

export const ReconnectedIcon = styled(ShapedIcon).attrs((props: IconProps) => ({
  'aria-label': props.ariaLabel
}))<IconProps>`
  flex-shrink: 0;
  margin-right: 9px;
`;

export const TextElement = styled(Text)`
  max-width: 475px;
  padding: 24px 0 0;
  text-align: center;
`;

export const ReconnectionWrapper = styled.div`
  position: fixed;
  top: 40px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;

  ${media.mobile`
  ${css`
    top: 0;
    background-color: ${props => props.theme.colors.contentBodyColor};
  `}
  `}
`;

export const CircleWrapper = styled.div`
  width: 100%;
  max-width: 560px;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  background: ${props => transparentize(props.theme.colors.incorrectColor, 0.1)};
  border: 1px solid ${props => transparentize(props.theme.colors.incorrectColor, 0.3)};

  &.Online {
    background: ${props => transparentize(props.theme.colors.correctColor, 0.1)};
    border: 1px solid ${props => transparentize(props.theme.colors.correctColor, 0.3)};
  }

  ${TextElement} {
    padding: 0;
  }

  > div {
    position: relative;
    left: auto;
    top: auto;
    margin: 0 20px 0 0;
  }
`;

export const HeaderElement = styled(Text)`
  color: ${props => props.theme.colors.textColor};
  font-weight: normal !important;
  text-align: center;

  ${media.mobile`
    ${css`
      text-align: center;
    `}
  `}
`;

export const ButtonsWrapper = styled.div`
  display: inline-block;
  margin-top: 40px;

  & > button {
    width: 100%;
    display: block;
    margin: 0 0 20px;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: max-content;
  height: 100%;

  ${media.mobile`
  ${css`
    height: auto;
  `}`}
`;

export const ContentWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;

  ${media.mobile`
    ${css`
      &::before {
        content: '';
        width: 100%;
        height: 40px;
      }

      &::after {
        content: '';
        width: 100%;
        height: 110px;
      }
    `}
  `}
`;

export const NoteWrapper = styled.div`
  position: fixed;
  bottom: 28px;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  justify-items: center;
  text-align: center;
  color: ${props => transparentize(props.theme.colors.textColor, 0.7)};

  ${media.mobile`
  ${(props: any) =>
    css`
      background-color: ${props.theme.colors.contentBodyColor};
      bottom: 0;
      padding: 24px 0;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-color: ${transparentize(props.theme.colors.textColor, 0.1)};
        z-index: -1;
      }
    `}
  `}
`;

export const Note = styled(Text)`
  width: 100%;

  b {
    font-weight: bold;
  }

  ${media.mobile`
    ${css`
      max-width: 260px;
    `}
  `}
`;
