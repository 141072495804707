import React from 'react';
import { ProgressContainer, Progress } from './ProgressBar.styled';

export const MAX_PROGRESS = 100;

type ProgressBarProps = {
  height?: number;
  progress?: number;
  className?: string;
  children?: any;
  progressColor?: string;
  opacity?: any;
};

export const ProgressBar = ({
  height = 2,
  progress = 0,
  className = '',
  opacity = 1,
  children,
  progressColor = 'correctColor'
}: ProgressBarProps) => (
  <ProgressContainer className={className} height={height}>
    <Progress
      progressColor={progressColor}
      progress={progress > MAX_PROGRESS ? MAX_PROGRESS : progress}
      opacity={opacity}
    />
    {children}
  </ProgressContainer>
);

export default ProgressBar;
