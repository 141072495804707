import React from 'react';
import { withTheme } from 'styled-components';
import { transparentize } from 'utils/color';
import { localize } from 'core/localization';
import { Figure } from './Figure.styled';

type RateProps = {
  theme: {[key: string]: any};
  number: number;
  promoters: boolean;
};

export const Rate = (props: RateProps) => (
  <Figure
    width="261px"
    height="89px"
    viewBox="0 0 261 89"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    arialabel={`${localize('[aria label rate figure]')}`}
  >
    <title>F19AC1D1-78C9-451F-BAEC-6A87EB3B013B</title>
    <desc>Created with sketchtool.</desc>
    <defs>
      <filter
        x="0.0%"
        y="0.0%"
        width="100.0%"
        height="100.0%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feGaussianBlur stdDeviation="0" in="SourceGraphic" />
      </filter>
      <filter
        x="0.0%"
        y="0.0%"
        width="100.0%"
        height="100.0%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feGaussianBlur stdDeviation="0" in="SourceGraphic" />
      </filter>
    </defs>
    <g id="Page-2" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g id="nps-step2-8-10" transform="translate(-589.000000, -238.000000)">
        <g id="Group" transform="translate(589.590278, 238.000000)">
          <path
            d="M74.427445,8.42331288 L20.970725,8.42331288 C20.970725,8.42331288 11.5990823,9.43488193 11.5990823,16.8895706 
            C11.5990823,24.3442592 20.970725,25.3558282 20.970725,25.3558282 L52.7758247,25.4163015 C52.7758247,25.4163015 
            60.2185692,26.8676599 60.2185692,33.398773 C60.2185692,39.9298861 52.7758247,41.8650307 52.7758247,41.8650307 L36.3437913,41.8650307 
            C36.3437913,41.8650307 23.0048466,42.6797948 23.0048466,50.7546012 C23.0048466,58.8294077 36.3437913,60.0674847 36.3437913,60.0674847 
            L9.47258391,60.0674847 C9.47258391,60.0674847 5.06374068e-16,61.7002629 0,68.4732691 C0,75.2462752 9.47258391,77 9.47258391,77 
            L217.193919,77 C217.193919,77 228.888558,75.6695881 228.888558,68.4732691 C228.888558,61.27695 217.193919,60.0674847 217.193919,60.0674847 
            L196.883512,60.0674847 C196.883512,60.0674847 185.023855,59.3602005 184.812045,52.0245399 C184.600235,44.6888793 196.883512,43.3163891 
            196.883512,43.3163891 L220.452862,42.5 C220.452862,42.5 232.754919,44.3142786 232.754919,34.1244522 C232.754919,23.9346259 
            221.437027,25.3558282 221.437027,25.3558282 L249.670247,25.4163015 C249.670247,25.4163015 259.819444,24.8060045 259.819444,16.8895706 
            C259.819444,8.97313658 249.670247,8 249.670247,8 L74.427445,8.42331288 Z"
            id="Path-8"
            fillOpacity="0.02"
            fill="#191919"
            filter="url(#filter-1)"
          />
          <ellipse
            id="Oval-6"
            fillOpacity="0.1"
            fill="#191919"
            filter="url(#filter-2)"
            cx="129.909722"
            cy="85"
            rx="29.9791667"
            ry="4"
          />
          <rect
            id="Rectangle-2"
            fillOpacity="0.3"
            fill="#191919"
            x="126.911806"
            y="49"
            width="5.99583333"
            height="35"
            rx="2.99791667"
          />
          <rect
            id="Rectangle-26"
            strokeOpacity="0.1"
            stroke="#000000"
            strokeWidth="2"
            fill={
              props.promoters
                ? props.theme.colors.correctColor
                : transparentize(props.theme.colors.textColor, 0.3, { solidColor: true })
            }
            x="100.930556"
            y="1"
            width="57.9583333"
            height="58"
            rx="5"
          />
          <text
            id="8"
            fontFamily="Charter-Roman, Charter"
            fontSize="32"
            fontWeight="normal"
            fill="#FFFFFF"
          >
            <tspan x="49.5%" y="47.5%" textAnchor="middle">
              {props.number}
            </tspan>
          </text>
        </g>
      </g>
    </g>
  </Figure>
);

export default withTheme(Rate);
