import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import media from 'components/mixins/media';
import * as typography from 'components/mixins/typography';

type PopoverContentProps = {
  isMatching: boolean;
  theme: any;
}
export const PopoverContent = styled.div<PopoverContentProps>`
  position: relative;
  outline: none;
  ${props =>
    props.isMatching
      ? css`
          &:before {
            content: '';
            display: block;
            position: absolute;
            left: 5px;
            right: 5px;
            bottom: 0;
            height: 16px;
            z-index: 11;
            background: linear-gradient(
              to top,
              ${props.theme.colors.contentBodyColor},
              ${transparentize(props.theme.colors.contentBodyColor, 0)}
            );
          }
        `
      : css``};

  .downshift-dropdown {
    position: static;
    width: auto;
    min-width: 210px;
    max-width: 340px;
    max-height: 360px;
    padding: 10px 0;
    background-color: ${props => props.theme.colors.contentBodyColor};
    border: none;
    border-radius: 2px;
    text-align: left;
    box-sizing: border-box;
    color: ${props => props.theme.colors.textColor};
    overflow-y: auto;
    z-index: 10;
    -webkit-overflow-scrolling: touch;
    transition: all 0.3s ease-out;

    ${media.xsmall`
      max-height: 200px;
      max-width: 250px;
    `};

    ${props =>
    props.isMatching
      ? css`
            ${media.small`
              max-width: 40vw;
            `};
            ${media.mobile`
              max-height: 90vh;
            `};
            ${media.xsmall`
              max-height: 200px;
              max-width: 210px;
            `};
            transition: none;
          `
      : css``};

    .dropdown-item {
      font-size: 16px;
    }
  }

  .dropdown-item {
    box-sizing: border-box;
    color: ${props => props.theme.colors.textColor};
    cursor: pointer;
    display: block;
    padding: 12px 24px;
    white-space: normal;
    ${typography.secondaryFontText};
    line-height: 22px;

    &.main-color {
      color: ${props => props.theme.colors.mainColor};
    }

    &.selected {
      color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
      text-decoration: line-through;
    }

    &.current-selected {
      color: ${props => props.theme.colors.mainColor};
    }

    &:hover {
      color: ${props => props.theme.colors.mainColor};
      background-color: ${props => transparentize(props.theme.colors.textColor, 0.05)};
      margin: auto 3px;
      padding: 12px 21px;

      &.selected {
        color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
      }
    }

    &:focus {
      color: ${props => props.theme.colors.mainColor};
      background-color: ${props => transparentize(props.theme.colors.textColor, 0.05)};
    }
  }
`;

export default PopoverContent;
