import ResponseMapperBase from './ResponseMapperBase';

export default class SingleChoiceImageResponseMapper extends ResponseMapperBase {
  mapResponse(question: any) {
    if (!this.hasQuestionResponse(question)) {
      return [];
    }

    const result: any[] = [];
    question.answers.forEach((answer: any, index: any) => {
      if (question.response.find((e: any) => e === answer.id)) {
        result.push(index);
      }
    });

    return result;
  }

  /**
   * @param {object} userAnswer
   * @param {object} question
   */
  unMap(userAnswer: any, { answers, correctAnswerId }: any) {
    if (this.isAnsweredCorrectly(userAnswer)) {
      return answers.filter(({ id }: any) => id === correctAnswerId).map(({ id }: any) => id);
    }

    if (this.isEmptyAnswer(userAnswer)) {
      return [];
    }

    return [answers[userAnswer].id];
  }
}
