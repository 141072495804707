import { useEffect, useRef } from 'react';

type Delay = number;
type TimerHandler = () => void;

const noop = () => {
  // do nothing
};

const useInterval = (callback: TimerHandler, delay: Delay) => {
  const savedCallbackRef = useRef<TimerHandler>();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = () => {
      if (savedCallbackRef.current) {
        return savedCallbackRef.current();
      }
      return noop();
    };
    const intervalId = setInterval(handler, delay);
    return () => clearInterval(intervalId);
  }, [delay]);
};

export default useInterval;
