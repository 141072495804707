class ResponseMapperBase {
  map(question: any) {
    if (question.isAnsweredCorrectly) {
      return 100;
    }

    if (!question.isAnswered) {
      return undefined;
    }

    return this.mapResponse(question);
  }

  mapResponse(question?: any) {
    throw new Error('Not implemented');
  }

  hasQuestionResponse(question: any) {
    return question.response !== null;
  }

  isAnsweredCorrectly(answer: any) {
    return answer === 100;
  }

  isEmptyAnswer(answer: any) {
    if (Array.isArray(answer) || typeof answer === 'string' || answer instanceof String) {
      return answer.length === 0;
    }

    if (answer.constructor === Object) {
      return Object.keys(answer).length === 0;
    }

    return false;
  }
}

export default ResponseMapperBase;
