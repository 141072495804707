import { useState, useEffect } from 'react';

type TimerProgressProviderProps = {
  valueStart: number;
  valueEnd: number;
  children(url: number): JSX.Element;
};

const TimerProgressProvider = ({
  valueStart = 0,
  valueEnd = 0,
  children
}: TimerProgressProviderProps) => {
  const [value, setValue] = useState(valueStart);
  useEffect(() => {
    setValue(valueEnd);
  }, [valueEnd]);

  return children(value);
};

export default TimerProgressProvider;
