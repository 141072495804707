import { createSelector } from 'reselect';
import { objectToArray } from 'utils/object';
import {
  MULTIPLE_CHOICE,
  SINGLE_CHOICE,
  SINGLE_CHOICE_IMAGE,
  STATEMENT_CHOICE,
  OPEN_QUESTION,
  RANKING_TEXT,
  SCENARIO,
  FILL_IN_THE_BLANK,
  DRAG_AND_DROP_TEXT,
  TEXT_MATCHING,
  HOTSPOT,
  INFORMATION_CONTENT
} from 'constants/questionTypes';
import { TimerStatus } from 'constants/timer';
import { getTimerStatus } from 'store/timer/selectors';
import * as multipleChoiceSelectors from './multipleChoice/selectors';
import * as statementChoiceSelectors from './statementChoice/selectors';
import * as openQuestionSelector from './openQuestion/selectors';
import * as rankingTextSelector from './rankingText/selectors';
import * as scenarioSelector from './scenario/selectors';
import * as fillInTheBlankSelector from './fillInTheBlank/selectors';
import * as dragAndDropTextSelector from './dragAndDropText/selectors';
import * as textMatching from './textMatching/selectors';
import * as hotspot from './hotspot/selectors';
import * as informationContent from './informationContent/selectors';
import { RootAppState } from '../types';
import {
  isAttemptsLimited,
  getLimitedAttemptsNumber,
  isQuestionPoolEnabled,
  getTimerEnabled
} from '../settings/selectors';

export const hasAvailableAttempt = (state: RootAppState, question: any) =>
  isAttemptsLimited(state)
    ? question.type !== INFORMATION_CONTENT &&
      question.score < 100 &&
      question.attemptNumber < getLimitedAttemptsNumber(state)
    : true;

export const isAttemptTimedOut = (state: RootAppState, question: any) =>
  getTimerEnabled(state)
    ? question.score < 100 && getTimerStatus(state) !== TimerStatus.STOPPED
    : true;

export const getQuestion = (state: RootAppState, questionId: string) => state.questions[questionId];

export const getQuestionSection = (state: RootAppState, questionId: string) =>
  state.questions[questionId].sectionId;

export const getQuestions = createSelector(
  (state: RootAppState) => state.questions,
  questions => objectToArray(questions)
);

export const getAffectProgressQuestions = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.filter(
        (question: any) => question.affectsProgress && question.isPoolQuestion
      );
    }
    return questions.filter((question: any) => question.affectsProgress);
  }
);

export const isAllQuestionsFailed = createSelector(
  getAffectProgressQuestions,
  state => state,
  (questions, state) => questions.every((question: any) => !hasAvailableAttempt(state, question))
);

export const isAllQuestionsTimedOut = createSelector(
  getAffectProgressQuestions,
  state => state,
  (questions, state) => questions.every((question: any) => !isAttemptTimedOut(state, question))
);

export const isAllQuestionsAnswered = createSelector(
  getQuestions,
  questions =>
    !questions.filter((question: any) => !question.isAnswered && question.affectsProgress).length
);

export const isAllSurveyQuestionsAnswered = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return true; // When questionPool is enabled there are no survey questions
    }
    return !questions.filter((question: any) => question.isSurvey && !question.isAnswered).length;
  }
);

export const getQuestionsCount = createSelector(getQuestions, questions => questions.length);

export const getQuestionAnswers = (state: RootAppState, questionId: string) =>
  state.questions[questionId].answers;

export const getQuestionResponse = (state: RootAppState, questionId: string) =>
  state.questions && state.questions[questionId] && state.questions[questionId].response;

export const getAffectProgressQuestionsCount = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.filter(
        (question: any) => question.affectsProgress && question.isPoolQuestion
      ).length;
    }
    return questions.filter((question: any) => question.affectsProgress).length;
  }
);

export const hasReAnswerableQuestions = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (!isAttemptsLimited(state)) {
      return true;
    }
    return questions.some(
      (question: any) =>
        !question.isAnsweredCorrectly &&
        question.type !== INFORMATION_CONTENT &&
        question.attemptNumber < getLimitedAttemptsNumber(state)
    );
  }
);

export const getAnsweredAffectProgressQuestionsCount = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.filter(
        (question: any) =>
          question.isAnswered && question.affectsProgress && question.isPoolQuestion
      ).length;
    }
    return questions.filter((question: any) => question.isAnswered && question.affectsProgress)
      .length;
  }
);

export const getAnsweredQuestionsCount = createSelector(
  getQuestions,
  questions => questions.filter((question: any) => question.isAnswered).length
);

export const getAnsweredQuestionsProgress = (state: RootAppState) => {
  const affectProgressQuestionsCount = getAffectProgressQuestionsCount(state);
  return affectProgressQuestionsCount > 0
    ? Math.floor(
        (getAnsweredAffectProgressQuestionsCount(state) * 100) / affectProgressQuestionsCount
      )
    : 0;
};

export const getCorrectlyAffectProgressAnsweredQuestionsCount = createSelector(
  getQuestions,
  questions =>
    questions.filter((question: any) => question.affectsProgress && question.isAnsweredCorrectly)
      .length
);

export const isQuestionAnswered = createSelector(
  getQuestion,
  question => question && question.isAnswered
);

export const isQuestionAnsweredCorrectly = createSelector(
  getQuestion,
  question => question && question.isAnsweredCorrectly
);

export const getQuestionAttempt = createSelector(
  getQuestion,
  question => question && question.attemptNumber
);

export const hasBeenOpened = createSelector(
  getQuestion,
  question => question && question.hasBeenOpened
);

export const hasCourseBeenStarted = createSelector(
  getQuestions,
  questions => questions.filter((question: any) => question.hasBeenOpened).length > 0
);

export const getLastOpenedQuestion = (questions: any[]) =>
  questions.reduce((acc, question) => (question.hasBeenOpened ? question : acc));

// TODO: should be Question type
export const getScore = (state: RootAppState, question: any) => {
  switch (question.type) {
    case SINGLE_CHOICE_IMAGE:
    case SINGLE_CHOICE:
    case MULTIPLE_CHOICE:
      return multipleChoiceSelectors.getScore(state, question);
    case STATEMENT_CHOICE:
      return statementChoiceSelectors.getScore(state, question);
    case OPEN_QUESTION:
      return openQuestionSelector.getScore(state, question);
    case RANKING_TEXT:
      return rankingTextSelector.getScore(state, question);
    case SCENARIO:
      return scenarioSelector.getScore(state, question);
    case FILL_IN_THE_BLANK:
      return fillInTheBlankSelector.getScore(state, question);
    case DRAG_AND_DROP_TEXT:
      return dragAndDropTextSelector.getScore(state, question);
    case TEXT_MATCHING:
      return textMatching.getScore(state, question);
    case HOTSPOT:
      return hotspot.getScore(state, question);
    case INFORMATION_CONTENT:
      return informationContent.getScore(state, question);
    default:
      throw new Error(`Unable to calculate score for ${question.type} question`);
  }
};

export const questionExists = (state: RootAppState, questionId: string) =>
  questionId && Object.keys(state.questions).includes(questionId);

export const isFeedbackAnimating = createSelector(
  getQuestion,
  question => question.isFeedbackAnimating
);

export const getPoolQuestions = (state: RootAppState) =>
  objectToArray(state.questions).filter((question: any) => question.isPoolQuestion);

export const getPoolQuestionIndex = (state: RootAppState, questionId: string) => {
  const questionsForPoolTemp: any = getPoolQuestions(state);
  const poolQuestionIDs: any = [];

  for (const question of questionsForPoolTemp) {
    poolQuestionIDs.push(question.id);
  }
  return poolQuestionIDs.indexOf(questionId);
};
export const getSectionId = (state: RootAppState, questionId: string) =>
  state.questions[questionId].sectionId;

export const isAllAffectedQuestionsAnswered = createSelector(
  getAffectProgressQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.every(({ isAnswered, isPoolQuestion }: any) => isAnswered && isPoolQuestion);
    }
    return questions.every((question: any) => question.isAnswered);
  }
);

export const getUnansweredQuestions = createSelector(
  getQuestions,
  state => state,
  (questions, state) => {
    if (isQuestionPoolEnabled(state)) {
      return questions.filter(
        ({ isAnswered, isPoolQuestion }: any) => !isAnswered && isPoolQuestion
      );
    }
    return questions.filter(({ isAnswered }: any) => !isAnswered);
  }
);

export const hasAnyQuestionOpened = createSelector(getQuestions, questions =>
  questions.some(question => question.hasBeenOpened)
);

export const getQuestionRetries = createSelector(getQuestion, question => question?.retries || 0);
