import * as React from 'react';
import { localize } from 'core/localization';
import Trophy from 'assets/figures/Trophy';
import ButtonVote from '../buttonVote';
import {
  Figure,
  TextElement,
  ButtonsListItem,
  ButtonsListWrapper,
  Wrapper
} from './EvaluateCourse.styled';

type EvaluateCourseProps = {
  onClick(data: any): void;
};

type EvaluateCourseState = {
  npsScore: number;
}

export class EvaluateCourse extends React.PureComponent<EvaluateCourseProps, EvaluateCourseState> {
  constructor(props: EvaluateCourseProps) {
    super(props);
    this.state = {
      npsScore: 0
    };
  }

  renderButtonsVote() {
    let items = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return (
      <ButtonsListWrapper>
        {items.map(item => (
          <ButtonsListItem key={item}>
            <ButtonVote
              rating={item}
              ratingLabelKey={
                (item === 1 && '[poor rating]') || ((item === 10 && '[excellent rating]') || '')
              }
              tabIndex={1}
              onClick={() => this.props.onClick(item)}
            />
          </ButtonsListItem>
        ))}
      </ButtonsListWrapper>
    );
  }

  render() {
    return (
      <Wrapper>
        <Figure>
          <Trophy />
        </Figure>
        <TextElement appearance="p" size={18}>
          {localize('[rate the course]')}
        </TextElement>
        {this.renderButtonsVote()}
      </Wrapper>
    );
  }
}


export default EvaluateCourse;
