import resourceLoader from 'core/http/resourceLoader';

export const SETTINGS_LOADED = 'SETTINGS_LOADED';
export const SETTINGS_LOADING_FAILED = 'SETTINGS_LOADING_FAILED';

class ConfigurationReader {
  readManifest() {
    return resourceLoader.getLocalJsonResource('manifest.json');
  }

  readSettings() {
    return resourceLoader.getLocalJsonResource('settings.js');
  }

  readPublishSettings() {
    return resourceLoader.getLocalJsonResource('publishSettings.js');
  }

  readThemeSettings() {
    return resourceLoader.getLocalJsonResource('themeSettings.js');
  }

  readCustomization() {
    return resourceLoader.getLocalJsonResource('customisations.json');
  }
}

export default new ConfigurationReader();
