import * as React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect } from 'react-redux';
import { CircleLoader } from 'components/common';
import * as loaderActions from 'store/loader/actions';
import { OverLayContainer } from './MainCircleLoader.styled';

export class MainCircleLoader extends React.Component<any, {}> {
  componentDidMount() {
    this.props.actions.openLoader();
  }

  componentWillUnmount() {
    this.props.actions.closeLoader();
  }

  render() {
    return (
      <OverLayContainer>
        <CircleLoader iconSize={96} />
      </OverLayContainer>
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch): { [key: string]: any } {
  return {
    actions: bindActionCreators(loaderActions, dispatch)
  };
}
export default connect(null, mapDispatchToProps)(MainCircleLoader);
