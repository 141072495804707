export type SectionsState = {
  [key: string]: {
    [key: string]: any;
  };
};

export enum ActionTypes {
  SECTIONS_LOADED = 'SECTIONS_LOADED',
  SECTION_SCORE_UPDATED = 'SECTION_SCORE_UPDATED',
  SECTION_IS_PASSED = 'SECTION_PASSED',
  SECTION_ANSWERED = 'SECTION_ANSWERED',
  SECTION_ANSWERED_SCORE = 'SECTION_ANSWERED_SCORE'
}

type SectionScoreUpdated = {
  [key: string]: any;
  type: ActionTypes.SECTION_SCORE_UPDATED;
  payload: {
    id: string;
    score: number;
    status: number;
  };
};

type SectionPassed = {
  [key: string]: any;
  type: ActionTypes.SECTION_IS_PASSED;
  payload: {
    id: string;
    status: number;
  };
};

type SectionAnswered = {
  [key: string]: any;
  type: ActionTypes.SECTION_ANSWERED;
  payload: {
    id: string;
    isSectionAnswered: boolean;
  };
};

type SectionAnsweredProgress = {
  [key: string]: any;
  type: ActionTypes.SECTION_ANSWERED_SCORE;
  payload: {
    id: string;
    sectionProgress: number;
  };
};

type SectionLoaded = {
  type: ActionTypes.SECTIONS_LOADED;
  payload: {
    [sections: string]: any;
  };
};

export type SectionsActionTypes =
  | SectionLoaded
  | SectionPassed
  | SectionScoreUpdated
  | SectionAnsweredProgress
  | SectionAnswered;
