import { events } from 'core/events/eventEmitter';
import BaseEventHandler from 'core/events/eventHandler';
import progressStorage from './progressStorage';

export default class EventHandler extends BaseEventHandler {
  handlers: any[];

  constructor() {
    super();
    this.handlers = [
      { event: events.APP_INITIALIZED, instance: this.appInitialize.bind(this) },
      { event: events.APP_NAVIGATED, instance: this.appNavigated.bind(this) },

      { event: events.COURSE_LAUNCHED, instance: this.courseLaunched.bind(this) },
      {
        event: events.COURSE_QUESTION_POOL_SELECTED,
        instance: this.courseQuestionPoolSelected.bind(this)
      },
      {
        event: events.COURSE_RANDOMIZED_OPTIONS_SAVED,
        instance: this.courseRandomizedOptionsSaved.bind(this)
      },
      {
        event: events.COURSE_PROGRESSED,
        instance: this.courseProgressed.bind(this)
      },
      {
        event: events.SUBMIT_ONCE_COURSE_PROGRESSED,
        instance: this.courseProgressed.bind(this)
      },
      {
        event: events.COURSE_ATTEMPT_STARTED,
        instance: this.courseAttemptStarted.bind(this)
      },
      {
        event: events.QUESTION_ANSWERED,
        instance: this.updateQuestionProgress.bind(this)
      },
      {
        event: events.SUBMIT_ONCE_QUESTION_ANSWERED,
        instance: this.updateQuestionProgress.bind(this)
      },
      {
        event: events.SUBMIT_ONCE_QUESTION_SUBMITTED,
        instance: this.updateCourseProgress.bind(this)
      },
      {
        event: events.COURSE_SUBMIT_AT_ONCE_LIMITED,
        instance: this.updateCourseAttemptProgress.bind(this)
      },
      {
        event: events.SUBMIT_ONCE_QUESTION_ATTEMPTED,
        instance: this.updateCourseProgress.bind(this)
      },
      {
        event: events.INFORMATION_CONTENT_EXPERIENCED,
        instance: this.updateQuestionProgress.bind(this)
      },
      {
        event: events.RESET_QUESTION_PROGRESS,
        instance: this.removeQuestionProgress.bind(this)
      },
      {
        event: events.RESET_TIMER_PROGRESS,
        instance: this.resetTimerProgress.bind(this)
      },
      {
        event: events.COURSE_TIMER_PROGRESSED,
        instance: this.courseProgressed.bind(this)
      },

      { event: events.USER_AUTHENTICATED, instance: this.userAuthorized.bind(this) },
      { event: events.USER_LOGOUT, instance: this.logout.bind(this) },

      {event: events.UPDATE_CHECKLIST_PROGRESS, instance: this.updateChecklistProgress.bind(this)}
    ];
  }

  appInitialize({ state, isScormMode }: any) {
    progressStorage.initialize({ state, isScormMode });
  }

  appNavigated({ url, state }: { [key: string]: any }) {
    // fix for browser back button
    if (progressStorage.url === url || url === '/') {
      return;
    }

    progressStorage.url = url;
    progressStorage.saveProgress(state);
  }

  courseLaunched(attemptId: any) {
    progressStorage.attemptId = attemptId;
  }

  courseQuestionPoolSelected({
    questionPoolIds,
    state
  }: {
    questionPoolIds: string[];
    state: any;
  }) {
    progressStorage.questionPool = questionPoolIds;
    progressStorage.saveProgress(state);
  }

  courseProgressed(state: any) {
    progressStorage.saveProgress(state);
  }

  courseAttemptStarted(state: any) {
    progressStorage.saveProgress(state);
  }

  courseRandomizedOptionsSaved({
    randomizedOptionsList,
    state
  }: {
    randomizedOptionsList: string[];
    state: any;
  }) {
    progressStorage.randomizedOptionsList = randomizedOptionsList;
    progressStorage.saveProgress(state);
  }

  updateQuestionProgress([question]: any) {
    progressStorage.updateQuestionProgress(question);
  }

  updateCourseProgress([allQuestionsSubmitted]: any) {
    progressStorage.updateCourseProgress(allQuestionsSubmitted);
  }

  updateCourseAttemptProgress(courseAttemptNumber: any) {
    progressStorage.updateCourseAttemptProgress(courseAttemptNumber);
  }
  removeQuestionProgress(questionId: any) {
    progressStorage.removeQuestionProgress(questionId);
  }

  resetTimerProgress() {
    progressStorage.resetTimerProgress();
  }

  userAuthorized(user: any) {
    progressStorage.user = user;
  }

  updateChecklistProgress( data: any) {
    progressStorage.updateChecklistProgress({id: data.id, isChecked: data.isChecked});
    progressStorage.saveProgress(data.state);
  }

  async logout({ state }: any) {
    await progressStorage.logout();
    this.appInitialize({ state });
  }
}
