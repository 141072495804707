export enum ActionTypes {
    QUESTION_MULTIPLECHOICE_TOGGLE_ANSWER_IS_SELECTED = 'QUESTION_MULTIPLECHOICE_TOGGLE_ANSWER_IS_SELECTED',
    QUESTION_MULTIPLECHOICE_SET_SINGLE_ANSWER_SELECTED = 'QUESTION_MULTIPLECHOICE_SET_SINGLE_ANSWER_SELECTED',
    SET_SINGLE_ANSWER_SELECTED = 'SET_SINGLE_ANSWER_SELECTED'
}

type AnswerIsSelected = {
    type: ActionTypes.QUESTION_MULTIPLECHOICE_TOGGLE_ANSWER_IS_SELECTED,
    payload: {
        questionId: string,
        answerId: string
    }
}

type MultipleChoiceSingleAnswerSelected = {
    type: ActionTypes.QUESTION_MULTIPLECHOICE_SET_SINGLE_ANSWER_SELECTED,
    payload: {
        questionId: string,
        answerId: string
    }
}

type SingleAnswerSelected = {
    type: ActionTypes.SET_SINGLE_ANSWER_SELECTED,
    payload: {
        questionId: string,
        answerId: string
    }
}

export type MultipleChoiceActionTypes = AnswerIsSelected | MultipleChoiceSingleAnswerSelected | SingleAnswerSelected;
