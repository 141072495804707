import ResponseMapperBase from './ResponseMapperBase';

export default class InformationContentResponseMapper extends ResponseMapperBase {
  mapResponse() {
    return 100;
  }

  /**
   * @param {object} userAnswer
   */
  unMap(userAnswer: any) {
    return this.isAnsweredCorrectly(userAnswer) ? 100 : undefined;
  }
}
