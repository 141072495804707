import styled, { css } from 'styled-components';
import media from 'components/mixins/media';

type HelperProps = {
  isMatchingOption: boolean;
  isMatching: boolean;
}
export const Helper = styled.div<HelperProps>`
  display: inline-flex;
  ${props =>
    props.isMatchingOption &&
    css`
      width: 100%;
    `}

  ${media.xsmall`
    display: block;
    line-height: 40px;
  `};

  .dropdown {
    display: block;
    outline: none;
    position: relative;

    ${props =>
      props.isMatchingOption &&
      css`
        width: 100%;
        max-height: 100%;
      `}

    ${props =>
      props.isMatching &&
      media.xsmall`
        line-height: 30px;
      `};
  }

  ${media.xsmall`
    max-width: 100%;
    width: 100%;
    padding: ${(props: HelperProps) => (props.isMatching ? '0' : '0 30px 0 14px')};
  `};
`;

export default Helper;
