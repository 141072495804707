import initialState from 'store/initialState';
import { ResourceActionTypes, ActionTypes } from './types';

export default function resourcesReducer(
  state = initialState.resource,
  action: ResourceActionTypes
) {
  switch (action.type) {
    case ActionTypes.RESOURCE_DATA_LOADED:
      return { ...state, ...action.payload };
    case ActionTypes.RESOURCE_DATA_LOADING_FAILED:
      return { ...state, ...{ isLoadingFailed: true } };
    default:
      return state;
  }
}
