import initialState from 'store/initialState';
import { ProgressStatus } from 'constants/progressStatus';
import { ActionTypes, CourseActionTypes } from './types';

export default function courseReducer(state = initialState.course, action: CourseActionTypes) {
  switch (action.type) {
    case ActionTypes.COURSE_DATA_LOADED:
      return { ...state, ...action.payload };
    case ActionTypes.COURSE_DATA_LOADING_FAILED:
      return { ...state, ...{ isLoadingFailed: true } };
    case ActionTypes.COURSE_PROGRESS_RESTORED:
      return { ...state, ...{ attemptId: action.payload.attemptId, hasBeenContinued: true } };
    case ActionTypes.COURSE_LAUNCHED:
      return { ...state, ...{ attemptId: action.payload.attemptId, isLaunched: true } };
    case ActionTypes.COURSE_SUBMIT_AT_ONCE:
      return {
        ...state,
        ...{ isAttemptSubmitted: true, reAttemptedSubmitAtOnce: false }
      };
    case ActionTypes.COURSE_SUBMIT_AT_ONCE_LIMITED:
      return {
        ...state,
        ...{
          courseAttempt: action.payload.courseAttempt
        }
      };
    case ActionTypes.COURSE_REATTEMPTED_SUBMIT_AT_ONCE:
      return {
        ...state,
        ...{ isAttemptSubmitted: false, reAttemptedSubmitAtOnce: true }
      };
    case ActionTypes.COURSE_SCORE_UPDATED:
      return {
        ...state,
        ...{
          score: action.payload.score,
          status: action.payload.status
        }
      };
    case ActionTypes.COURSE_CLEANUP:
      return {
        ...state,
        ...{
          score: 0,
          status: ProgressStatus.IN_PROGRESS,
          attemptId: null,
          hasBeenContinued: false,
          isLaunched: false,
          isProgressRestoreFailed: false,
          isProgressRestoreFetched: false,
          isAttemptSubmitted: false,
          reAttemptedSubmitAtOnce: false,
          courseAttempt: 0,
          isCourseFinished: false
        }
      };
    case ActionTypes.CLEAR_PREVIEW_QUESTION_ID:
      delete state.previewQuestionId;
      return state;
    case ActionTypes.CERTIFICATE_DOWNLOADED:
      return { ...state, ...action.payload };
    case ActionTypes.COURSE_IS_PASSED:
      return { ...state, ...action.payload };
    case ActionTypes.COURSE_PROGRESS_RESTORE_FAILED:
      return { ...state, ...{ isProgressRestoreFailed: true } };
    case ActionTypes.COURSE_PROGRESS_RESTORE_FETCHED:
      return { ...state, ...{ isProgressRestoreFetched: true } };
    case ActionTypes.TIME_SPENT_UPDATED:
      return { ...state, ...action.payload };
    case ActionTypes.COURSE_IS_FAILED:
      return { ...state, ...action.payload };
    case ActionTypes.COURSE_RANDOMIZED_OPTIONS_LOADED:
      return { ...state, ...{ isRandomizedOptionsLoaded: true } };
    case ActionTypes.COURSE_FINISHED:
      return { ...state, ...{ isCourseFinished: true } };
    default:
      return state;
  }
}
