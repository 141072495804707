import { createSelector } from 'reselect';
import { COURSE_TIMER_PROGRESS_KEY } from 'constants/timer';
import * as comparison from '../../utils/comparison';
import {
  getSections,
  getQuestions,
  affectsProgress,
  getAffectProgressSections
} from '../sections/selectors';
import { isAccessLimited, getAllowedUsers } from '../settings/selectors';
import { getEmail, isAnonymous, getUserId } from '../user/selectors';
import { INFORMATION_CONTENT } from '../../constants/questionTypes';
import { RootAppState } from '../types';

export const getCourseTitle = (state: RootAppState) => state.course.title;

export const getCourseIntroductions = (state: RootAppState) => state.course.introductions;

export const getCourse = (state: RootAppState) => state.course;

export const getStatus = (state: RootAppState) => state.course.status;

export const isPassed = (state: RootAppState) => comparison.isPassed(state.course.status);

export const isInProgress = (state: RootAppState) => comparison.isInProgress(state.course.status);

export const isFailed = (state: RootAppState) => comparison.isFailed(state.course.status);

export const getCourseId = (state: RootAppState) => state.course.id;

export const getTemplateId = (state: RootAppState) => state.course.templateId;

export const getAttemptId = (state: RootAppState) => state.course.attemptId;

export const hasBeenContinued = (state: RootAppState) => state.course.hasBeenContinued;

export const getCourseAttempt = (state: RootAppState) => state.course.courseAttempt;

export const getPreviewQuestionId = (state: RootAppState) => state.course.previewQuestionId;

export const isAttemptSubmitted = (state: RootAppState) => !!state.course.isAttemptSubmitted;

export const getTimeSpent = (state: RootAppState) =>
  new Date().getTime() -
  state.course.timeSpent.incrementStartedAt.getTime() +
  state.course.timeSpent.accumulatedValueInMilliseconds;

export const getCourseStructure = createSelector(
  (state: RootAppState) => state,
  (state: RootAppState) => ({
    title: getCourseTitle(state),
    sections: getSections(state).map(section => ({
      title: section.title,
      id: section.id,
      status: section.status,
      isSectionAnswered: section.isSectionAnswered,
      sectionProgress: section.sectionProgress,
      score: section.score,
      learningObjective: section.learningObjective,
      affectsProgress: affectsProgress(state, section.id),
      questions: getQuestions(state, section.id).map((question: any) => ({
        id: question.id,
        title: question.title,
        isInformationContent: question.type === INFORMATION_CONTENT,
        isAnswered: question.isAnswered,
        isAnsweredCorrectly: question.isAnsweredCorrectly,
        affectsProgress: question.affectsProgress,
        isSurvey: question.isSurvey,
        hasBeenOpened: question.hasBeenOpened,
        type: question.type
      }))
    })),
    isAttemptSubmitted: isAttemptSubmitted(state)
  })
);

export const getQuestionsStructure = createSelector(
  (state: RootAppState) => getCourseStructure(state),
  ({ sections }) => {
    const questionsSchema: string[] = [];

    sections.forEach(({ questions }) => {
      questions.forEach(({ id }: { [key: string]: any }) => {
        questionsSchema.push(id);
      });
    });

    return questionsSchema;
  }
);
export const isCourseAllAnswered = (state: RootAppState) => {
  return getSections(state).filter((section: any) => !section.isSectionAnswered).length === 0;
};

export const isCourseAccessLimited = (state: RootAppState) => {
  const email = getEmail(state);
  return (
    (!!email || isAnonymous(state)) &&
    isAccessLimited(state) &&
    !getAllowedUsers(state).some((item: any) => item.email === email)
  );
};

export const getScore = (state: RootAppState) => state.course.score;

export const isCertificateDownloaded = (state: RootAppState) =>
  state.course.isCertificateDownloaded;

export const getPassedAfterwords = (state: RootAppState) => state.course.passedAfterwords;

export const getFailedAfterwords = (state: RootAppState) => state.course.failedAfterwords;

export const isFinalized = (state: RootAppState) => state.course.score === 100;

export const isCourseLaunched = (state: RootAppState) => state.course.isLaunched;

export const isProgressRestoreFailed = (state: RootAppState) =>
  state.course.isProgressRestoreFailed;

export const isProgressRestoreFetched = (state: RootAppState) =>
  state.course.isProgressRestoreFetched;

export const isCoursePassed = createSelector(getAffectProgressSections, sections =>
  sections.every(section => comparison.isPassed(section.status))
);

export const isCourseFailed = createSelector(
  getAffectProgressSections,
  state => state,
  (sections, state) =>
    !isCoursePassed(state) && sections.every(section => !comparison.isInProgress(section.status))
);

export const getTimerProgressKey = (state: RootAppState) =>
  `${COURSE_TIMER_PROGRESS_KEY}${getCourseId(state)}${getTemplateId(state)}${getUserId(state)}`;

export const isRandomizedOptionsLoaded = (state: RootAppState) =>
  state.course.isRandomizedOptionsLoaded;
