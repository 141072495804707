import * as React from 'react';
import { withTheme } from 'styled-components';
import { transparentize } from 'utils/color';
import { Container, ContentLoader } from './Loader.styled';

type LoaderProps = {
  height?: number;
  width?: number;
  speed?: number;
  theme: { [key: string]: any };
};

export const Loader = (props: LoaderProps) => {
  const { height = 8, width = 400, speed = 2 } = props;
  const space = 16;
  return (
    <Container>
      <ContentLoader
        height={height * 2 + space}
        width={width}
        speed={speed}
        primaryColor={transparentize(props.theme.colors.textColor, 0.08, { solidColor: true })}
        secondaryColor={transparentize(props.theme.colors.textColor, 0.1, { solidColor: true })}
      >
        <rect x="0" y="0" rx="3" ry="3" width={width / 2 - 10} height={height} />
        <rect x="0" y={height + space} rx="3" ry="3" width={width} height={height} />
      </ContentLoader>
    </Container>
  );
};

export default withTheme(Loader);
