import { getQuestion } from 'store/questions/selectors';
import { RootAppState } from 'store/types';

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  let question = getQuestion(state, questionId);
  const responseAnswer = question.response !== null ? question.response : '';
  return {
    id: question.id,
    answeredText: !responseAnswer ? '' : responseAnswer.answeredText // Fix for undefined
  };
};

export const getScore = (state: RootAppState, question: any) => {
  const responseAnswer = question.response !== null ? question.response : ' ';
  if (responseAnswer && responseAnswer.answeredText) {
    return responseAnswer.answeredText.trim() ? 100 : 0;
  }
  return 0;
};
