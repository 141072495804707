import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import throttle from 'lodash.throttle';
import { getColors, getFonts, getBackground, isResource } from 'store/settings/selectors';
import SomethingWentWrong from 'pages/errors/somethingWentWrong';
import { withRouter } from 'react-router-dom';
import * as actions from 'store/app/actions';
import { isAppLoading, isAppLoadingFailed, isOffline } from 'store/app/selectors';
import withScrollOnRedirect from 'components/hocs/withScrollOnRedirect';
import { getTreeOfContentVisibility } from 'store/treeOfContent/selectors';
import { isAuthenticated } from 'store/user/selectors';
import { isCourseAccessLimited } from 'store/course/selectors';
import withNavigation from 'components/hocs/withNavigation';
import { RootAppState } from 'store/types';
import ConnectionOverlay from 'pages/errors/ConnectionAlert';
import { CircleLoader } from '../common';

const Course = React.lazy(() => import('../../pages/course/Course'));
const Resource = React.lazy(() => import('../../pages/resource/Resource'));

type ShellProps = {
  colors: { [key: string]: any };
  fonts: { [key: string]: any };
  background: { [key: string]: any };
  actions: { [key: string]: any };
  isLoading: boolean;
  isLoadingFailed: boolean;
  isOffline: boolean;
  isLowResolution: boolean;
  isTocExpanded: boolean;
  isUserAuthenticated: boolean;
  location: { [key: string]: any };
  userAccessIsLimited: boolean;
  navigateToUrl(url: string): void;
  resources: { [key: string]: any };
  isResource: { [key: string]: any };
  courseLaunched: boolean;
  popupActions: { [key: string]: any };
  isCourseProgressRestoreFailed: boolean;
  navigateToIndex(): void;
};

type ShellState = {
  hasError: boolean;
  showAlert: boolean;
};

export class Shell extends React.Component<ShellProps, ShellState> {
  constructor(props: ShellProps) {
    super(props);
    this.state = {
      hasError: false,
      showAlert: false
    };
  }

  windowResizeHandler = throttle(() => {
    this.props.actions.resolutionChanged();
  }, 500);

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowResizeHandler);
  }

  async componentDidMount() {
    window.addEventListener('resize', this.windowResizeHandler);
    this.props.actions.resolutionChanged();

    await this.props.actions.load();
  }

  componentDidUpdate(prevProps: ShellProps): void {
    if (this.props.isOffline && prevProps.isOffline !== this.props.isOffline) {
      this.setState({ showAlert: true });
    }

    if (prevProps.isOffline && prevProps.isOffline !== this.props.isOffline) {
      setTimeout(() => {
        this.setState({
          showAlert: false
        });
      }, 2000);
    }
  }

  componentDidCatch(error: any, errorInfo: any) {
    console.error(`Component did catch: ${error}, ${errorInfo}`);
    this.setState({ hasError: true });
  }

  render() {
    const { isLoading, isLoadingFailed, isOffline } = this.props;
    const { hasError, showAlert } = this.state;

    if (showAlert) {
      return <ConnectionOverlay isOffline={isOffline} />;
    }

    if (isLoading) {
      return <CircleLoader iconSize={96} />;
    }

    if (isLoadingFailed || hasError) {
      return <SomethingWentWrong hasUnhandledError={hasError} />;
    }

    return (
      <React.Suspense fallback={<CircleLoader iconSize={96} />}>
        {this.props.isResource ? <Resource {...this.props} /> : <Course {...this.props} />}
      </React.Suspense>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    colors: getColors(state),
    fonts: getFonts(state),
    isOffline: isOffline(state),
    isLoading: isAppLoading(state),
    isLoadingFailed: isAppLoadingFailed(state),
    isLowResolution: state.app.isLowResolution,
    isTocExpanded: getTreeOfContentVisibility(state),
    background: getBackground(state),
    isUserAuthenticated: isAuthenticated(state),
    userAccessIsLimited: isCourseAccessLimited(state),
    isResource: isResource(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    actions: bindActionCreators(actions, dispatch)
  };
}

export default withRouter(
  withNavigation(connect(mapStateToProps, mapDispatchToProps)(withScrollOnRedirect(Shell)))
);
