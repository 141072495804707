import * as React from 'react';
import { BUTTON, SIZES } from 'constants/components';
import CircleLoader from 'components/common/CircleLoader';
import { ButtonElement, ButtonLinkElement, TextElement, IconElement } from './Button.styled';

export interface Layout {
  [BUTTON.LAYOUT.PRIMARY]: string;
  [BUTTON.LAYOUT.SECONDARY]: string;
}
export interface Size {
  [SIZES.SMALL]: string;
  [SIZES.MEDIUM]: string;
}
type ButtonProps = {
  type?: string;
  suffixIcon?: string;
  disabled: boolean;
  children: React.ReactNode;
  onClick?(e?: any): void;
  layout: keyof Layout;
  size: keyof Size;
  textTransform?: string;
  tabIndex?: number;
  onBlur?(e?: any): void;
  onFocus?(e?: any): void;
  onKeyDown?(e?: any): void;
  link?: string;
  isLoading?: boolean;
  loaderIconSize: number;
  target?: string;
  inverted: boolean;
  theme?: any;
  id?: string;
};

export const Button = (props: Readonly<ButtonProps>) => {
  const {
    disabled,
    onClick,
    children,
    type,
    layout,
    suffixIcon,
    textTransform,
    tabIndex,
    link,
    onBlur,
    onFocus,
    isLoading,
    loaderIconSize,
    target,
    ...other
  } = props;

  const Element: React.ReactType = link ? ButtonLinkElement : ButtonElement;
  return (
    <Element
      onClick={onClick}
      disabled={disabled}
      type={type}
      layout={layout}
      suffixIcon={suffixIcon}
      textTransform={textTransform}
      tabIndex={tabIndex}
      href={link}
      onBlur={onBlur}
      onFocus={onFocus}
      target={target || '_blank'}
      isLoading={isLoading}
      theme={props.theme}
      {...other}
    >
      <TextElement theme={props.theme} ellipsis layout={layout}>
        {children}
      </TextElement>
      {suffixIcon && (
        <IconElement name={suffixIcon} color="buttonTextColor" theme={props.theme} size={12} />
      )}
      {isLoading && <CircleLoader iconSize={loaderIconSize} />}
    </Element>
  );
};

Button.defaultProps = {
  layout: BUTTON.LAYOUT.PRIMARY,
  size: SIZES.MEDIUM,
  isLoading: false,
  loaderIconSize: 24,
  disabled: false,
  tabIndex: 0,
  inverted: false
};

export default Button;
