import React from 'react';
import { IconElement, TextElement } from './Icon.styled';

type IconProps = {
  name: string;
  color?: any;
  opacity?: any;
  getColor?(): void;
  className?: string;
  size?: number;
  text?: string;
  fontSize?: number;
  shapeSize?: any;
  role?: string;
  ariaLabel?: string;
  tabIndex?: number;
  onKeyDown?: any;
  onClick?: any;
  theme?: { [key: string]: any };
  isBlur?: boolean;
};
export const Icon = (props: IconProps) => {
  const {
    name = 'ok',
    className = 'buttonTextColor',
    text = '',
    fontSize = 2,
    size = 8,
    opacity = 1,
    ...other
  } = props;
  return (
    <IconElement
      className={`icon icon-${name} ${className || ''}`}
      size={size}
      opacity={opacity}
      {...other}
    >
      <TextElement theme={props.theme} fontSize={fontSize}>
        {text}
      </TextElement>
    </IconElement>
  );
};

export default Icon;
