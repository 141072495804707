import * as React from 'react';
import { Wrapper, DescriptionIcon, DescriptionText } from './DescriptionWithBackground.styled';

type DescriptionWithBackgroundProps = {
  iconName: string;
  iconSize: number;
  descriptionText: string | null;
  descriptionTextAppearance?: any;
  descriptionChildren?: any;
};

export const DescriptionWithBackground = ({
  iconName = 'info',
  iconSize = 2,
  descriptionText = null,
  descriptionTextAppearance = 'p',
  descriptionChildren = null
}: DescriptionWithBackgroundProps) => (
    <Wrapper>
      <DescriptionIcon size={iconSize} name={iconName} />
      <DescriptionText appearance={descriptionTextAppearance}>
        {descriptionText}
        {descriptionChildren}
      </DescriptionText>
    </Wrapper>
  );

export default DescriptionWithBackground;
