import { createSelector } from 'reselect';
import { RootAppState } from '../types';
/* eslint-disable import/prefer-default-export */
export const getContents = createSelector(
  state => state,
  (state: RootAppState, ids: string[]) => ids,
  (state, ids) => {
    let contents: any[] = [];
    if (ids) ids.forEach(id => contents.push(state.contents[id]));
    return contents;
  }
);
/* eslint-enable */
