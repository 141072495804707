import { LOGIN_PATH } from 'constants/navigation';
import { getCurrentUrl } from 'utils/window';

class SocialNetworks {
  courseTitle: any;

  authStorageUrl: any;

  location: any;

  constructor(courseTitle: any, authStorageUrl: any) {
    this.courseTitle = courseTitle;
    this.authStorageUrl = authStorageUrl;
    this.location = `${getCurrentUrl()}#${LOGIN_PATH}`;
  }

  getAuthLink(socialNetwork: any) {
    const isIframe = window.top !== window.self;
    const isMainHost =
      window.location.host.indexOf('easygenerator.com') === -1 ? '&addTokenQuery=true' : '';

    let addTokenQueryParam = '';
    if (isIframe || !isMainHost) {
      addTokenQueryParam = '&addTokenQuery=true';
    }
    const encodeLocation = encodeURIComponent(this.location);

    return `${this.authStorageUrl}/api/auth/${socialNetwork}?callbackUrl=${encodeLocation}${addTokenQueryParam}`;
  }
}

export default SocialNetworks;
