import { QUESTION_PATH, SECTION_PATH, LEARNING_OBJECTIVE_PATH } from 'constants/navigation';
import pathToRegexp from 'path-to-regexp';
import { getCurrentLocationHash, getCurrentUrl } from 'utils/window';

const SECTION_ID = ':sectionId';

export const getQuestionUrl = (sectionId: string, questionId: string) =>
  QUESTION_PATH.replace(SECTION_ID, sectionId).replace(':questionId', questionId);

export const getLearningObjectiveUrl = (sectionId: string) =>
  LEARNING_OBJECTIVE_PATH.replace(SECTION_ID, sectionId);

export const getQuestionAbsoluteUrl = (sectionId: string, questionId: string) =>
  `${getCurrentUrl()}#${getQuestionUrl(sectionId, questionId)}`;

export const getSectionUrl = (sectionId: string) => SECTION_PATH.replace(SECTION_ID, sectionId);

export const getSectionAbsoluteUrl = (sectionId: string) =>
  `${getCurrentUrl()}#${getSectionUrl(sectionId)}`;

export const getLocationParams = (path: string) => {
  const keys: any[] = [];
  const match = pathToRegexp(path, keys).exec(getCurrentLocationHash().replace('#', ''));
  return keys.reduce((acc, key, index) => {
    acc[key.name] = (match ? match[index + 1] : null) || null;
    return acc;
  }, {});
};
