import styled from 'styled-components';
import { Text } from 'components/common';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';
import { EditableContentWrapper } from 'components/common/EditableTextarea/EditableTextarea.styled'

export const Figure = styled.div`
  margin: 45px 0;
`;

export const TextElement = styled(Text)`
  font-weight: bold;
  color: ${props => props.theme.colors.textColor};
  text-align: center;
`;

export const FeedbackContainer = styled.div`
  margin-bottom: 40px;
  & ${TextElement} {
    margin: 0 25px 20px;
  }
`;

export const EditableTextareaContainer = styled.div`
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};
  box-shadow: inset 0 2px 0 0 rgba(39, 17, 0, 0.06);
  padding: 10px 23px 45px;
  ${EditableContentWrapper} {
    padding: 0;
  }
  & textarea {
    min-height: 160px;
    font-size: 14px;
    font-family: ${props => props.theme.fonts.secondaryFont};
  }
  ${media.mobile`
    padding: 10px 10px 45px;
    textarea {
        padding: 4px 5px 29px;
    }
`};
`;

export const Wrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  margin-top: 40px;
  z-index: 1;
  ${media.mobile`
    margin-top: 70px;
  `};
`;
