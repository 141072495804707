import * as React from 'react';
import { LINK } from 'constants/components';
import { SECTIONS_PATH } from 'constants/navigation';
import LinkElement from './Link.styled';

export interface LinkProps {
  text?: string;
  size?: number;
  layout?: string;
  onClick(): void;
  onBlur?(): void;
  href?: string;
  opacity?: number;
  tabIndex?: number;
}

export const Link = (props: LinkProps) => {
  const {
    onClick,
    onBlur,
    text,
    layout = LINK.LAYOUT.DEFAULT,
    href = `#${SECTIONS_PATH}`,
    size = 13,
    opacity = 0.4,
    tabIndex = 0,
    ...restProps
  } = props;
  return (
    <LinkElement
      onClick={(event: any) => {
        event.preventDefault();
        onClick();
      }}
      onKeyPress={(event: any) => {
        event.preventDefault();
        onClick();
      }}
      layout={layout}
      href={href}
      size={size}
      opacity={opacity}
      tabIndex={tabIndex}
      {...restProps}
    >
      {text}
    </LinkElement>
  );
};

export default Link;
