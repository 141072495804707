import uuidv4 from 'uuid/v4';
import deepmerge from 'deepmerge';

type ObjType = { [key: string]: any };

export function renameProperty(obj: ObjType, currPropName: string, newPropName: string) {
  const modifiedObj: ObjType = obj || {};

  modifiedObj[newPropName] = modifiedObj[currPropName];
  delete modifiedObj[currPropName];
}

export function guid() {
  return uuidv4().replace(/[-]/g, '');
}

export function guidWithHyphens() {
  return uuidv4();
}

export function toggleArrayElement(array: any[], element: any): any[] {
  const index = array.indexOf(element);
  if (index !== -1) {
    array.splice(index, 1);
  } else {
    array.push(element);
  }
  return array;
}

export function toggleArrayStatementElement(array: any[], element: any): any[] {
  const index = array.indexOf(element);
  if (index !== -1) {
    array.splice(index, 1);
  }
  if (
    array.some(e => e.id === element.id) &&
    array.find(s => s.id === element.id).hasOwnProperty('isCorrect')
  ) {
    array.find(s => s.id === element.id).isCorrect = element.isCorrect;
  } else {
    array.push(element);
  }
  return array;
}

export function objectToArray(value: ObjType): any[] {
  return Object.keys(value).reduce((acc: any[], item: string) => {
    acc.push(value[item]);
    return acc;
  }, []);
}

function overwriteMerge(destinationArray: any[], sourceArray: any[]): any[] {
  return sourceArray.filter((item: any) => item != null);
}

export function deepMerge(obj1: ObjType, obj2: ObjType): object {
  if (Object.keys(obj2).length === 0) {
    return obj1;
  }
  return deepmerge(obj1, obj2, {
    arrayMerge: overwriteMerge
  });
}

export function isArray(item: any): boolean {
  return item && item.constructor && item.constructor === Array;
}

export function isObject(item: any): boolean {
  return item && item.constructor && item.constructor === Object;
}

export function isEmptyObject(item: any): boolean {
  return isObject(item) && Object.keys(item).length === 0;
}

export function deepExtend(destination: any, source: any): any {
  if (destination === null || destination === undefined) {
    return source;
  }

  for (const property in source) {
    if (source[property] && (isObject(source[property]) || isArray(source[property]))) {
      if (
        (destination.hasOwnProperty(property) && hasInternalObjectsOrArrays(source[property])) ||
        (!isArray(destination) &&
          !isArray(source) &&
          isObject(destination[property]) &&
          isObject(source[property]) &&
          !hasSameNumbersOfKeys(destination[property], source[property]))
      ) {
        deepExtend(destination[property], source[property]);
      } else {
        if (isArray(destination)) {
          const { index, deleteNumber } = getItemPositionValues(destination, source[property].key);
          destination.splice(index, deleteNumber, source[property]);
          continue;
        }

        destination[property] = source[property];
      }
    } else {
      destination[property] = source.hasOwnProperty(property)
        ? source[property]
        : destination[property];
    }
  }
  return destination;
}

export function hasInternalObjectsOrArrays(object: ObjType): boolean {
  let counter = 0;
  for (const property in object) {
    if (object[property] && (isObject(object[property]) || isArray(object[property]))) {
      counter++;
    }
  }
  return counter > 0;
}

export function hasSameNumbersOfKeys(destinationObj: ObjType, sourceObj: ObjType): boolean {
  return Object.keys(destinationObj).length === Object.keys(sourceObj).length;
}

type ReplacedItem = {
  index: number;
  deleteNumber: number;
};

function getItemPositionValues(destination: any, sourceKey: any): ReplacedItem {
  const index = destination.findIndex((item: any) => item.key === sourceKey);
  const shouldReplaceItem = index !== -1;

  return {
    index: shouldReplaceItem ? index : destination.length,
    deleteNumber: shouldReplaceItem ? 1 : 0
  };
}

export function arrayToObject(array: any[]): ObjType {
  return array.reduce((accum: ObjType, [k, v]: [string, any]) => {
    accum[k] = v;
    return accum;
  }, {});
}
