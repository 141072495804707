import { getCourseId, getTemplateId } from 'store/course/selectors';
import { isCourse, isResource } from 'store/settings/selectors';
import { getResourceId } from 'store/resource/selectors';
import StorageInterface from '../storageInterface';
import { RootAppState } from 'store/types';

const constants = {
  courseProgressKey: 'course_progress',
  resourceProgressKey: 'resource_progress'
};

class LocalStorage extends StorageInterface {
  progressKey: string;
  constructor(state: any) {
    super();

    this.progressKey = this.getProgressKey(state);
  }

  async getProgress() {
    return new Promise(resolve => {
      const result = localStorage.getItem(this.progressKey) || '{}';
      resolve(JSON.parse(result));
    });
  }

  async saveProgress(progress: any) {
    return new Promise(resolve => {
      localStorage.setItem(this.progressKey, JSON.stringify(progress));
      resolve();
    });
  }

  async removeProgress() {
    return new Promise(resolve => {
      localStorage.removeItem(this.progressKey);
      resolve();
    });
  }

  private getProgressKey(state: RootAppState): string {
    let result = '';
    if (isCourse(state)) {
      const courseId = getCourseId(state);
      const templateId = getTemplateId(state);
      result = `${constants.courseProgressKey}${courseId}${templateId}`;
    } else if (isResource(state)) {
      const resourceId = getResourceId(state);
      result = `${constants.resourceProgressKey}${resourceId}`;
    }
    return result;
  }
}

export default LocalStorage;
