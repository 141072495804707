import { deepMerge } from 'utils/object';
import resourceLoader from 'core/http/resourceLoader';
import { DEFAULT_LANGUAGE_CODE, CUSTOM_LANGUAGE_CODE } from 'constants/common';

// eslint-disable-next-line import/prefer-default-export
export const getTranslations = async function(settings: { [key: string]: any }) {
  const languageCode = settings.languages.selected.toLowerCase();

  if (languageCode === CUSTOM_LANGUAGE_CODE) {
    const defaultTranslations = await loadTranslation(DEFAULT_LANGUAGE_CODE);
    return deepMerge(defaultTranslations, settings.languages.customTranslations);
  }

  document.documentElement.setAttribute('lang', languageCode);

  return loadTranslation(languageCode);
};

function loadTranslation(code: any) {
  return resourceLoader.getLocalJsonResource(`lang/${code}.json`);
}
