import styled, { css } from 'styled-components';
import { ICON, SIZES } from 'constants/components';
import { transparentize } from 'utils/color';
import { IconElement } from 'components/common/Icon/Icon.styled';
import media from 'components/mixins/media';
import { pointerOnHover, transition } from 'components/mixins/interactivity';
import { defaultBoxShadowColor } from 'constants/common';

const roundFullColor = css`
  border-radius: 50%;
  background-color: ${props => getColor(props)};
`;

const roundOutlined = css`
  border-radius: 50%;
  border: 1px solid ${props => getColor(props)};
`;

const roundShaded = css`
  border-radius: 50%;
  background: ${props => props.theme.colors.contentBodyColor};
  box-shadow: 0 2px 4px 0 ${transparentize(defaultBoxShadowColor, 0.15)};
`;

type IconShapeProps = {
  size: any;
  shape?: any;
  hoverShape?: any;
  getHoverColor?: any;
  shapeColor?: any;
  shapeOpacity?: any;
};
/* eslint-disable import/prefer-default-export */
export const IconShape = styled.div<IconShapeProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  width: ${props => getShapeSize(props.size)}px;
  height: ${props => getShapeSize(props.size)}px;

  ${transition('border, background-color, box-shadow')};

  & ${IconElement} {
    ${transition('none')};
  }

  ${props => props.shape === ICON.SHAPES.ROUND_FULL_COLOR && roundFullColor};
  ${props => props.shape === ICON.SHAPES.ROUND_OUTLINED && roundOutlined};
  ${props => props.shape === ICON.SHAPES.ROUND_SHADED && roundShaded};

  ${props =>
    props.size === SIZES.LARGE &&
    media.xsmall`
      width: 20px;
      height: 20px;
  `};

  ${props =>
    props.hoverShape &&
    css`
      ${pointerOnHover};
      &:hover,
      &:focus {
        background-color: ${props.theme.colors.contentBodyColor};
        border: none;
        box-shadow: none;
        ${props.hoverShape === ICON.SHAPES.ROUND_SHADED && roundShaded};
      }
    `};

  ${props =>
    props.getHoverColor &&
    css`
      ${pointerOnHover};
      &:hover ${IconElement}, &:focus ${IconElement} {
        color: ${getHoverColor(props)};
      }
    `};
`;

function getHoverColor(props: any) {
  if (props.getHoverColor) {
    return props.getHoverColor(props.theme.colors);
  }
}

function getColor(props: any) {
  if (props.getShapeColor) {
    return props.getShapeColor(props.theme.colors);
  }

  return transparentize(props.theme.colors[props.shapeColor], props.shapeOpacity || 1);
}

function getShapeSize(size: any) {
  if (typeof size === 'number') {
    return size;
  }

  switch (size) {
    case SIZES.SMALL:
      return 16;
    case SIZES.MEDIUM:
      return 24;
    default:
      return 30;
  }
}
