import styled from 'styled-components';
import media from 'components/mixins/media';
import { Button, Text } from 'components/common';
import { TextElement } from 'components/common/Button/Button.styled';
import { secondaryFont } from 'components/mixins/typography';
import { transparentize } from 'utils/color';
import buttonDefaultStyle from 'components/mixins/normalize';
import { defaultBoxShadowColor } from 'constants/common';

export const IconContainer = styled.div`
  display: flex;
  width: 15px;
  height: 100%;
  align-items: center;
  padding: 3px;
  opacity: 0.3;
  transition: opacity 0.2s linear;
`;

export const ItemText = styled(Text)`
  text-align: left;
  color: ${props => transparentize(props.theme.colors.textColor, 0.7)};
`;

type ListItemProps = {
  tabIndex: string;
};

export const ListItem = styled(Button).attrs((props: ListItemProps) => ({
  tabIndex: props.tabIndex
}))`
  ${buttonDefaultStyle};
  display: flex;
  align-items: center;
  width: 100%;
  height: auto;
  padding: 16px;
  border-radius: 0;
  line-height: 0;
  cursor: pointer;

  ${TextElement} {
    display: flex;
    align-items: center;
    font-weight: normal;
  }

  ${IconContainer} {
    opacity: 0.5;
    margin-right: 8px;
  }

  &:hover,
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.textColor, 0.05)};
    ${IconContainer} {
      opacity: 1;
    }

    ${ItemText} {
      color: ${props => transparentize(props.theme.colors.textColor, 1)};
    }
  }
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.textColor, 0.05)};
    outline: 1px solid black;
    ${IconContainer} {
      opacity: 1;
    }

    ${ItemText} {
      color: ${props => transparentize(props.theme.colors.textColor, 1)};
    }
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

export const ActionListContainer = styled.div.attrs({
  'data-test': 'action-list-container'
})`
  position: relative;
  width: 225px;
  border-radius: 2px;
  padding: 0;
  background-color: ${props => props.theme.colors.contentBodyColor};
`;

export const AvatarLetterElement = styled.span`
  font-size: 12px;
  ${secondaryFont};
  font-weight: bold;
  color: ${props => props.theme.colors.buttonTextColor};
`;

export const AvatarLetterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 34px;
  height: 34px;
  margin-right: 4px;
  background-color: ${props => props.theme.colors.mainColor};
  border-radius: 20px;
`;

export const TimerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

type ExpandableWrapperProps = {
  ariaHidden?: any;
  tabIndex?: number;
};
export const ExpandableWrapper = styled.button.attrs((props: ExpandableWrapperProps) => ({
  'aria-hidden': props.ariaHidden,
  'data-test': 'user-menu-dropdown-toggler',
  tabIndex: props.tabIndex
}))<ExpandableWrapperProps>`
  ${buttonDefaultStyle};
  display: flex;
  padding: 0;
  align-items: center;
  border-radius: 20px;
  cursor: pointer;
  transition: all 0.1s ease-out, focus 0s;

  &:hover,
  &:active {
    outline: none;
    ${IconContainer} {
      opacity: 0.7;
    }
  }
  &:focus {
    outline: 1px solid black;
    background: none;
    ${IconContainer} {
      opacity: 0.7;
    }
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

type MenuContainerProps = {
  ariaHidden?: any;
};
export const MenuContainer = styled.div.attrs((props: MenuContainerProps) => ({
  'aria-hidden': props.ariaHidden
}))<MenuContainerProps>`
  display: flex;
  align-items: center;
  height: 40px;
  padding: 3px;
  position: absolute;
  background-color: ${props => props.theme.colors.contentBodyColor};
  box-shadow: 0 2px 10px 0 ${transparentize(defaultBoxShadowColor, 0.1)};
  border-radius: 20px;
  top: 20px;
  right: 30px;
  z-index: 105;

  ${media.mobile`
    top: 10px;
    right: 10px;
  `};
`;
