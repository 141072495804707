import React from 'react';
import { withTheme } from 'styled-components';
import { localize } from 'core/localization';
import { Figure } from './Figure.styled';

type LostConnectionProps = {
  theme: { [key: string]: any };
};
export const LostConnection = (props: LostConnectionProps) => (
  <Figure
    width="260px"
    height="135px"
    viewBox="0 0 260 135"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    arialabel={`${localize('[continue later aria label]')}`}
  >
    <title>2F4B8814-1795-4431-B359-0884AE1D4E74</title>
    <desc>Created with sketchtool.</desc>
    <defs>
      <linearGradient id="ljzn3byk2g" x1="50%" x2="50%" y1="0%" y2="59.083%">
        <stop offset="0%" stopColor="#FFF" />
        <stop offset="100%" stopColor="#FBFBFC" />
      </linearGradient>
      <filter
        id="lmn9khmtja"
        width="100%"
        height="100%"
        x="0%"
        y="0%"
        filterUnits="objectBoundingBox"
      >
        <feGaussianBlur in="SourceGraphic" />
      </filter>
    </defs>
    <g fill="none" fillRule="evenodd">
      <g>
        <g>
          <g fill="#363E4E" opacity=".02">
            <g>
              <path
                d="M74.48.423H20.984s-9.378 1.012-9.378 8.467c0 7.454 9.378 8.466 9.378 8.466l31.828.06s7.447 1.452 7.447 7.983c0 6.53-7.447 8.466-7.447 
                  8.466H36.368s-13.348.815-13.348 8.89c0 8.074 13.348 9.312 13.348 9.312H9.479S0 53.7 0 60.473C0 67.246 9.48 69 9.48 69h207.865s11.703-1.33 
                  11.703-8.527c0-7.196-11.703-8.406-11.703-8.406H197.02s-11.868-.707-12.08-8.042c-.211-7.336 12.08-8.709 12.08-8.709l23.586-.816s12.31 1.814 
                  12.31-8.376-11.325-8.768-11.325-8.768l28.253.06S260 16.806 260 8.89C260 .973 249.844 0 249.844 0L74.479.423z"
                filter="url(#lmn9khmtja)"
                transform="translate(-590 -196) translate(590 199) translate(0 36) translate(0 6)"
              />
            </g>
          </g>
          <g transform="translate(-590 -186) translate(590 199) translate(71 5)">
            <ellipse cx="59" cy="110" fill="#F5F7FB" rx="20" ry="4" />
            <g fillRule="nonzero">
              <circle
                fill="#F85D67"
                opacity="0.8"
                id="tcpqn4dule"
                cx="59.034"
                cy="82.618"
                r="6.585"
              />
            </g>
          </g>
          <g fillRule="nonzero" transform="translate(-590 -186) translate(590 199)">
            <path
              id="b1bmyi6zbf"
              fill="#fff"
              d="M129.5 0h1c3.314 0 6 2.686 6 6v63c0 3.314-2.686 6-6 6h-1c-3.314 0-6-2.686-6-6V6c0-3.314 2.686-6 6-6z"
            />
            <path
              fill="#F85D67"
              fillOpacity=".7"
              fillRule="evenodd"
              stroke="url(#ljzn3byk2g)"
              strokeWidth="3"
              d="M130.5-1.5c2.071 0 3.946.84 5.303 2.197C137.161 2.054 138 3.929 138 6h0v63c0 2.071-.84 3.946-2.197 5.303-1.357 1.358-3.232 
              2.197-5.303 2.197h0-1c-2.071 0-3.946-.84-5.303-2.197C122.839 72.946 122 71.071 122 69h0V6c0-2.071.84-3.946 
              2.197-5.303C125.554-.661 127.429-1.5 129.5-1.5h0z"
            />
          </g>
        </g>
      </g>
    </g>
  </Figure>
);

export default withTheme(LostConnection);
