import styled, { css } from 'styled-components';
import * as typography from 'components/mixins/typography';

const normalizeFocus = () => css`
  &:focus {
    outline: none;
    border: none;
  }
`;

type HeadersProps = {
  title: string;
  tabIndex: string | number;
};
export const Header1 = styled.h1.attrs(({ tabIndex = 1, title }: HeadersProps) => ({
  title,
  tabIndex
}))`
  ${normalizeFocus};
  ${typography.mainFontH1};
`;

export const Header2 = styled.h2.attrs(() => ({
  tabIndex: 0
}))`
  ${normalizeFocus};
  ${typography.mainFontH2};
`;

export const Header3 = styled.h3.attrs(() => ({
  tabIndex: 0
}))`
  ${normalizeFocus};
  ${typography.mainFontH3};
`;

export const Header4 = styled.h4`
  ${typography.mainFontH4};
  ${normalizeFocus};
`;

export const Span = styled.span`
  ${typography.secondaryFontSmallText};
  line-height: normal;
`;

export const Paragraph = styled.p`
  ${typography.secondaryFontText};
`;

export const ellipsisMixin = css`
  display: inline-block;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const sizeMixin = (size: number) => css`
  font-size: ${size}px;
`;

export const fontMixin = (fontName: string) => css`
  font-family: ${props => props.theme.fonts[fontName].fontFamily};
`;
