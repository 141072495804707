import eventEmitter from 'core/events/eventEmitter';

export default abstract class BaseEventHandler {
  abstract handlers: any[];

  on() {
    this.handlers.forEach((handler: any) => eventEmitter.on(handler.event, handler.instance));
  }

  off() {
    this.handlers.forEach((handler: any) => eventEmitter.off(handler.event, handler.instance));
  }
}
