import idShortener from './idShortener';
import responseMappers from './responseMappers';

class IdMapper extends idShortener {
  map(id: any) {
    return this.convertToShort(id);
  }

  unMap(id: any) {
    return this.convertToOriginal(id);
  }

  mapResponse(questionType: string, response: any) {
    return responseMappers[questionType].map(response);
  }

  unMapResponse(questionType: any, response: any) {
    return responseMappers[questionType].unMap(response);
  }
}

export default new IdMapper();
