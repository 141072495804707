import * as React from 'react';

import { localize } from 'core/localization';
import Rate from 'assets/figures/Rate';
import { Button } from 'components/common';
import EditableTextarea from 'components/common/EditableTextarea';
import {
  Figure,
  TextElement,
  FeedbackContainer,
  EditableTextareaContainer,
  Wrapper
} from './FeedbackStep.styled';

type FeedbackStepProps = {
  onChange(e: any): void;
  onSubmit(): void;
  npsScore: number;
};

export class FeedbackStep extends React.PureComponent<FeedbackStepProps, {}> {

  render() {
    const { npsScore, onChange, onSubmit } = this.props;
    let score = npsScore / 10;
    return (
      <Wrapper>
        <TextElement appearance="h3">{localize('[you gave a rate of]')}</TextElement>
        <Figure>
          <Rate number={score} promoters={score > 6} />
        </Figure>
        <FeedbackContainer>
          <TextElement appearance="p" size={18}>
            {localize('[course score reason]')}
          </TextElement>
          <EditableTextareaContainer>
            <EditableTextarea
              placeholder={`${localize('[placeholder textarea]')}`}
              tabIndex={1}
              onChange={(e: any) => onChange(e)}
              rows={1}
              isAutofocus={true}
            />
          </EditableTextareaContainer>
        </FeedbackContainer>
        <Button tabIndex={1} onClick={onSubmit}>
          {localize('[submit]')}
        </Button>
      </Wrapper>
    );
  }
}

export default FeedbackStep;
