export enum ActionTypes {
    QUESTION_DRAG_AND_DROP_ANSWERS_ARE_SELECTED = 'QUESTION_DRAG_AND_DROP_ANSWERS_ARE_SELECTED'
}

type AnswerSelected = {
    type: ActionTypes.QUESTION_DRAG_AND_DROP_ANSWERS_ARE_SELECTED,
    payload: {
        answers: any,
        questionId: string
    }
}

export type DragAndDropActionTypes = AnswerSelected;
