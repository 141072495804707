import idShortener from '../idShortener';

export default class StatementChoiceResponseMapper extends idShortener {
  map(response: any) {
    return this._map(response, this.convertToShort.bind(this));
  }

  unMap(response: any) {
    return this._map(response, this.convertToOriginal.bind(this));
  }

  _map(response: any, convert: any) {
    return response.map((option: any) => ({ ...option, id: convert(option.id) }));
  }
}
