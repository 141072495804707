import xss from '@easygenerator/xss';
import resourceLoader from './resourceLoader';

export default {
  async loadMaterialData() {
    let response = await resourceLoader.getLocalResource(`content/data.js`);
    return response.json();
  },
  async loadStaticHtmlContent(url: string) {
    let response = await resourceLoader.getLocalResource(`content/${url}`);
    return xss.filter(await response.text());
  }
};
