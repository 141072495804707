import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';

type StatusIconProps = {
  passedMark: any;
  progress: any;
}
export const StatusIcon = styled.div<StatusIconProps>`
  position: absolute;
  left: 50%;
  top: 50%;
  bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  background: ${props => props.theme.colors.correctColor};
  border-radius: 50%;
  visibility: hidden;
  opacity: 0;
  transform: translate(-50%, -50%) scale(0);
  ${props =>
    props.passedMark <= props.progress &&
    css`
      visibility: visible;
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    `}
`;

export const ProgressWrapper = styled.div`
  padding: 10px 0 8px;
  ${StatusIcon} {
    transition: all 0.3s;
  }
  &:hover ${StatusIcon} {
    visibility: visible;
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
`;
type ProgressBarMarkProps = {
  top: number;
  passedMark: any;
  height: any;
  styles: any;
}
export const ProgressBarMark = styled.div<ProgressBarMarkProps>`
  position: absolute;
  top: ${props => (props.top ? props.top : 0)}px;
  left: ${props => (props.passedMark ? props.passedMark : 0)}%;
  height: ${props => (props.height ? props.height : 2)}px;
  width: 2px;
  border-radius: 1px;
  background-color: ${props =>
    props.styles
      ? transparentize(props.theme.colors[props.styles.color], props.styles.opacity)
      : transparentize(props.theme.colors.coverBgOverlayTextColor, 0.5)};
`;
