import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

export enum AnimationTypes {
  slideInDown = 'slideInDown',
  fade = 'fade',
  fadeInDown = 'fadeInDown'
};

interface Types {
  [AnimationTypes.slideInDown]: string;
  [AnimationTypes.fade]: string;
  [AnimationTypes.fadeInDown]: string;
}

type CssAnimationProps = {
  isVisible?: boolean;
  type: keyof Types;
  animateAppear?: boolean;
  animateLeave?: boolean;
  timeout?: number;
  children: any;
};

class CssAnimation extends React.Component<CssAnimationProps, {}> {
  static AnimationType = AnimationTypes
  static defaultProps = {
    timeout: 300,
    isVisible: true,
    animateAppear: true,
    animateLeave: true
  }

  render() {
    const { isVisible, animateAppear, animateLeave, children, type, timeout = 0 } = this.props;

    return (
      <CSSTransition
        in={isVisible}
        appear={animateAppear}
        exit={animateLeave}
        classNames={type}
        timeout={timeout}
        unmountOnExit
      >
        {children}
      </CSSTransition>
    );
  }
}

export default CssAnimation;
