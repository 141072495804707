import { DEFAULT_AUTH_URL, DEFAULT_LEARN_URL } from 'constants/progressStorage';
import {
  DEFAULT_ALTERNATIVE_LOGO_URL,
  DEFAULT_BACKGROUND,
  DEFAULT_COLORS,
  DEFAULT_FONTS
} from 'constants/defaults';
import getImageResizeUrl from 'components/mixins/images';
import { RootAppState } from 'store/types';
import { MATERIAL_TYPES } from '../../constants/common';
import { getMillisecondsFromTime } from 'utils/timer';
import { objectToArray } from 'utils/object';

export enum ShowAllQuestionAndContentItemsTypes {
  SEPARATE_PAGES = 'separatePages',
  ONE_PAGE = 'onePage'
}

export enum SubmitAnswerTypes {
  AFTER_EVERY_QUESTION = 'afterEveryQuestion',
  ALL_QUESTIONS_AT_ONCE = 'allQuestionsAtOnce'
}

export enum PreviousAttemptAnswerTypes {
  SHOW = 'show',
  RESET = 'reset'
}

export enum AnswerOptionsOrderTypes {
  SET_BY_AUTHOR = 'setByAuthor',
  RANDOMIZE = 'randomize'
}

export enum QuestionPoolRandomizationTypes {
  SAME_ON_EVERY_ATTEMPT = 'sameOnEveryAttempt',
  DIFFERENT_ON_EVERY_ATTEMPT = 'differentOnEveryAttempt'
}

export const isSettingsLoadingFailed = (state: RootAppState) => state.settings.isLoadingFailed;

export const getFonts = (state: RootAppState) => state.settings.template?.fonts || DEFAULT_FONTS;

export const isAttemptsLimited = (state: RootAppState) =>
  state.settings.template &&
  state.settings.template.numberOfAttempts &&
  state.settings.template.numberOfAttempts.isLimited;

export const getLimitedAttemptsNumber = (state: RootAppState) =>
  state.settings.template &&
  state.settings.template.numberOfAttempts &&
  state.settings.template.numberOfAttempts.attemptsLimit
    ? state.settings.template.numberOfAttempts.attemptsLimit
    : 0;

export const getColors = (state: RootAppState) =>
  state.settings.template.branding?.colors || DEFAULT_COLORS;

export const getLogo = (state: RootAppState) =>
  state.settings.template && getImageResizeUrl(state.settings.template.branding.mainLogo, 400, 160);

export const getAlternativeLogo = (state: RootAppState) =>
  getImageResizeUrl(
    state.settings.template?.branding?.alternativeLogo || DEFAULT_ALTERNATIVE_LOGO_URL,
    720,
    400
  );

export const isMainLogoShown = (state: RootAppState) =>
  state.settings.template && state.settings.template.branding.allowToShowMainLogo;

export const getBackground = (state: RootAppState) =>
  state.settings.template?.background || DEFAULT_BACKGROUND;

export const getTranslations = (state: RootAppState) => state.settings.translations;

export const isResultsTrackingEnabled = (state: RootAppState) =>
  state.settings.template.resultsTracking && state.settings.template.resultsTracking.enabled;

export const getXapiSettings = (state: RootAppState) =>
  state.settings.template && state.settings.template.xApi;

export const isScoringOfContentPagesAllowed = (state: RootAppState) =>
  state.settings.template.allowScoringOfContentPages &&
  state.settings.template.allowScoringOfContentPages.enabled;

export const isShowContentPagesEnabled = (state: RootAppState) =>
  state.settings.template.showContentPages.enabled;

export const shouldSubmitEachQuestions = (state: RootAppState) =>
  state.settings.template.submitAnswers &&
  state.settings.template.submitAnswers.selected &&
  state.settings.template.submitAnswers.selected === SubmitAnswerTypes.AFTER_EVERY_QUESTION;

export const shouldSubmitAllQuestions = (state: RootAppState) =>
  state.settings.template.submitAnswers &&
  state.settings.template.submitAnswers.selected &&
  state.settings.template.submitAnswers.selected === SubmitAnswerTypes.ALL_QUESTIONS_AT_ONCE;

export const getPublishSettings = (state: RootAppState) =>
  state.settings.template && state.settings.publish;

export const getAuthoringToolUrl = (state: RootAppState) =>
  state.settings.publish.hasOwnProperty('authoringToolUrl') &&
  state.settings.publish.authoringToolUrl;

export const isCrossDeviceSavingEnabled = (state: RootAppState) =>
  state.settings.template.saveProgressCrossDevice &&
  state.settings.template.saveProgressCrossDevice.enabled;

export const isNpsEnabled = (state: RootAppState) =>
  state.settings.template.conductLearnerSatisfactionSurvey &&
  state.settings.template.conductLearnerSatisfactionSurvey.enabled;

export const isAccessLimited = (state: RootAppState) =>
  (state.settings.publish &&
    state.settings.publish.accessLimitation &&
    state.settings.publish.accessLimitation.enabled) ||
  false;

export const isAllowToSkipTrackingAndTracingEnabled = (state: RootAppState) =>
  state.settings.template.allowUserToSkipTrackingAndTracing &&
  state.settings.template.allowUserToSkipTrackingAndTracing.enabled;

export const isTrackingEnabled = (state: RootAppState) =>
  isCrossDeviceSavingEnabled(state) ||
  isResultsTrackingEnabled(state) ||
  isNpsEnabled(state) ||
  isAccessLimited(state);

export const isLoginViaSocialMediaEnabled = (state: RootAppState) =>
  state.settings.template.loginViaSocialMedia &&
  state.settings.template.loginViaSocialMedia.enabled;

export const isScormMode = (state: RootAppState) =>
  state.settings.template && state.settings.publishMode && state.settings.publishMode.isScorm;
export const isScorm2004Mode = (state: RootAppState) =>
  state.settings.template && state.settings.publishMode && state.settings.publishMode.isScorm2004;
export const isPreviewMode = (state: RootAppState) =>
  state.settings.template && state.settings.publishMode && state.settings.publishMode.isPreview;
export const isReviewMode = (state: RootAppState) =>
  state.settings.template && state.settings.publishMode && state.settings.publishMode.isReview;
export const isPublishMode = (state: RootAppState) =>
  !(isScormMode(state) || isPreviewMode(state) || isReviewMode(state));

export const getAuthServiceUrl = (state: RootAppState) =>
  state.settings.publish && state.settings.publish.authServiceUrl
    ? `${state.settings.publish.authServiceUrl}`
    : DEFAULT_AUTH_URL;

export const getLearnServiceUrl = (state: RootAppState) =>
  state.settings.publish && state.settings.publish.learnServiceUrl
    ? `${state.settings.publish.learnServiceUrl}`
    : DEFAULT_LEARN_URL;

export const getAllowedUsers = (state: RootAppState) =>
  isAccessLimited(state) ? state.settings.publish.accessLimitation.allowedUsers : [];

export const getQuestionShortIds = (state: RootAppState) =>
  isScormMode(state) ? state.settings.publish.questionShortIds : [];

export const getSelectedLanguage = (state: RootAppState) =>
  state.settings.template.languages && state.settings.template.languages.selected;

export const isAllowedDownloadCertificate = (state: RootAppState) =>
  state.settings.template.allowUsersToDownloadCertificates &&
  state.settings.template.allowUsersToDownloadCertificates.enabled;

export const isOverallMasteryScore = (state: RootAppState) =>
  state.settings.template.masteryScore && state.settings.template.masteryScore.isOverall;

export const getMasteryScoreValue = (state: RootAppState) =>
  state.settings.template.masteryScore && state.settings.template.masteryScore.score;

export const isAllQuestionsOnOnePage = (state: RootAppState) =>
  state.settings.template.showAllQuestionAndContentItems &&
  state.settings.template.showAllQuestionAndContentItems.selected &&
  state.settings.template.showAllQuestionAndContentItems.selected ===
    ShowAllQuestionAndContentItemsTypes.ONE_PAGE;

export const isResource = (state: RootAppState) =>
  state.settings.publish.materialType === MATERIAL_TYPES.RESOURCE;

export const isCourse = (state: RootAppState) =>
  state.settings.publish.materialType === MATERIAL_TYPES.COURSE;

export const analyticsWriteKey = (state: RootAppState) => state.settings.publish.analyticsWriteKey;

export const isShowLearningHintsEnabled = (state: RootAppState) =>
  state.settings.template.showLearningHints && state.settings.template.showLearningHints.enabled;

export const isQuestionPoolEnabled = (state: RootAppState) =>
  state.settings.template.questionPool && state.settings.template.questionPool.enabled;

export const getQuestionPoolSize = (state: RootAppState) => {
  let questionPoolSize =
    state.settings.template.questionPool && state.settings.template.questionPool.size
      ? state.settings.template.questionPool.size
      : 0;

  const nonSurveyQuestions = objectToArray(state.questions).filter( question => !question.isSurvey)
  if (questionPoolSize && nonSurveyQuestions.length < questionPoolSize) {
      questionPoolSize = nonSurveyQuestions.length;
  }

  return questionPoolSize;
};

export const isQuestionRandomizeEnabled = (status: RootAppState) =>
  status.settings.template.questionPool &&
  status.settings.template.questionPool.subset &&
  status.settings.template.questionPool.subset.type ===
    QuestionPoolRandomizationTypes.SAME_ON_EVERY_ATTEMPT &&
  status.settings.template.questionPool.subset.randomizeQuestionOrder;

export const isNewSubsetOnEveryAttempt = (status: RootAppState) =>
  status.settings.template.questionPool &&
  status.settings.template.questionPool.subset &&
  status.settings.template.questionPool.subset.type ===
    QuestionPoolRandomizationTypes.DIFFERENT_ON_EVERY_ATTEMPT;

export const isRandomizeAnswersEnabled = (state: RootAppState) =>
  state.settings.template.answerOptionsOrder?.selected === AnswerOptionsOrderTypes.RANDOMIZE;

export const isAnswersFromPreviousAttemptEnabled = (state: RootAppState) =>
  state.settings.template.answersFromPreviousAttempt &&
  state.settings.template.answersFromPreviousAttempt.selected === PreviousAttemptAnswerTypes.SHOW;

export const getTimerEnabled = (state: RootAppState) => state.settings.template.timer.enabled;

export const getTimerLimit = (state: RootAppState) =>
  getMillisecondsFromTime(state.settings.template.timer?.time);
