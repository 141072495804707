import { css } from 'styled-components';
import { loadFontsFromGoogle } from 'core/fonts/loader';

export const loadReviewFont = async () => {
  await loadFontsFromGoogle({
    fontFamily: 'Open Sans',
    fontWeight: [400, 600]
  });
};

export const reviewFontText = css`
  font-family: 'Open Sans';
  font-size: 11px;
  line-height: 11px;
  color: #42515f;
`;

export default reviewFontText;
