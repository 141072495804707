import { getQuestion } from 'store/questions/selectors';
import { RootAppState } from '../../types';

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  const responseAnswer = question.response ? question.response : [];
  return {
    id: question.id,
    background: question.background,
    isMultiple: question.isMultiple,
    spots: question.spots.map((spot: any) => spot),
    markers: !responseAnswer.length ? [] : responseAnswer
  };
};

export const getScore = (state: RootAppState, question: any) => {
  const responseAnswer = question.response !== null ? question.response : [];
  const answers: any[] = [];
  const correctMarkers: any[] = [];
  const { spots } = question;

  if (!question.spots.length) {
    return 100;
  }

  if (!responseAnswer.length) {
    return 0;
  }

  function markIsInSpot(mark: any, spot: any) {
    const { x } = mark;
    const { y } = mark;

    let inside = false;
    for (let i = 0, j = spot.length - 1; i < spot.length; j = i, i++) {
      const xi = spot[i].x;
      const yi = spot[i].y;
      const xj = spot[j].x;
      const yj = spot[j].y;

      // eslint-disable-next-line
      const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
      if (intersect) {
        inside = !inside;
      }
    }

    return inside;
  }

  for (let i = 0; i < spots.length; i++) {
    let isMarkerInsideInSpot = false;

    for (let k = 0; k < responseAnswer.length; k++) {
      if (markIsInSpot(responseAnswer[k], spots[i])) {
        if (!isMarkerInsideInSpot) {
          isMarkerInsideInSpot = true;
          answers.push(true);
        }
        correctMarkers.push(responseAnswer[k]);
      }
    }
    if (isMarkerInsideInSpot) {
      continue;
    }
    answers.push(false);
  }

  if (correctMarkers.length === responseAnswer.length) {
    if (question.isMultiple && answers.some(element => element === false)) {
      return 0;
    }
    return 100;
  }

  if (!question.background && !spots.length) {
    return 100;
  }

  return 0;
};
