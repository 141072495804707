import { getQuestion } from 'store/questions/selectors';
import { isRandomizeAnswersEnabled } from 'store/settings/selectors';
import { RootAppState } from 'store/types';

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  const randomizeAnswersEnabled = isRandomizeAnswersEnabled(state);
  const { answers: options, randomizedOptions = [] } = question;
  const answers = randomizeAnswersEnabled ? randomizedOptions : options;

  return answers.map((answer: any) => {
    const responseAnswer = question.response.find((r: any) => r.id === answer.id);
    return {
      id: answer.id,
      isCorrect: answer.isCorrect,
      text: answer.text,
      userSelected: !!responseAnswer, // Fix for undefined
      isTrue: responseAnswer ? responseAnswer.isCorrect : false
    };
  });
};

export const getScore = (state: RootAppState, question: any) => {
  const originalAnswers = question.answers.map((answer: any) => ({
    id: answer.id,
    isCorrect: answer.isCorrect
  }));
  const userAnswers = question.response.map((answer: any) => ({
    id: answer.id,
    isCorrect: answer.isCorrect
  }));

  originalAnswers.sort(compare);
  userAnswers.sort(compare);

  if (JSON.stringify(originalAnswers) === JSON.stringify(userAnswers)) {
    return 100;
  }
  return 0;
};

function compare(prev: any, next: any) {
  if (getStringHash(prev.id) > getStringHash(next.id)) {
    return -1;
  }
  return 1;
}

function getStringHash(text: string) {
  let hash = 0;
  let chr;
  if (text.length === 0) {
    return hash;
  }
  for (let index = 0; index < text.length; index++) {
    chr = text.charCodeAt(index);
    // eslint-disable-next-line
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
}
