import { schema } from 'normalizr';

const afterword = new schema.Entity(
  'contents',
  {},
  {
    processStrategy: (value: any, parent: any, key: string) => {
      const result = {
        ...value,
        value: undefined
      };
      switch (key) {
        case 'children':
          result.url = `afterword/${value.id}.html`;
          break;
        case 'passedAfterwords':
          result.url = `afterword/${value.id}.html`;
          break;
        case 'failedAfterwords':
          result.url = `afterword/${value.id}.html`;
          break;
        default:
          throw new Error('Content entity key is not supported');
      }
      return result;
    }
  }
);

afterword.define({
  children: [afterword]
});

export default afterword;
