import SingleChoiceResponseMapper from './SingleChoice';

export default class MultipleChoiceResponseMapper extends SingleChoiceResponseMapper {
  map(response: any) {
    return super.map(response);
  }

  unMap(response: any) {
    return super.unMap(response);
  }
}
