import idShortener from '../idShortener';

export default class SingleChoiceResponseMapper extends idShortener {
  map(response: any) {
    return response.map((id: any) => this.convertToShort(id));
  }

  unMap(response: any) {
    return response.map((id: any) => this.convertToOriginal(id));
  }
}
