export type TreeOfContentState = {
  isExpanded: boolean;
  isSubmitButtonVisible: boolean;
};

export enum ActionTypes {
  TOC_TOGGLE_VISIBILITY = 'TOC_TOGGLE_VISIBILITY',
  COURSE_ENABLE_SUBMIT_BUTTON = 'COURSE_ENABLE_SUBMIT_BUTTON'
}

type ToggleTOCVisibility = {
  type: ActionTypes.TOC_TOGGLE_VISIBILITY;
};

type EnableSubmitButton = {
  type: ActionTypes.COURSE_ENABLE_SUBMIT_BUTTON;
  payload: {
    isSubmitButtonVisible: boolean;
  };
};

export type TOCActionTypes = ToggleTOCVisibility | EnableSubmitButton;
