import shortId from 'short-uuid';

export default class IdShortener {
  translator: any;
  constructor() {
    this.translator = shortId();
  }

  convertToShort(id: any) {
    return this.translator.fromUUID(id);
  }

  convertToOriginal(id: any) {
    return this.translator.toUUID(id).replace(/-/g, '');
  }
}
