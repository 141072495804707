export const REVIEW_USER_EMAIL = 'reviewerUsermail';
export const REVIEW_USER_NAME = 'reviewerUsername';
export const HINT_FOR_SPOT_COMMENT = 'hintForSpotComment';
export const HINT_FOR_GENERAL_COMMENT = 'hintForGeneralComment';

export const COMMENT_SENDING_FAILED = 'commentSendingFailed';
export const STATUS_OK = 'statusOk';
export const REFERRER_POLICY = 'no-referrer-when-downgrade';

export const REVIEW_COMMENT_PROPERTIES: { [key: string]: string } = {
  DEFAULT_TITLE: 'defaultTitle',
  TITLE: 'title',
  INTRODUCTION: 'introduction',
  LEARNING_CONTENT: 'learningContent',
  QUESTION: 'question',
  VOICE_OVER: 'voiceOver',
  CONTENT_ITEM: 'contentItem',
  TITLE_OF_THE_SECTION: 'titleOfTheSection',
  GENERAL: 'general'
};

export const REVIEW_COMMENT_TYPES: { [key: string]: string } = {
  QUESTION: 'question',
  INFORMATION_CONTENT: 'informationContent',
  COURSE: 'course',
  SECTION: 'section'
};
