import { ActionTypes, TextMatchingActionTypes } from './types';
import { QuestionsState } from '../types';

export default function reducer(state: QuestionsState, action: TextMatchingActionTypes) {
  switch (action.type) {
    case ActionTypes.QUESTION_MATCHING_ANSWERS_ARE_SELECTED:
      return action.payload.answers;
    default:
      return state;
  }
}
