import initialState from 'store/initialState';
import { ActionTypes, LoaderActionTypes } from './types';

export default function loaderReducer(state = initialState.loader, action: LoaderActionTypes) {
  switch (action.type) {
    case ActionTypes.OPEN_LOADER:
      return { ...state, ...{ isLoaded: true } };
    case ActionTypes.CLOSE_LOADER:
      return { ...state, ...{ isLoaded: false } };
    default:
      return state;
  }
}
