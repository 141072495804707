import styled from 'styled-components';

type SeparatorTypes = {
  margin?: number;
  height?: number;
  opacity?: number;
  color?: string;
  marginBottom?: number;
};
export const Separator = styled.div<SeparatorTypes>`
  position: relative;
  width: 100%;
  height: ${({ height = 2, margin = 32, opacity = 0.08, ...rest }: SeparatorTypes) =>
    height + margin * 2}px;
  margin-bottom: ${props =>
    props.marginBottom || props.marginBottom === 0 ? props.marginBottom : 0}px;
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    height: ${props => props.height}px;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    background-color: ${props =>
      props.color && props.theme.colors[props.color]
      ? props.theme.colors[props.color]
      : props.theme.colors.ctaButtonColor};
    opacity: ${props => (props.opacity || props.opacity === 0 ? props.opacity : 0.08)};
  }
`;

Separator.defaultProps = {
  margin: 32,
  height: 2,
  opacity: 0.08
};

export default Separator;
