import styled from 'styled-components';
import { Button, Text } from 'components/common';
import buttonDefaultStyle from 'components/mixins/normalize';
import { transparentize } from 'utils/color';

export const ButtonElement = styled(Button)`
  ${buttonDefaultStyle};
  flex: 0 0 auto;
  min-width: 200px;
  background-color: ${props => props.theme.colors.mainColor};
  font-size: 14px;
  margin-bottom: 24px;
  &:hover,
  &:focus {
    outline: 1px solid black;
    background-color: ${props => transparentize(props.theme.colors.mainColor, 0.9)};
  }
  &:focus:not(:focus-visible) {
    outline: none;
  }
`;

export const EmailWrapper = styled.span`
  color: ${props => props.theme.colors.mainColor};
`;

export const DescriptionElement = styled(Text)`
  font-weight: normal;
  line-height: 1.71;
  text-align: center;
  color: ${props => props.theme.colors.textColor};
`;

export const DescriptionWrapper = styled(Text)`
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  line-height: 1.71;
  margin-bottom: 40px;
`;

export const TitleElement = styled(Text)`
  font-weight: 300;
  line-height: 1.03;
  text-align: center;
  margin-bottom: 32px;
  color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
`;

export const Figure = styled.div`
  margin-bottom: 54px;
`;

export const ContinueLaterWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;
