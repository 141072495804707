import courseMapper from 'core/mappers/course';
import { guidWithHyphens } from 'utils/object';
import * as questionActions from 'store/questions/actions';
import * as contentsActions from 'store/contents/actions';
import * as sectionActions from 'store/sections/actions';
import {
  initializeXapi,
  subscribeXapi,
  unsubscribeXapi,
  initializeScorm
} from 'store/modules/actions';
import * as navigationActions from 'store/navigation/actions';
import { isXapiInitialized, shouldInitializeXapi } from 'store/modules/selectors';
import {
  getAffectProgressSections,
  getSections,
  isSectionsPassed,
  isSectionsFailed
} from 'store/sections/selectors';
import { getValueFromUrl } from 'utils/window';
import {
  getCourse,
  getAttemptId,
  hasBeenContinued,
  isCourseAccessLimited,
  getCourseAttempt,
  getPreviewQuestionId,
  getTimeSpent,
  isAttemptSubmitted,
  isInProgress,
  getTimerProgressKey
} from 'store/course/selectors';
import eventEmitter, { events } from 'core/events/eventEmitter';
import { getUser, isAnonymous } from 'store/user/selectors';
import {
  getQuestions,
  getQuestion,
  getAffectProgressQuestions,
  getQuestionSection,
  isAllQuestionsFailed,
  getCorrectlyAffectProgressAnsweredQuestionsCount,
  isAllQuestionsTimedOut,
  hasAnyQuestionOpened
} from 'store/questions/selectors';

import sampleSize from 'lodash.samplesize';
import shuffle from 'utils/shuffle';
import progressStorage from 'core/progressStorage';
import { INDEX_PATH } from 'constants/navigation';
import { DEFAULT_QUESTION_POOL_ID_LENGTH } from 'constants/common';
import { getQuestionUrl } from 'utils/navigation';
import { ProgressStatus } from 'constants/progressStatus';
import { TimerStatus, PROGRESS_STORAGE_TIMER_UPDATE_INTERVAL } from 'constants/timer';
import * as courseTimerActions from 'store/timer/actions';
import { TimerState } from 'store/timer/types';
import {
  buildRandomizedOptions,
  buildQuestionWithRandomizedOptions
} from 'core/dataProcessors/questionOptions';
import {
  isShowContentPagesEnabled,
  isReviewMode,
  isPreviewMode,
  isScormMode,
  isOverallMasteryScore,
  getMasteryScoreValue,
  shouldSubmitAllQuestions,
  isQuestionPoolEnabled,
  getQuestionPoolSize,
  isQuestionRandomizeEnabled,
  isAnswersFromPreviousAttemptEnabled,
  getTimerEnabled,
  isAllQuestionsOnOnePage,
  isRandomizeAnswersEnabled
} from '../settings/selectors';

import { ActionTypes } from './types';
import { ThunkResult } from '../types';
import { arrayToObject } from '../../utils/object';

const buildQuestionPool = (questions: any[], questionPoolIds: string[]): any[] => {
  let tempQuestion;
  const sortedQuestions = [];
  sortedQuestions.push(
    ...questionPoolIds.map(questionPoolId => {
      tempQuestion = questions.find(
        question => question.id.slice(0, questionPoolId.length) === questionPoolId
      );
      if (tempQuestion === -1) {
        throw new Error('Question not found');
      }
      tempQuestion.isPoolQuestion = true;
      return tempQuestion;
    })
  );

  sortedQuestions.push(
    ...questions.filter(question => {
      if (!questionPoolIds.includes(question.id.slice(0, questionPoolIds[0].length))) {
        question.isPoolQuestion = false;
        return question;
      }
      return false;
    })
  );

  return sortedQuestions;
};

export const loadPoolQuestions = (
  questions: any[],
  questionPoolIds: string[]
): ThunkResult<Promise<void>> => async dispatch => {
  let questionPool = [...buildQuestionPool(questions, questionPoolIds)];

  questionPool = questionPool.map(question => [question.id, { ...question }]);
  const questionPoolObjects = arrayToObject(questionPool);
  dispatch(questionActions.questionsLoaded(questionPoolObjects));
  dispatch(navigationActions.poolUrlReset(questionPoolObjects));
};

const getShortenedIds = (questions: any[], idLength = DEFAULT_QUESTION_POOL_ID_LENGTH): any => {
  const maxIdLength = questions[0]?.id.length;
  let shortenedIds: string[] = [];

  idLength = idLength > maxIdLength ? maxIdLength : idLength;
  for (let i = idLength; i <= maxIdLength; i++) {
    const uniqueShortenedIds = new Set(shortenedIds);
    if (uniqueShortenedIds.size === questions.length) {
      return shortenedIds;
    }
    shortenedIds = questions.map(question => question.id.slice(0, i));
  }

  return shortenedIds;
};

export const clearPreviewQuestionId = (): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.CLEAR_PREVIEW_QUESTION_ID });
};

export const markCourseAsPassed = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const affectProgressSections = getAffectProgressSections(getState());
  const isCoursePassed =
    getMasteryScoreValue(getState()) === 0 || affectProgressSections.length === 0;

  if (isCoursePassed) {
    affectProgressSections.forEach(section =>
      dispatch(sectionActions.onSectionIsPassed(section.id))
    );

    await dispatch({
      type: ActionTypes.COURSE_IS_PASSED,
      payload: {
        status: ProgressStatus.PASSED
      }
    });
  }
};

export const updateScorePerCourse = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const affectProgressQuestions = getAffectProgressQuestions(getState());
  const masteryScore = getMasteryScoreValue(getState());

  const correctlyAnsweredQuestionsCount = getCorrectlyAffectProgressAnsweredQuestionsCount(
    getState()
  );

  const score =
    affectProgressQuestions.length > 0
      ? Math.floor((correctlyAnsweredQuestionsCount * 100) / affectProgressQuestions.length)
      : 100;

  let status = ProgressStatus.IN_PROGRESS;

  if (isAllQuestionsFailed(getState()) || isAllQuestionsTimedOut(getState())) {
    status = ProgressStatus.FAILED;
  }

  if (score >= masteryScore) {
    status = ProgressStatus.PASSED;
  }

  dispatch({
    type: ActionTypes.COURSE_SCORE_UPDATED,
    payload: {
      score,
      status
    }
  });
};

export const updateScorePerSection = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const sections = getAffectProgressSections(getState());
  const totalScore = sections.reduce((sum, section) => sum + section.score, 0);
  const score = sections.length > 0 ? Math.floor(totalScore / sections.length) : 100;
  let status = ProgressStatus.IN_PROGRESS;

  if (isSectionsFailed(getState())) {
    status = ProgressStatus.FAILED;
  }
  if (isSectionsPassed(getState())) {
    status = ProgressStatus.PASSED;
  }

  dispatch({
    type: ActionTypes.COURSE_SCORE_UPDATED,
    payload: {
      score,
      status
    }
  });
};

export const makeSubmitAtOnce = (): ThunkResult<Promise<void>> => async dispatch => {
  dispatch({
    type: ActionTypes.COURSE_SUBMIT_AT_ONCE
  });
};

export const updateSubmitAtOnceAttemptNumber = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const courseAttempt = getCourseAttempt(getState()) ? getCourseAttempt(getState()) + 1 : 1;
  dispatch({
    type: ActionTypes.COURSE_SUBMIT_AT_ONCE_LIMITED,
    payload: {
      courseAttempt
    }
  });
  await eventEmitter.emit(events.COURSE_SUBMIT_AT_ONCE_LIMITED, courseAttempt);
};

export const courseAttemptedSubmitAtOnce = (): ThunkResult<Promise<void>> => async dispatch => {
  dispatch({
    type: ActionTypes.COURSE_REATTEMPTED_SUBMIT_AT_ONCE
  });
};

const setTimeSpent = (value: number): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch({
    type: ActionTypes.TIME_SPENT_UPDATED,
    payload: {
      timeSpent: {
        accumulatedValueInMilliseconds: value,
        incrementStartedAt: new Date()
      }
    }
  });
};

const getClickedId = (url: any) => {
  const clickedId = url.split('/');
  if (clickedId[clickedId.length - 1] === 'learning-objective') {
    return clickedId.slice(-2, -1)[0];
  }
  return clickedId.slice(-1)[0];
};

export const setTimerProgress = (
  timerProgress: TimerState = { elapsed: 0, status: TimerStatus.NOT_STARTED }
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (timerProgress.status === TimerStatus.RESET) {
    localStorage.setItem(getTimerProgressKey(getState()), '0');
  }

  const localStorageTimerProgress = localStorage.getItem(getTimerProgressKey(getState())) || '0';
  const elapsedTime = timerProgress.elapsed || 0;
  const elapsedTimeDelta = parseInt(localStorageTimerProgress, 10) - elapsedTime;

  if (
    elapsedTime > 0 ||
    (elapsedTimeDelta > 0 && elapsedTimeDelta < PROGRESS_STORAGE_TIMER_UPDATE_INTERVAL) ||
    hasAnyQuestionOpened(getState())
  ) {
    dispatch(
      courseTimerActions.setTimerStatus(
        timerProgress.status === TimerStatus.NOT_STARTED
          ? TimerStatus.STARTED
          : timerProgress.status
      )
    );
    dispatch(
      courseTimerActions.setTimerElapsed(
        Math.max(timerProgress.elapsed, parseInt(localStorageTimerProgress, 10))
      )
    );
    localStorage.setItem(
      getTimerProgressKey(getState()),
      `${Math.max(timerProgress.elapsed, parseInt(localStorageTimerProgress, 10))}`
    );
  } else if (timerProgress.status === TimerStatus.STARTED) {
    dispatch(courseTimerActions.setTimerStatus(timerProgress.status));
    dispatch(courseTimerActions.setTimerElapsed(timerProgress.elapsed));
  }
};

const emitCourseProgressed = async (state: any) => {
  const course = getCourse(state);
  let courseStatus = course.status;
  if (isAttemptSubmitted(state) && isInProgress(state) && shouldSubmitAllQuestions(state)) {
    courseStatus = ProgressStatus.FAILED;
  }

  let eventToCall = events.COURSE_PROGRESSED;
  if (shouldSubmitAllQuestions(state) && !isAttemptSubmitted(state)) {
    eventToCall = events.SUBMIT_ONCE_COURSE_PROGRESSED;
  }

  return eventEmitter.emit(eventToCall, {
    ...state,
    course,
    timeSpent: getTimeSpent(state),
    xapiStatus: courseStatus
  });
};

export const updateScore = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const masteryScorePerCourse = isOverallMasteryScore(getState());

  if (masteryScorePerCourse) {
    dispatch(updateScorePerCourse());
  } else {
    const isQuestionPoolAllowed = isQuestionPoolEnabled(getState());
    if (!isQuestionPoolAllowed) {
      dispatch(updateScorePerSection());
    }
  }
};

export const updateProgress = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch(updateScore());
  emitCourseProgressed(getState());
};

export const load = (data: any): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({ type: ActionTypes.COURSE_DATA_LOADING_STARTED });
  try {
    const showContentPages = isShowContentPagesEnabled(getState());
    const { course, sections, questions, contents } = courseMapper.map(data, showContentPages);

    if (getValueFromUrl('questionId')) {
      course.previewQuestionId = getValueFromUrl('questionId');
    }
    dispatch(questionActions.questionsLoaded(questions));
    dispatch(sectionActions.sectionsLoaded(sections));
    dispatch(contentsActions.contentsLoaded(contents));
    await dispatch({ type: ActionTypes.COURSE_DATA_LOADED, payload: course });
    eventEmitter.emit(events.APP_INITIALIZED, {
      state: getState(),
      isScormMode: isScormMode(getState())
    });
  } catch (e) {
    dispatch({ type: ActionTypes.COURSE_DATA_LOADING_FAILED, reason: e });
    throw e;
  }
};

export const processPoolQuestions = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const inProgressQuestionPool = progressStorage.getQuestionPool();
  const questions = getQuestions(getState());
  const questionPoolSize = getQuestionPoolSize(getState());
  const nonSurveyQuestions = questions.filter(question => !question.isSurvey);
  if (inProgressQuestionPool && inProgressQuestionPool.length > 0) {
    await dispatch(loadPoolQuestions(questions, inProgressQuestionPool));
  } else {
    const randomQuestionsSubset = sampleSize(nonSurveyQuestions, questionPoolSize);
    // shortening ids to save on the storage space(4Kb) for suspend_data in scorm enabled lms
    const questionPoolIds = getShortenedIds(randomQuestionsSubset);
    await dispatch(loadPoolQuestions(questions, questionPoolIds));
    await eventEmitter.emit(events.COURSE_QUESTION_POOL_SELECTED, {
      questionPoolIds,
      state: getState()
    });
  }
};

export const loadAnswers = (showPrevAnswer: boolean): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const questions = getQuestions(getState());
  const answers = progressStorage.getAnswers(questions);
  const sectionIds = new Set();
  for (const { id, response, attempt } of answers) {
    const question = getQuestion(getState(), id);
    sectionIds.add(question.sectionId);
    dispatch(questionActions.restoreProgress(id, response, attempt, showPrevAnswer));
  }

  if (getTimerEnabled(getState())) {
    await dispatch(setTimerProgress(progressStorage.timer));
  }

  if (!isQuestionPoolEnabled(getState())) {
    for (const sectionId of sectionIds) {
      dispatch(sectionActions.updateSection(sectionId as string));
    }
  }
  await dispatch(updateScore());
};

export const processRandomizeAnswers = (): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  const storedRandomizedAnswersList = progressStorage.getRandomizeAnswers();
  const questions = getQuestions(getState());
  if (storedRandomizedAnswersList?.length > 0) {
    dispatch(
      questionActions.questionsLoaded(
        buildQuestionWithRandomizedOptions(getQuestions(getState()), storedRandomizedAnswersList)
      )
    );
  } else {
    const randomizedOptionsList = buildRandomizedOptions(questions);
    dispatch(
      questionActions.questionsLoaded(
        buildQuestionWithRandomizedOptions(getQuestions(getState()), randomizedOptionsList)
      )
    );
    await eventEmitter.emit(events.COURSE_RANDOMIZED_OPTIONS_SAVED, {
      randomizedOptionsList,
      state: getState()
    });
  }
  dispatch({
    type: ActionTypes.COURSE_RANDOMIZED_OPTIONS_LOADED
  });
};

export const setCourseAttempt = (attempt: number): ThunkResult<Promise<void>> => async dispatch => {
  dispatch({
    type: ActionTypes.COURSE_SUBMIT_AT_ONCE_LIMITED,
    payload: {
      courseAttempt: attempt
    }
  });
};

export const setCourseAttemptOnTimeOut = (): ThunkResult<Promise<void>> => async dispatch => {
  try {
    await progressStorage.restoreProgress();
    if (progressStorage?.courseAttemptNumber) {
      dispatch(setCourseAttempt(progressStorage.courseAttemptNumber));
    }
  } catch (e) {
    console.error(e);
  }
};

export const restoreProgress = (): ThunkResult<Promise<boolean>> => async (
  dispatch,
  getState
): Promise<boolean> => {
  try {
    const isProgressRestored = await progressStorage.restoreProgress();
    dispatch({ type: ActionTypes.COURSE_PROGRESS_RESTORE_FETCHED });

    const isQuestionPoolAllowed = isQuestionPoolEnabled(getState());

    if (isQuestionPoolAllowed) {
      await dispatch(processPoolQuestions());
    }

    const randomizeAnswersEnabled = isRandomizeAnswersEnabled(getState());

    if (randomizeAnswersEnabled) {
      await dispatch(processRandomizeAnswers());
    }

    if (!isProgressRestored) {
      return false;
    }

    const isSubmitAllAtOnce = shouldSubmitAllQuestions(getState());
    const isCourseOnOnePage = isAllQuestionsOnOnePage(getState());
    await dispatch(loadAnswers(false));

    if (isSubmitAllAtOnce) {
      const { allQuestionsSubmitted } = progressStorage;
      const { courseAttemptNumber } = progressStorage;
      await dispatch(setCourseAttempt(courseAttemptNumber));

      if (allQuestionsSubmitted) {
        await dispatch(makeSubmitAtOnce());
      }
    }

    if (progressStorage.timeSpent) {
      await dispatch(setTimeSpent(progressStorage.timeSpent));
    }

    if (isCourseOnOnePage) {
      await dispatch(
        navigationActions.updateElementIdInViewPort(getClickedId(progressStorage.url))
      );
    }

    dispatch({
      type: ActionTypes.COURSE_PROGRESS_RESTORED,
      payload: { attemptId: progressStorage.attemptId }
    });
    return true;
  } catch (e) {
    dispatch({ type: ActionTypes.COURSE_PROGRESS_RESTORE_FAILED, reason: e });
    return false;
  }
};

export const onCourseLaunched = (attemptId?: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.COURSE_LAUNCHED,
    payload: { attemptId }
  });
  eventEmitter.emit(events.COURSE_LAUNCHED, attemptId);
};

export const onCourseStarted = (): ThunkResult<Promise<void>> => async dispatch => {
  await dispatch({
    type: ActionTypes.COURSE_STARTED
  });

  await dispatch(setTimeSpent(0));
  await eventEmitter.emit(events.COURSE_STARTED);
};

const loadScormMode = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (isScormMode(getState())) {
    await dispatch(initializeScorm());
    await progressStorage.checkScormNonAsciiSupport();
  } else {
    if (isAnonymous(getState())) {
      await eventEmitter.emit(events.APP_INITIALIZED, {
        state: getState(),
        isScormMode: isScormMode(getState())
      });
    }
    await eventEmitter.emit(events.USER_AUTHENTICATED, getUser(getState()));
  }
};

const loadPreviewMode = (): ThunkResult<Promise<string>> => async (dispatch, getState) => {
  const isQuestionPoolAllowed = isQuestionPoolEnabled(getState());
  if (isQuestionPoolAllowed) {
    await dispatch(processPoolQuestions());
  }
  dispatch(onCourseLaunched());
  dispatch(onCourseStarted());
  const previewQuestionId = getPreviewQuestionId(getState());
  const randomizeAnswersEnabled = isRandomizeAnswersEnabled(getState());
  if (randomizeAnswersEnabled) {
    await dispatch(processRandomizeAnswers());
  }
  if (previewQuestionId) {
    const previewSectionId = getQuestionSection(getState(), previewQuestionId);
    const questionUrl = getQuestionUrl(previewSectionId, previewQuestionId);
    dispatch(clearPreviewQuestionId());
    return questionUrl;
  }
  return INDEX_PATH;
};

const loadPreviouslyAnsweredQuestions = (
  restoreStatus: boolean
): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  if (
    !restoreStatus &&
    isAnswersFromPreviousAttemptEnabled(getState()) &&
    shouldSubmitAllQuestions(getState())
  ) {
    const isAnswersInProgress = await progressStorage.restoreAnswers();
    if (isAnswersInProgress) {
      await dispatch(loadAnswers(true));
    }
  }
};

export const launch = (): ThunkResult<Promise<string>> => async (dispatch, getState) => {
  if (isCourseAccessLimited(getState())) {
    dispatch(onCourseLaunched());
    return INDEX_PATH;
  }

  await dispatch(markCourseAsPassed());

  if (isPreviewMode(getState()) || isReviewMode(getState())) {
    return dispatch(loadPreviewMode());
  }

  await dispatch(loadScormMode());

  const restoreStatus = await dispatch(restoreProgress());

  let attemptId = getAttemptId(getState());
  if (!attemptId) {
    attemptId = guidWithHyphens();
  }

  // TODO: events
  dispatch(onCourseLaunched(attemptId));

  if (!restoreStatus) {
    await eventEmitter.emit(events.COURSE_ATTEMPT_STARTED, getState());
  }

  dispatch(loadPreviouslyAnsweredQuestions(restoreStatus));

  const state = getState();
  if (shouldInitializeXapi(state)) {
    await dispatch(initializeXapi());
    await dispatch(subscribeXapi());
  }

  if (hasBeenContinued(getState())) {
    return progressStorage.url || INDEX_PATH;
  }

  await dispatch(onCourseStarted());

  if (
    getMasteryScoreValue(getState()) === 0 ||
    getAffectProgressSections(getState()).length === 0
  ) {
    await emitCourseProgressed(getState());
  }

  return INDEX_PATH;
};

export const cleanup = (isLogout: boolean): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  dispatch({
    type: ActionTypes.COURSE_CLEANUP
  });

  dispatch(courseTimerActions.resetTimer());
  localStorage.setItem(getTimerProgressKey(getState()), '0');

  const sections = getSections(getState());
  sections.forEach(section => {
    dispatch(sectionActions.cleanup(section.id));
  });
  const questions = getQuestions(getState());

  if (
    shouldSubmitAllQuestions(getState()) &&
    isAnswersFromPreviousAttemptEnabled(getState()) &&
    !isLogout
  ) {
    questions.forEach(question => {
      dispatch(questionActions.cleanupKeepAnswers(question.id));
    });
  } else {
    questions.forEach(question => {
      dispatch(questionActions.cleanup(question.id));
    });
  }

  if (isXapiInitialized(getState())) {
    await dispatch(unsubscribeXapi());
  }
};

export const finished = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.COURSE_FINISHED
  });
  eventEmitter.emit(events.COURSE_FINISHED, getCourse(getState()));
};

export const finalized = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  dispatch({
    type: ActionTypes.COURSE_FINALIZED
  });
  eventEmitter.emit(events.COURSE_FINALIZED, getCourse(getState()));
};

export const startNewAttempt = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  const currentAttemptCount = getCourseAttempt(getState());
  await dispatch(cleanup(false));
  await progressStorage.removeProgress();
  if (shouldSubmitAllQuestions(getState())) {
    await dispatch(courseAttemptedSubmitAtOnce());
    await dispatch(setCourseAttempt(currentAttemptCount));
  }

  if (isQuestionRandomizeEnabled(getState())) {
    let inProgressQuestionPool = progressStorage.getQuestionPool();
    if (inProgressQuestionPool && inProgressQuestionPool.length > 0) {
      inProgressQuestionPool = shuffle(inProgressQuestionPool);
      await eventEmitter.emit(events.COURSE_QUESTION_POOL_SELECTED, {
        questionPoolIds: inProgressQuestionPool,
        state: getState()
      });
    }
  }
  await dispatch(launch());
};

export const certificateDownloaded = (
  isCertificateDownloaded: boolean
): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.CERTIFICATE_DOWNLOADED,
    payload: { isCertificateDownloaded }
  });
};

export const evaluate = (data: { score: number; response: any }) => () => {
  const { score, response } = data;
  eventEmitter.emit(events.COURSE_EVALUATED, {
    score,
    response
  });
};
