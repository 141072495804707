import styled from 'styled-components';
import { transparentize } from 'utils/color';

type ProgressContainerProps = {
  height: number;
};
export const ProgressContainer = styled.div<ProgressContainerProps>`
  position: relative;
  width: 100%;
  height: ${props => (props.height ? props.height : 2)}px;
`;

type ProgressProps = {
  progress: number;
  progressColor: string;
  opacity: any;
};
export const Progress = styled.div<ProgressProps>`
  height: 100%;
  width: ${props => (props.progress ? props.progress : 0)}%;
  background-color: ${props =>
    transparentize(props.theme.colors[props.progressColor], props.opacity)};
  border-radius: inherit;
  transition: width 0.2s;
`;
