import { getQuestion } from 'store/questions/selectors';
import { objectToArray } from 'utils/object';
import { RootAppState } from '../../types';

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  const questionContent = objectToArray(state.contents).find(
    content => content.id === question.questionContent[0]
  );
  const userAnswers: { [key: string]: any } = {};
  const responseAnswer = question.response !== null ? question.response : [];
  question.answerGroups.forEach((answer: any) => {
    userAnswers[answer.id] =
      responseAnswer && responseAnswer.data ? responseAnswer.data[answer.id] : '';
  });
  return {
    id: question.id,
    questionContent,
    userAnswers
  };
};

export const getScore = (state: RootAppState, question: any) => {
  const responseAnswer = question.response !== null ? question.response : [];
  const newAnswer = objectToArray(question.answerGroups).map(group =>
    responseAnswer.data
      ? responseAnswer.data.hasOwnProperty(group.id)
        ? group.answers.find((answer: any) =>
            answer.matchCase === true
              ? answer.text === responseAnswer.data[group.id] && answer.isCorrect === true
              : answer.text.toLowerCase() === responseAnswer.data[group.id].toLowerCase() &&
                answer.isCorrect === true
          )
        : null
      : 0
  );

  if (responseAnswer && responseAnswer.data) {
    if (newAnswer && !newAnswer.includes(undefined)) {
      return 100;
    }
    return 0;
  }
  return 0;
};
