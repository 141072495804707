import * as React from 'react';
import Input from './TextInput.styled';

type TextInputProps = {
  name: string,
  maxLength: number,
  value: string,
  tabIndex: number,
  alt: string,
  placeholder: string,
  onChange?(e?: any): void;
  onBlur?(e?: any): void;
  disabled: boolean;
};

type TextInputState = {
  value: any;
}
export class TextInput extends React.Component<TextInputProps, TextInputState> {

  constructor(props: TextInputProps) {
    super(props);
    this.state = {
      value: this.props.value
    };
  }

  static defaultProps = {
    alt: 'Input value please',
    placeholder: 'Type your answer',
    maxLength: 255
  };

  onChangeHandler = (event: any) => {
    this.setState({ value: event.target.value });
  };

  componentDidUpdate(prevProps: TextInputProps) {
    if (this.props.value !== prevProps.value) {
      this.setState({ value: this.props.value });
    }
  }

  render() {
    const { maxLength, alt, placeholder, tabIndex, onBlur, name, disabled } = this.props;
    const { value } = this.state;

    return (
      <Input
        key={name}
        type="text"
        value={value}
        tabIndex={tabIndex}
        placeholder={placeholder}
        aria-label={placeholder}
        alt={alt}
        name={name}
        maxLength={maxLength}
        disabled={disabled}
        onChange={this.onChangeHandler}
        onBlur={onBlur}
        title={value || placeholder}
      />
    );
  }
}

export default TextInput;
