import { css } from 'styled-components';
import { transparentize } from 'utils/color';

export function getCustomScrollbars(scrollColor?: any) {
  return css`
    overflow-y: auto;
    &::-webkit-scrollbar {
      width: 8px;
      background-color: transparent;
    }
    &::-webkit-scrollbar-thumb {
      margin-right: 8px;
      background-color: ${props =>
        transparentize(`${scrollColor || props.theme.colors.textColor}`, 0.2)};
      border-radius: 6px;
    }

    &:hover {
      &::-webkit-scrollbar-thumb {
        background-color: ${props =>
          transparentize(`${scrollColor || props.theme.colors.textColor}`, 0.4)};
      }
    }
  `;
}
export default getCustomScrollbars;
