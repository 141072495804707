import styled from 'styled-components';
import { transparentize } from 'utils/color';

export const ListCounter = styled.ul`
  line-height: 42px;
  padding-right: 20px;
  color: ${props => transparentize(props.theme.colors.textColor, 0.2)};
`;

type ListCounterItemProps = {
  lineHeight: number;
}
export const ListCounterItem = styled.li<ListCounterItemProps>`
  margin-bottom: 8px;
  transition: 0.3s;

  line-height: ${props => props.lineHeight}px !important;
`;
