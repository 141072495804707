import { NO_SELECTED_ID } from 'constants/common';
import ResponseMapperBase from './ResponseMapperBase';

export default class DragAndDropTextResponseMapper extends ResponseMapperBase {
  mapResponse(question: any) {
    if (!this.hasQuestionResponse(question)) {
      return {};
    }

    const result: { [key: string]: any } = {};
    question.dropspots.forEach((answer: any) => {
      const responseItem = question.response.find((e: any) => e.id === answer.id);
      if (responseItem && responseItem.items.length) {
        const item = responseItem.items[0];
        const itemIndex = question.dropspots.findIndex((e: any) => e.id === item.id);
        result[itemIndex] = {
          x: answer.x,
          y: answer.y
        };
      }
    });

    return result;
  }

  /**
   * @param {object} userAnswer
   * @param {object} question
   */
  unMap(userAnswer: any, { dropspots }: any) {
    const notSelectedItem: { [key: string]: any } = {
      id: NO_SELECTED_ID,
      items: [],
      isDropDisabled: false
    };

    if (this.isAnsweredCorrectly(userAnswer)) {
      const result = dropspots.map((dropspot: any) => ({
        id: dropspot.id,
        items: [dropspot],
        position: {
          x: dropspot.x,
          y: dropspot.y
        },
        isDropDisabled: true
      }));

      result.unshift(notSelectedItem);
      return result;
    }

    const userAnswerIndexes = Object.keys(userAnswer);
    dropspots.forEach((dropspot: any, index: any) => {
      if (!userAnswerIndexes.find(answerIndex => answerIndex === index.toString())) {
        notSelectedItem.items.push(dropspot);
      }
    });

    let answerValues: any[] = [];
    Object.keys(userAnswer).forEach((answerIndex: any) => {
      answerValues[answerIndex] = userAnswer[answerIndex];
    });

    const result = dropspots.map((dropspot: any) => {
      const selectedOptionIndex = answerValues.findIndex(
        answerOption =>
          answerOption && answerOption.x === dropspot.x && answerOption.y === dropspot.y
      );
      const isAnswered = selectedOptionIndex !== -1;
      return {
        id: dropspot.id,
        items: isAnswered ? [dropspots[selectedOptionIndex]] : [],
        position: {
          x: dropspot.x,
          y: dropspot.y
        },
        isDropDisabled: isAnswered
      };
    });

    result.unshift(notSelectedItem);
    return result;
  }
}
