import { useEffect, useRef } from 'react';
import { TimerStatus } from 'constants/timer';

type Delay = number;
type TimerHandler = () => void;

const noop = () => {
  // do nothing
};

const useTimeout = (
  callback: TimerHandler,
  delay: Delay,
  timerStatus: TimerStatus,
  updatedAt: number,
  isPageVisible: boolean
) => {
  const savedCallbackRef = useRef<TimerHandler>();

  useEffect(() => {
    savedCallbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    const handler = () => {
      if (savedCallbackRef.current) {
        return savedCallbackRef.current();
      }
      return noop();
    };
    const intervalId = setTimeout(handler, delay);
    return () => clearInterval(intervalId);
  }, [delay, timerStatus, updatedAt]);
};

export default useTimeout;
