import * as questionTypes from 'constants/questionTypes';

import DragAndDropTextResponseMapper from './DragAndDropTextResponseMapper';
import FillInTheBlankResponseMapper from './FillInTheBlankResponseMapper';
import HotspotResponseMapper from './HotspotResponseMapper';
import InformationContentResponseMapper from './InformationContentResponseMapper';
import MultipleChoiceResponseMapper from './MultipleChoiceResponseMapper';
import OpenQuestionResponseMapper from './OpenQuestionResponseMapper';
import RankingTextResponseMapper from './RankingTextResponseMapper';
import ScenarioResponseMapper from './ScenarioResponseMapper';
import SingleChoiceResponseMapper from './SingleChoiceResponseMapper';
import SingleChoiceImageResponseMapper from './SingleChoiceImageResponseMapper';
import StatementChoiceResponseMapper from './StatementChoiceResponseMapper';
import TextMatchingResponseMapper from './TextMatchingResponseMapper';

const questions: { [key: string]: any } = {
  [questionTypes.DRAG_AND_DROP_TEXT]: new DragAndDropTextResponseMapper(),
  [questionTypes.FILL_IN_THE_BLANK]: new FillInTheBlankResponseMapper(),
  [questionTypes.HOTSPOT]: new HotspotResponseMapper(),
  [questionTypes.INFORMATION_CONTENT]: new InformationContentResponseMapper(),
  [questionTypes.MULTIPLE_CHOICE]: new MultipleChoiceResponseMapper(),
  [questionTypes.OPEN_QUESTION]: new OpenQuestionResponseMapper(),
  [questionTypes.RANKING_TEXT]: new RankingTextResponseMapper(),
  [questionTypes.SCENARIO]: new ScenarioResponseMapper(),
  [questionTypes.SINGLE_CHOICE]: new SingleChoiceResponseMapper(),
  [questionTypes.SINGLE_CHOICE_IMAGE]: new SingleChoiceImageResponseMapper(),
  [questionTypes.STATEMENT_CHOICE]: new StatementChoiceResponseMapper(),
  [questionTypes.TEXT_MATCHING]: new TextMatchingResponseMapper()
};

export default questions;
