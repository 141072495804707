import React, { useState } from 'react';
import { localize } from 'core/localization';
import { Icon, ProgressBar, Tooltip } from 'components/common';
import { ProgressWrapper, ProgressBarMark, StatusIcon } from './ProgressResult.styled';

type ProgressResultProps = {
  height?: number;
  progress: number;
  className?: string;
  passedMark: number;
  passedMarkStyles?: { [key: string]: any };
  forwardedRef?: any;
  progressColor?: string;
  opacity?: any;
};
const ProgressResult = ({
  height = 2,
  progress = 0,
  className = '',
  passedMark,
  passedMarkStyles,
  progressColor,
  opacity
}: ProgressResultProps) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const toggleShowTooltip = () => {
    setShowTooltip(!showTooltip);
  };

  return (
    <ProgressWrapper onMouseEnter={toggleShowTooltip} onMouseLeave={toggleShowTooltip}>
      <ProgressBar
        className={className}
        height={height}
        progress={progress}
        progressColor={progressColor}
        opacity={opacity}
      >
        <ProgressBarMark height={8} top={-2} passedMark={passedMark} styles={passedMarkStyles}>
          <Tooltip
            html={localize('[reach x to pass the course]', {
              mustPercentSuccess: passedMark
            })}
            arrow={true}
            open={showTooltip}
            distance={15}
            tooltipTheme={'light'}
            size={'small'}
          >
            <StatusIcon progress={progress} passedMark={passedMark}>
              <Icon name="ok" size={6} />
            </StatusIcon>
          </Tooltip>
        </ProgressBarMark>
      </ProgressBar>
    </ProgressWrapper>
  );
};

export default ProgressResult;
