import * as React from 'react';

type Props = {
  location: { [key: string]: any }
}

function withScrollOnRedirect<T>(WrappedComponent: React.ComponentType<T>): any {
  return class ScrollToTop extends React.Component<Props & T, {}> {
    componentDidUpdate(prevProps: Props & T) {
      if (this.props.location !== prevProps.location) {
        window.scrollTo(0, 0);
      }
    }

    render() {
      return <WrappedComponent {...this.props} />;
    }

    static propTypes: Props;
  };
}

export default withScrollOnRedirect;
