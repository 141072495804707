import initialState from 'store/initialState';
import { ActionTypes, TOCActionTypes } from './types';

export default function treeOfContentReducer(
  state = initialState.treeOfContent,
  action: TOCActionTypes
) {
  switch (action.type) {
    case ActionTypes.TOC_TOGGLE_VISIBILITY:
      return { ...state, ...{ isExpanded: !state.isExpanded } };
    case ActionTypes.COURSE_ENABLE_SUBMIT_BUTTON:
      return {
        ...state,
        ...{
          isSubmitButtonVisible: action.payload.isSubmitButtonVisible
        }
      };
    default:
      return state;
  }
}
