import { getQuestion } from 'store/questions/selectors';
import { isRandomizeAnswersEnabled } from 'store/settings/selectors';
import { RootAppState } from 'store/types';

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  const randomizeAnswersEnabled = isRandomizeAnswersEnabled(state);
  const { answers: options, randomizedOptions = [] } = question;
  const answers = randomizeAnswersEnabled ? randomizedOptions : options;
  return answers.map((answer: any) => ({
    id: answer.id,
    key: answer.key,
    value: answer.value,
    selectedKey: ''
  }));
};

export const getUserResponse = (state: RootAppState, questionId: string) => getQuestion(state, questionId).response;

export const getScore = (state: RootAppState, question: any) => {
  if (!question.response.length) return 0;
  for (let i = 0; i < question.response.length; i++) {
    const item = question.response[i];
    if (item.value !== item.shownSelectedValue) {
      return 0;
    }
  }

  return 100;
};
