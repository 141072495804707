import { normalizeColorName, toCamelCase } from 'utils/string';

export default {
  map(options: any) {
    const { settings, publishSettings, manifest, translations, publishMode } = options;
    return {
      template: mapTemplateSettings(settings),
      publish: publishSettings,
      manifest,
      translations,
      publishMode
    };
  }
};

function mapTemplateSettings(settings: any) {
  return {
    fonts: mapFonts(settings.fonts),
    branding: {
      mainLogo: settings.branding.logo.url,
      alternativeLogo: settings.branding.alternativeLogo.url,
      allowToShowMainLogo: settings.branding.logo.allowToShowLogo,
      colors: mapColors(settings.branding.colors)
    },
    background: settings.branding.background,
    languages: settings.languages,
    saveProgressCrossDevice: settings.saveProgressCrossDevice,
    loginViaSocialMedia: settings.loginViaSocialMedia,
    conductLearnerSatisfactionSurvey: settings.conductLearnerSatisfactionSurvey,
    allowUsersToDownloadCertificates: settings.allowUsersToDownloadCertificates,
    showContentPages: settings.showContentPages,
    showLearningHints: settings.showLearningHints,
    resultsTracking: settings.resultsTracking,
    numberOfAttempts: settings.numberOfAttempts,
    allowUserToSkipTrackingAndTracing: settings.allowUserToSkipTrackingAndTracing,
    allowScoringOfContentPages: settings.allowScoringOfContentPages,
    answersFromPreviousAttempt: settings.answersFromPreviousAttempt,
    masteryScore: settings.masteryScore,
    showAllQuestionAndContentItems: settings.showAllQuestionAndContentItems,
    submitAnswers: settings.submitAnswers,
    xApi: settings.xApi,
    questionPool: settings.questionPool,
    randomizeQuestionOrder: settings.randomizeQuestionOrder,
    timer: settings.timer,
    answerOptionsOrder: settings.answerOptionsOrder
  };
}

function mapFonts(fonts: any) {
  return fonts.reduce((acc: any, item: any) => {
    acc[toCamelCase(item.key)] = item;
    return acc;
  }, {});
}

function mapColors(colors: any) {
  return colors.reduce((acc: any, item: any) => {
    acc[normalizeColorName(item.key)] = item.value;
    return acc;
  }, {});
}
