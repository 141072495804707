import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import * as courseActions from 'store/course/actions';
import { isScormMode } from 'store/settings/selectors';
import { CssAnimation } from 'components/common';
import { closeWindow } from 'utils/window';
import { POPUP_ID_CLOSE_COURSE } from 'constants/popups';
import EvaluateCourse from './evaluateCourse';
import FeedbackStep from './feedbackStep';
import ThankForFeedback from './thankForFeedback';
import CloseCoursePopup from '../closeCourse';
import { RootAppState } from 'store/types';

const EVALUATE_COURSE_BLOCK = 'EVALUATE_COURSE_BLOCK';
const FEEDBACK_STEP_BLOCK = 'FEEDBACK_STEP_BLOCK';
const THANK_FOR_FEEDBACK_BLOCK = 'THANK_FOR_FEEDBACK_BLOCK';

interface NPSSteps {
  EVALUATE_COURSE_BLOCK: string;
  FEEDBACK_STEP_BLOCK: string;
  THANK_FOR_FEEDBACK_BLOCK: string;
}

type NpsPopupProps = {
  courseActions: { [key: string]: any };
  actions: { [key: string]: any };
  closePopup(): void;
  popupSettings: { [key: string]: any };
  scormMode: boolean;
};

type NpsPopupState = {
  npsScore: number;
  textFeedback: string;
  currentStep: keyof NPSSteps;
}

export class NpsPopup extends React.PureComponent<NpsPopupProps, NpsPopupState> {
  steps: Array<keyof NPSSteps>;
  constructor(props: NpsPopupProps) {
    super(props);
    this.state = {
      npsScore: 0,
      textFeedback: '',
      currentStep: EVALUATE_COURSE_BLOCK
    };
    this.steps = [EVALUATE_COURSE_BLOCK, FEEDBACK_STEP_BLOCK, THANK_FOR_FEEDBACK_BLOCK];
  }

  updateNpsScore = (score: number) => {
    this.setState({ npsScore: score * 10 });
    this.goToNextStep();
  };

  updateTextFeedback = (e: { [key: string]: any }) => {
    this.setState({ textFeedback: e.target.value });
  };

  submitNpsFeedback = async () => {
    await this.props.courseActions.evaluate({
      score: this.state.npsScore,
      response: this.state.textFeedback
    });

    this.goToNextStep();
  };

  goToNextStep() {
    let currentNameBlock = this.steps.indexOf(this.state.currentStep);
    if (currentNameBlock < this.steps.length) {
      ++currentNameBlock;
      this.setState({ currentStep: this.steps[currentNameBlock] });
    }
  }

  backToResult = () => {
    this.props.closePopup();
  };

  openClosePopup() {
    this.props.actions.openPopup({
      popupId: POPUP_ID_CLOSE_COURSE,
      popupAriaLabelKey: '[aria label close course popup]',
      component: CloseCoursePopup,
      disablePopupClosing: true,
      closePopupByTap: false
    });
  }

  closeCourse = () => {
    this.props.closePopup();
    if (!closeWindow()) {
      this.openClosePopup();
    } else {
      closeWindow();
    }
  };

  closeCourseInScormMode = () => {
    this.props.closePopup();
    this.openClosePopup();
    this.props.courseActions.finalized();
  };

  render() {
    const { fromDownloadCertificateButton } = this.props.popupSettings;
    const { scormMode } = this.props;
    return (
      <React.Fragment>
        {this.state.currentStep === EVALUATE_COURSE_BLOCK && (
          <CssAnimation type={CssAnimation.AnimationType.fadeInDown}>
            <EvaluateCourse onClick={this.updateNpsScore} />
          </CssAnimation>
        )}
        {this.state.currentStep === FEEDBACK_STEP_BLOCK && (
          <CssAnimation type={CssAnimation.AnimationType.fadeInDown}>
            <FeedbackStep
              npsScore={this.state.npsScore}
              onChange={this.updateTextFeedback}
              onSubmit={this.submitNpsFeedback}
            />
          </CssAnimation>
        )}
        {this.state.currentStep === THANK_FOR_FEEDBACK_BLOCK && (
          <CssAnimation type={CssAnimation.AnimationType.fadeInDown}>
            <ThankForFeedback
              fromDownloadCertificateButton={fromDownloadCertificateButton}
              backTo={this.backToResult}
              closeCourse={scormMode ? this.closeCourseInScormMode : this.closeCourse}
              isScormMode={scormMode}
            />
          </CssAnimation>
        )}
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    scormMode: isScormMode(state)
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    courseActions: bindActionCreators(courseActions, dispatch)
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NpsPopup);
