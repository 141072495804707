import * as React from 'react';
import { withTheme } from 'styled-components';
import { TOOLTIP } from 'constants/components';
import { Tooltip } from 'components/common';
import {
  ContainerMessage,
  MessageContainer,
  IconElement,
  PopoverWrapper,
  PopoverButton
} from './StatusTooltip.styled';

export interface LayoutType {
  [TOOLTIP.CORRECT]: string;
  [TOOLTIP.INCORRECT]: string;
}
type StatusTooltipProps = {
  popoverPosition: any;
  message: string | React.ReactElement;
  popoverButton: React.ReactElement;
  layout: keyof LayoutType;
  showTooltip: boolean;
  theme?: any;
  interactive?: boolean
};

export class StatusTooltip extends  React.Component<any, {}> {
  refButton: any;
  constructor(props: StatusTooltipProps) {
    super(props);
    this.refButton = React.createRef();
  }

  render() {
    const { popoverPosition, popoverButton, message, layout, showTooltip, onRequestClose = () => {} } = this.props;
    const templateTheme = this.props.theme;
    return (
      <PopoverWrapper>
        <Tooltip
          trigger={'manual'}
          open={showTooltip}
          onRequestClose={ () => onRequestClose && onRequestClose() }
          tooltipTheme={'light'}
          position={popoverPosition}
          interactive={this.props.interactive === false ? false : true}
          distance={20}
          classNames='status-theme'
          html={
            <ContainerMessage layout={layout} theme={templateTheme}>
              <IconElement
                theme={templateTheme}
                name={layout === TOOLTIP.CORRECT ? 'check-mark-2' : ''}
                fontSize={16}
                size={11}
                text={layout === TOOLTIP.INCORRECT ? '!' : ''}
                color={
                  (layout === TOOLTIP.INCORRECT && 'incorrectColor') ||
                  (layout === TOOLTIP.CORRECT && 'correctColor')
                }
              />
              <MessageContainer theme={templateTheme} appearance={'p'} font={'secondaryFont'}>
                {message}
              </MessageContainer>
            </ContainerMessage>
          }
        >
          <PopoverButton
            role="presentation"
            ref={this.refButton}
          >
            {popoverButton}
          </PopoverButton>
        </Tooltip>
      </PopoverWrapper>
    );
  }
}

export default withTheme(StatusTooltip);
