import { COURSE_FINISHED } from 'core/events/events';
import BaseEventHandler from 'core/events/eventHandler';
import lti from './lti';

export default class EventHandler extends BaseEventHandler {
  handlers: any[];

  constructor() {
    super();
    this.handlers = [{ event: COURSE_FINISHED, instance: this.submitResultToLti.bind(this) }];
  }

  initialize(callbackUrl: any) {
    lti.initialize(callbackUrl);
  }

  submitResultToLti({ score }: { [key: string]: any }) {
    lti.submitResultToLti(score);
  }
}
