import WebFont from 'webfontloader';
import resourceLoader from 'core/http/resourceLoader';
import { addQueryStringParameter } from 'utils/window';
import { FONT_WEIGHTS, SECONDARY_FONT_KEY } from 'constants/common';

/* eslint-disable import/prefer-default-export */
export async function loadFonts(fonts: any, customFontPlace: any) {
  const mappedFonts = mapFonts(fonts, customFontPlace);
  const webFontConfig = prepareWebFontConfig(mappedFonts);
  if (Object.keys(webFontConfig).length !== 0 && webFontConfig.constructor === Object) {
    await makeLoadFontRequest(webFontConfig);
  }
}

function mapFonts(fonts: any, customFontPlace: any) {
  const mappedFonts: { [key: string]: any } = {};
  const presetFonts = fonts.map(mapPresetFont);
  const templateFonts = getTemplateFont(fonts);
  const allTemplateFonts = presetFonts.concat(templateFonts);
  allTemplateFonts.forEach((font: any) => {
    if (font.place === 'none') {
      return;
    }
    const place = mappedFonts[font.place] || {};

    const samefont = place[font.family] || {
      variants: [],
      styles: []
    };

    const variant = mapVariant(font.variant, font.style);

    if (variant && samefont.variants.indexOf(variant) === -1) {
      samefont.variants.push(variant);
    }

    samefont.url = addQueryStringParameter(customFontPlace, 'v', resourceLoader.cacheBuster);

    place[font.family] = samefont;
    mappedFonts[font.place] = place;
  });

  return mappedFonts;
}

function prepareWebFontConfig(places: any) {
  const config: { [key: string]: any } = {};

  for (const place in places) {
    const families = places[place];

    config[place] = {};

    for (const family in families) {
      const fontData = families[family];
      const placeData = config[place];

      placeData.families = placeData.families || [];
      placeData.urls = placeData.urls || [];

      placeData.families.push(mapFontName(family, fontData));

      if (fontData.url && placeData.urls.indexOf(fontData.url) === -1) {
        placeData.urls.push(fontData.url);
      }
    }
  }

  return config;
}

async function makeLoadFontRequest(config: any) {
  return new Promise(resolve => {
    const baseConfig = {
      ...config,
      active: resolve,
      inactive: resolve
    };

    WebFont.load(baseConfig);
  });
}

function mapPresetFont(font: any) {
  return {
    family: font.fontFamily,
    place: font.place,
    variant: font.fontWeight,
    style: font.fontStyle
  };
}

function getTemplateFont(fonts: any) {
  const templateFonts: any[] = [];
  const secondaryFont = fonts.find((font: any) => font.key === SECONDARY_FONT_KEY);
  for (const fontWeight of FONT_WEIGHTS) {
    templateFonts.push({
      family: secondaryFont.fontFamily,
      place: secondaryFont.place,
      variant: fontWeight,
      style: secondaryFont.fontStyle
    });
  }
  return templateFonts;
}

function mapVariant(variant: any, style: any) {
  let result = '';
  if (variant) {
    if (variant === 'normal') {
      variant = '400';
    }
    result += variant;
  }
  if (style && style !== 'normal') {
    result += style;
  }
  return result;
}

function mapFontName(family: any, fontData: any) {
  const salt = fontData.variants && fontData.variants.length ? `:${fontData.variants.join(',')}` : '';
  return `${family}${salt}`;
}

export async function loadFontsFromGoogle({
  fontFamily = 'Open Sans',
  fontWeight = [400],
  fontVariants = ['normal'],
  fontLanguages = ['latin', 'cyrillic']
}) {
  const fontConfig = `${fontFamily}:${fontWeight.join(',')}${fontVariants.join(
    ','
  )}:${fontLanguages.join(',')}`;

  return WebFont.load({
    google: {
      families: [fontConfig]
    }
  });
}
