export enum ActionTypes {
    OPEN_POPUP = 'OPEN_POPUP',
    CLOSE_POPUP = 'CLOSE_POPUP'
}

type OpenPopup = {
    type: ActionTypes.OPEN_POPUP,
    payload: {
        settings: { [key: string]: any }
    }
}

type ClosePopup = {
    type: ActionTypes.CLOSE_POPUP
}

export type PopupActionTypes = OpenPopup | ClosePopup;
