import eventEmitter, { events } from 'core/events/eventEmitter';
import { INFORMATION_CONTENT } from 'constants/questionTypes';
import { ProgressStatus } from 'constants/progressStatus';
import * as courseSelectors from 'store/course/selectors';
import { getTimerStatus } from 'store/timer/selectors';
import { TimerStatus } from 'constants/timer';
import {
  getAffectProgressQuestions,
  getSection,
  getSectionProgress,
  isSectionAnswered,
  isSectionFailed
} from './selectors';
import * as settingsSelector from '../settings/selectors';
import { isAttemptSubmitted, getCourseAttempt } from '../course/selectors';

import { ActionTypes } from './types';
import { RootAppState, ThunkResult } from '../types';

const checkSectionFailed = (state: RootAppState, sectionId: string) => {
  if (settingsSelector.shouldSubmitAllQuestions(state)) {
    return (
      settingsSelector.isAttemptsLimited(state) &&
      isAttemptSubmitted(state) &&
      getCourseAttempt(state) >= settingsSelector.getLimitedAttemptsNumber(state)
    );
  }
  return isSectionFailed(state, sectionId);
};

const checkSectionFailedTimedOut = (state: RootAppState, score: number) => {
  const isOverallMasteryScore = settingsSelector.isOverallMasteryScore(state);
  const masteryScore = settingsSelector.getMasteryScoreValue(state);
  if (settingsSelector.getTimerEnabled(state)) {
    return (
      ((isOverallMasteryScore && score < 100) ||
        (!isOverallMasteryScore && score < masteryScore)) &&
      getTimerStatus(state) === TimerStatus.STOPPED
    );
  }
  return false;
};

export const updateScore = (sectionId: string): ThunkResult => (dispatch, getState) => {
  const isOverallMasteryScore = settingsSelector.isOverallMasteryScore(getState());
  const masteryScore = settingsSelector.getMasteryScoreValue(getState());
  const questions = getAffectProgressQuestions(getState(), sectionId);

  const correctQuestions = questions.filter((question: any) =>
    question.type === INFORMATION_CONTENT ? question.hasBeenOpened : question.isAnsweredCorrectly
  );
  const score = questions.length
    ? Math.floor((correctQuestions.length * 100) / questions.length)
    : 100;

  let status = ProgressStatus.IN_PROGRESS;

  if (
    (isOverallMasteryScore && score === 100) ||
    (!isOverallMasteryScore && score >= masteryScore)
  ) {
    status = ProgressStatus.PASSED;
  } else if (
    checkSectionFailed(getState(), sectionId) ||
    checkSectionFailedTimedOut(getState(), score)
  ) {
    status = ProgressStatus.FAILED;
  }
  dispatch({
    type: ActionTypes.SECTION_SCORE_UPDATED,
    payload: {
      id: sectionId,
      score,
      status
    }
  });
};

export const updateSectionAnsweredProgress = (sectionId: string): ThunkResult => (
  dispatch,
  getState
) => {
  const sectionProgress = getSectionProgress(getState(), sectionId);

  dispatch({
    type: ActionTypes.SECTION_ANSWERED_SCORE,
    payload: {
      id: sectionId,
      sectionProgress
    }
  });
};

export const sectionAnswered = (sectionId: string): ThunkResult => (dispatch, getState) => {
  dispatch({
    type: ActionTypes.SECTION_ANSWERED,
    payload: {
      id: sectionId,
      isSectionAnswered: isSectionAnswered(getState(), sectionId)
    }
  });
};

export const updateSection = (sectionId: string): ThunkResult => dispatch => {
  dispatch(updateScore(sectionId));
  dispatch(sectionAnswered(sectionId));
  dispatch(updateSectionAnsweredProgress(sectionId));
};

export const updateProgress = (sectionId: string): ThunkResult<Promise<void>> => async (
  dispatch,
  getState
) => {
  await dispatch(updateSection(sectionId));
  const section = getSection(getState(), sectionId);
  let xapiStatus = section.status;
  if (
    isAttemptSubmitted(getState()) &&
    courseSelectors.isInProgress(getState()) &&
    settingsSelector.shouldSubmitAllQuestions(getState())
  ) {
    xapiStatus = ProgressStatus.FAILED;
  }
  await eventEmitter.emit(events.SECTION_PROGRESSED, { section, xapiStatus });
};

export const onSectionIsPassed = (sectionId: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.SECTION_IS_PASSED,
    payload: {
      id: sectionId,
      status: ProgressStatus.PASSED
    }
  });
};

export const sectionsLoaded = (sections: any): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.SECTIONS_LOADED, payload: sections });
};

export const cleanup = (sectionId: string): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.SECTION_SCORE_UPDATED,
    payload: {
      id: sectionId,
      score: 0,
      status: ProgressStatus.IN_PROGRESS
    }
  });

  dispatch({
    type: ActionTypes.SECTION_ANSWERED,
    payload: {
      id: sectionId,
      isSectionAnswered: false
    }
  });

  dispatch({
    type: ActionTypes.SECTION_ANSWERED_SCORE,
    payload: {
      id: sectionId,
      sectionProgress: 0
    }
  });
};
