import { NO_SELECTED_ID } from 'constants/common';
import idShortener from '../idShortener';

export default class DragAndDropTextResponseMapper extends idShortener {
  map(response: any) {
    return response.map((option: any) => ({
      ...option,
      id: this._mapId(option.id, this.convertToShort.bind(this)),
      items: this._mapItems(option.items, this.convertToShort.bind(this))
    }));
  }

  unMap(response: any) {
    return response.map((option: any) => ({
      ...option,
      id: this._mapId(option.id, this.convertToOriginal.bind(this)),
      items: this._mapItems(option.items, this.convertToOriginal.bind(this))
    }));
  }

  _mapItems(items: any, convert: any) {
    return items.map((item: any) => ({
      ...item,
      id: this._mapId(item.id, convert)
    }));
  }

  _mapId(id: any, convert: any) {
    return id === NO_SELECTED_ID ? id : convert(id);
  }
}
