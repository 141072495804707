import React from 'react';
import { withTheme } from 'styled-components';
import { transparentize } from 'utils/color';
import { localize } from 'core/localization';
import { Figure } from './Figure.styled';

type TrophyProps = {
  theme: { [key: string]: any };
};
export const Trophy = (props: TrophyProps) => (
  <Figure
    width="260px"
    height="141px"
    viewBox="0 0 260 141"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    arialabel={`${localize('[aria label trophy]')}`}
  >
    <title>5F3B7156-1795-4431-B359-0884AE1D4E74</title>
    <desc>Created with sketchtool.</desc>
    <defs>
      <filter
        x="0.0%"
        y="0.0%"
        width="100.0%"
        height="100.0%"
        filterUnits="objectBoundingBox"
        id="filter-1"
      >
        <feGaussianBlur stdDeviation="0" in="SourceGraphic" />
      </filter>
      <filter
        x="0.0%"
        y="0.0%"
        width="100.0%"
        height="100.0%"
        filterUnits="objectBoundingBox"
        id="filter-2"
      >
        <feGaussianBlur stdDeviation="0" in="SourceGraphic" />
      </filter>
    </defs>
    <g
      id="styles-simple-course"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
      transform="translate(-62.000000, -562.000000)"
    >
      <g id="Group-7" transform="translate(62.000000, 561.000000)">
        <g id="Group-4" transform="translate(0.000000, 32.000000)">
          <path
            d="M74.4791667,8.42331288 L20.9852982,8.42331288 C20.9852982,8.42331288 11.6071429,9.43488193 11.6071429,16.8895706 C11.6071429,24.3442592 
            20.9852982,25.3558282 20.9852982,25.3558282 L52.8125,25.4163015 C52.8125,25.4163015 60.2604167,26.8676599 60.2604167,33.398773 
            C60.2604167,39.9298861 52.8125,41.8650307 52.8125,41.8650307 L36.3690476,41.8650307 C36.3690476,41.8650307 23.0208333,42.6797948 
            23.0208333,50.7546012 C23.0208333,58.8294077 36.3690476,60.0674847 36.3690476,60.0674847 L9.47916667,60.0674847 C9.47916667,60.0674847 
            5.06725961e-16,61.7002629 0,68.4732691 C0,75.2462752 9.47916667,77 9.47916667,77 L217.344853,77 C217.344853,77 229.047619,75.6695881 
            229.047619,68.4732691 C229.047619,61.27695 217.344853,60.0674847 217.344853,60.0674847 L197.020332,60.0674847 C197.020332,60.0674847
             185.152433,59.3602005 184.940476,52.0245399 C184.728519,44.6888793 197.020332,43.3163891 197.020332,43.3163891 L220.606061,42.5 
             C220.606061,42.5 232.916667,44.3142786 232.916667,34.1244522 C232.916667,23.9346259 221.590909,25.3558282 221.590909,25.3558282 
             L249.84375,25.4163015 C249.84375,25.4163015 260,24.8060045 260,16.8895706 C260,8.97313658 249.84375,8 249.84375,8 L74.4791667,8.42331288 Z"
            id="Path-8"
            fill={props.theme.colors.textColor}
            opacity="0.0199999996"
            filter="url(#filter-1)"
          />
          <path
            d="M131.399036,59.8785113 C131.087648,59.9948139 130.698414,60.1097949 130.555914,60.095257 C130.503137,60.0873273 130.455637,60.0820408 
            130.406817,60.0820408 C130.364595,60.0820408 130.322373,60.0860057 130.277512,60.0926138 C130.167999,60.1177246 129.728626,59.9961355 
            129.302447,59.841506 C106.800726,54.5114114 103.835946,7.91768911 103.511364,0.681818182 L157.30491,0.681818182 C157.278521,1.28447702 
            157.229702,2.1831788 157.155814,3.2854101 C157.124147,3.3766019 157.108314,3.47308017 157.106994,3.57220169 L157.104355,4.03212555 
            C156.174151,16.9655014 151.719723,55.055919 131.399036,59.8785113 Z"
            id="Path-Copy"
            fill={props.theme.colors.contentBodyColor}
          />
          <rect
            id="Rectangle"
            fill={props.theme.colors.contentBodyColor}
            x="110.465909"
            y="69.3579545"
            width="39.9886364"
            height="17.3863636"
          />
          <rect
            id="Rectangle-Copy"
            fill={props.theme.colors.contentBodyColor}
            x="121.767045"
            y="58.9261364"
            width="17.3863636"
            height="10.4318182"
          />
          <path
            d="M159.188936,5.14909233 L159.19423,4.38312687 C161.424545,3.82654296 163.484111,4.05315212 165.323956,5.06295435 C169.503976,7.36085074 
            170.213441,12.643097 170.324626,14.8071482 L170.324626,14.805823 C170.863343,25.3093564 163.709128,35.0813795 152.695212,39.257084 
            C152.991705,38.4434113 153.224664,37.6310638 153.466888,36.7935376 C153.856035,35.443159 154.258418,34.0503741 154.971854,32.4866383 
            C160.152537,29.5499957 163.34116,24.4691798 164.200195,17.7729453 C164.47948,15.6022681 164.619785,11.76714 162.426532,10.460493 
            C161.494697,9.9065595 160.361671,9.93306349 159.045984,10.5386798 C159.171728,8.72845691 159.180994,6.91690882 159.188936,5.14909233 Z"
            id="Path-Copy-3"
            fill={props.theme.colors.contentBodyColor}
          />
          <path
            d="M90.5958378,14.805823 C90.7070226,12.643097 91.4164877,7.36085074 95.5965078,5.06295435 C97.4350284,4.05182692 99.4932713,3.82389256 
            101.726233,4.38312687 L101.730204,5.13451513 C101.73947,6.90763242 101.747411,8.72448131 101.873156,10.540005 C100.556145,9.93438869 
            99.423119,9.9092099 98.4912843,10.4618182 C96.2980311,11.7684652 96.4383358,15.6035933 96.7176215,17.7742705 C97.5779803,24.4705049 
            100.767926,29.5499957 105.945962,32.4879635 C106.660722,34.0503741 107.061782,35.4458094 107.452252,36.796188 C107.693153,37.632389 
            107.927435,38.4447365 108.222604,39.2584092 C97.2113351,35.0813795 90.0571208,25.3093564 90.5958378,14.805823 Z"
            id="Path-Copy-2"
            fill={props.theme.colors.contentBodyColor}
          />
        </g>
        <g id="Group" transform="translate(88.732955, 0.943182)">
          <path
            d="M26.1460122,114.870488 L56.9219375,114.870488 C57.4272828,114.870488 57.8376285,114.460786 57.8376285,113.954606 C57.8376285,113.922887 
            57.8389479,110.758928 57.9181143,108.764603 C57.9379059,108.258422 57.5447129,107.831539 57.040687,107.811715 C56.5551333,107.786604 
            56.1104822,108.185733 56.0906906,108.690592 C56.0365936,110.049218 56.0181215,111.931205 56.0115243,113.037401 L27.0603838,113.037401 
            L27.0603838,104.25127 L40.5344995,104.417794 C40.9963033,104.427045 41.4541488,104.018664 41.460746,103.512484 C41.4673432,103.006303 
            41.0622752,102.591314 40.5569299,102.584706 L26.1565677,102.407609 C25.8794854,102.407609 25.6762918,102.500123 25.5021258,102.670612 
            C25.3279598,102.842422 25.2303212,103.077671 25.2303212,103.323492 L25.2303212,113.954606 C25.2303212,114.460786 25.6393475,114.870488 
            26.1460122,114.870488 Z"
            fill={props.theme.colors.correctColor}
            fillRule="nonzero"
          />
          <path
            d="M62.6081852,72.9099685 C62.7458426,72.8357573 62.8689401,72.7363673 62.9589469,72.6117985 C75.6472537,68.5421101 84.0311193,57.5972852 
            83.4235736,45.7698772 L83.4235736,45.768552 C83.1548769,40.5459398 80.9867727,36.4417961 77.4738616,34.5096548 C75.3719389,33.3527554 
            73.0569119,33.0320571 70.5790786,33.5369582 C70.6730562,31.9175641 70.6982052,30.975347 70.6982052,30.9395666 C70.7034997,30.6917543 
            70.609522,30.4532183 70.4374503,30.2756416 C70.2653785,30.09939 70.0284489,30 69.7809303,30 L13.9105536,30 C13.663035,30 13.4274291,30.09939 
            13.2540337,30.2756416 C13.0806383,30.4518931 12.9866606,30.6917543 12.9932788,30.9395666 C12.9946024,30.975347 13.0184277,31.9361169 
            13.1150526,33.582015 C10.5472125,33.0082035 8.15276783,33.3156498 5.98069277,34.5096548 C2.46778162,36.4417961 0.299677451,40.5459398 
            0.0309807807,45.768552 L0.0309807807,45.7698772 C-0.576564893,57.5986104 7.80465342,68.5407849 20.4956075,72.6117985 C20.6425303,72.8119037 
            20.8741654,72.9417733 21.1190367,72.9947813 C24.1474997,80.2992824 27.8854278,85.6650163 32.2931121,89.0668041 L32.2931121,98.4346412 
            L20.4850185,98.4346412 C19.9780686,98.4346412 19.5677436,98.8454531 19.5677436,99.3530047 L19.5677436,118.621409 C19.5677436,119.128961 
            19.9780686,119.539773 20.4850185,119.539773 L62.9695359,119.539773 C63.4764858,119.539773 63.8881344,119.128961 63.8881344,118.621409 
            L63.8881344,99.3530047 C63.8881344,98.8454531 63.4778094,98.4346412 62.9695359,98.4346412 L51.1627659,98.4346412 L51.1627659,89.2351044 
            C55.7014895,85.8187394 59.5254535,80.3655424 62.6081852,72.9099685 Z M70.4559811,36.2059105 L70.4612756,35.439945 C72.6915903,34.8833611 
            74.7511569,35.1099703 76.5910011,36.1197725 C80.7710212,38.4176689 81.4804863,43.6999152 81.5916712,45.8639664 L81.5916712,45.8626412 
            C82.1303881,56.3661746 74.9761738,66.1381977 63.9622576,70.3139021 C64.2587505,69.5002295 64.4917092,68.687882 64.7339332,67.8503558 
            C65.1230802,66.4999772 65.5254633,65.1071923 66.2388993,63.5434565 C71.4195829,60.6068138 74.608205,55.5259979 75.4672402,48.8297635 
            C75.7465259,46.6590863 75.8868305,42.8239582 73.6935774,41.5173112 C72.7617426,40.9633777 71.6287163,40.9898817 70.3130291,41.595498 
            C70.4387739,39.7852751 70.4480393,37.973727 70.4559811,36.2059105 Z M69.767694,44.0550687 C71.1045592,43.0876729 72.164786,42.7470966 
            72.7551245,43.0995997 C73.6432795,43.6283544 74.0006593,45.8374624 73.6472504,48.5978535 C72.9152836,54.2922369 70.4692174,58.5342014 
            66.4030293,61.2601373 C66.5578939,60.6571714 66.7193766,60.0701079 66.8676231,59.4499144 C68.166103,54.040449 69.0277855,48.7078451 
            69.5995932,44.1451823 C69.6565092,44.1173531 69.7147489,44.0934995 69.767694,44.0550687 Z M13.6868603,44.0537435 C13.8112815,44.1438571 
            13.955557,44.1915643 14.1011562,44.2114423 C14.6716403,48.7595279 15.5319991,54.0643026 16.8225373,59.447264 C16.9866672,60.1284167 
            17.1627099,60.773789 17.333458,61.4350637 C13.1004927,58.7184042 10.5564779,54.4194561 9.80730398,48.5965283 C9.45257143,45.8374624 
            9.8125985,43.6270292 10.6994299,43.0982745 C11.2857975,42.7457714 12.3486715,43.0863477 13.6868603,44.0537435 Z M1.86288321,45.8626412 
            C1.97406803,43.6999152 2.68353314,38.4176689 6.86355326,36.1197725 C8.70207383,35.1086451 10.7603168,34.8807107 12.9932788,35.439945 
            L12.9972497,36.1913333 C13.0065151,37.9644506 13.0144569,39.7812995 13.1402016,41.5968232 C11.8231908,40.9912069 10.6901645,40.9660281 
            9.75832971,41.5186364 C7.56507659,42.8252834 7.70538126,46.6604115 7.98466696,48.8310887 C8.84502575,55.5273231 12.0349715,60.6068138 
            17.2130078,63.5447817 C17.9277674,65.1071923 18.328827,66.5026276 18.7192975,67.8530062 C18.960198,68.6892072 19.1944803,69.5015547 
            19.4896495,70.3152273 C8.47838054,66.1381977 1.32416623,56.3661746 1.86288321,45.8626412 Z M62.0509374,100.271368 L62.0509374,117.701721 
            L21.4022933,117.701721 L21.4022933,100.271368 L62.0509374,100.271368 L62.0509374,100.271368 Z M49.3242454,98.4346412 L34.1276617,98.4346412 
            L34.1276617,90.3482722 C36.0389819,91.540952 38.0601633,92.4063075 40.1991476,92.9112086 C40.5088768,93.0304766 41.2620216,93.2769637 
            41.8444183,93.2425085 C41.8867745,93.2451589 41.9317779,93.2478093 41.9754576,93.2478093 C42.5406472,93.2478093 43.1865781,93.026501 
            43.3745334,92.946989 C45.469838,92.4513643 47.4513104,91.6244396 49.3242454,90.4847678 L49.3242454,98.4346412 Z M42.8397873,91.1924245 
            C42.5274109,91.3090421 42.1369403,91.4243344 41.9939884,91.4097573 C41.9410433,91.4018061 41.8933926,91.3965053 41.8444183,91.3965053 
            C41.8020622,91.3965053 41.7597061,91.4004809 41.7147027,91.4071069 C41.6048415,91.4322856 41.1640731,91.3103673 40.7365409,91.1553189 
            C18.1633733,85.8107882 15.1891792,39.0908704 14.8635665,31.8354017 L68.8279175,31.8354017 C68.8014449,32.4396928 68.7524706,33.3408286 
            68.6783474,34.4460452 C68.6465803,34.537484 68.6306968,34.6342236 68.6293732,34.7336136 L68.6267259,35.1947831 C67.6935675,48.163188 
            63.2249963,86.3567705 42.8397873,91.1924245 Z"
            fill={props.theme.colors.correctColor}
            fillRule="nonzero"
          />
          <ellipse
            id="Oval-3"
            stroke={props.theme.colors.correctColor}
            strokeWidth="0.866666667"
            fill={transparentize(props.theme.colors.correctColor, 0.06)}
            cx="41.7272727"
            cy="57.0040584"
            rx="10.6086287"
            ry="10.6594968"
          />
          <path
            d="M43.5872704,5.21607097 C43.2302767,5.58188202 43.0673096,6.10896171 43.151669,6.62569333 L43.5481583,9.05681818 L41.4717662,7.90898859 
            C41.2512813,7.78696198 41.0090675,7.72621746 40.7669815,7.72621746 C40.5248956,7.72621746 40.2828096,7.78723076 40.0621969,7.90898859 
            L37.9858048,9.05681818 L38.3822941,6.62569333 C38.4666535,6.1090961 38.3036864,5.58201641 37.9466927,5.21607097 L36.2670455,3.49452817 
            L38.5884634,3.13973714 C39.0817104,3.06447844 39.5082367,2.73858139 39.7288494,2.26861767 L40.7671094,0.0568181818 L41.8052415,2.26861767 
            C42.025982,2.73858139 42.4522527,3.06434405 42.9456275,3.13973714 L45.2670455,3.49452817 L43.5872704,5.21607097 Z"
            fill={props.theme.colors.correctColor}
            fillRule="nonzero"
          />
          <path
            d="M8.58727041,14.216071 C8.23027668,14.581882 8.06730961,15.1089617 8.15166903,15.6256933 L8.54815832,18.0568182 L6.47176617,16.9089886 
            C6.25128132,16.786962 6.00906752,16.7262175 5.76698155,16.7262175 C5.52489557,16.7262175 5.28280959,16.7872308 5.06219692,16.9089886 
            L2.98580477,18.0568182 L3.38229406,15.6256933 C3.46665348,15.1090961 3.30368642,14.5820164 2.94669268,14.216071 L1.26704545,12.4945282 
            L3.58846337,12.1397371 C4.08171036,12.0644784 4.50823671,11.7385814 4.72884938,11.2686177 L5.76710936,9.05681818 L6.80524153,11.2686177 
            C7.02598201,11.7385814 7.45225273,12.0643441 7.94562753,12.1397371 L10.2670455,12.4945282 L8.58727041,14.216071 Z"
            fill={props.theme.colors.correctColor}
            fillRule="nonzero"
            transform="translate(5.767045, 13.556818) rotate(-15.000000) translate(-5.767045, -13.556818) "
          />
          <path
            d="M77.5872704,14.216071 C77.2302767,14.581882 77.0673096,15.1089617 77.151669,15.6256933 L77.5481583,18.0568182 L75.4717662,16.9089886 
            C75.2512813,16.786962 75.0090675,16.7262175 74.7669815,16.7262175 C74.5248956,16.7262175 74.2828096,16.7872308 74.0621969,16.9089886 
            L71.9858048,18.0568182 L72.3822941,15.6256933 C72.4666535,15.1090961 72.3036864,14.5820164 71.9466927,14.216071 L70.2670455,12.4945282 
            L72.5884634,12.1397371 C73.0817104,12.0644784 73.5082367,11.7385814 73.7288494,11.2686177 L74.7671094,9.05681818 L75.8052415,11.2686177 
            C76.025982,11.7385814 76.4522527,12.0643441 76.9456275,12.1397371 L79.2670455,12.4945282 L77.5872704,14.216071 Z"
            fill={props.theme.colors.correctColor}
            fillRule="nonzero"
            transform="translate(74.767045, 13.556818) scale(-1, 1) rotate(-15.000000) translate(-74.767045, -13.556818) "
          />
          <ellipse
            id="Oval-6"
            fill={props.theme.colors.textColor}
            opacity="0.100000001"
            filter="url(#filter-2)"
            cx="41.2670455"
            cy="137.556818"
            rx="26"
            ry="3.5"
          />
          <path
            d="M44.4006287,57.7893213 C44.003969,58.195778 43.8228945,58.7814221 43.9166272,59.3555683 L44.3571709,62.0568182 L42.0500685,60.781452 
            C41.8050853,60.6458668 41.5359589,60.5783729 41.2669744,60.5783729 C40.99799,60.5783729 40.7290056,60.6461655 40.4838804,60.781452 
            L38.176778,62.0568182 L38.6173217,59.3555683 C38.7110544,58.7815714 38.5299799,58.1959273 38.1333201,57.7893213 L36.2670455,55.8764959 
            L38.8463987,55.4822837 C39.3944509,55.3986629 39.8683691,55.0365551 40.1134943,54.5143732 L41.2671165,52.0568182 L42.4205966,54.5143732 
            C42.6658639,55.0365551 43.139498,55.3985136 43.6876922,55.4822837 L46.2670455,55.8764959 L44.4006287,57.7893213 Z"
            fill={props.theme.colors.correctColor}
            fillRule="nonzero"
            transform="translate(0.5, -0.4)"
          />
        </g>
      </g>
    </g>
  </Figure>
);

export default withTheme(Trophy);
