export const getStringHash = (string: string) => {
  let hash = 0;
  let chr;
  if (string.length === 0) {
    return hash;
  }
  for (let index = 0; index < string.length; index++) {
    chr = string.charCodeAt(index);
    // eslint-disable-next-line
    hash = (hash << 5) - hash + chr;
    // eslint-disable-next-line
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

export default getStringHash;
