import styled, { css } from 'styled-components';
import { transition } from 'components/mixins/interactivity';

type ContentProps = {
  defaultOpacity: number;
  opacityDuration: string;
  ref: any
}
export const Content = styled.div`
  ${(props: ContentProps) =>
    css`
      opacity: ${props.defaultOpacity};
      ${transition('opacity, height', props.opacityDuration)};
    `};
`;

type ContainerProps = {
  duration: number;
  isVisible: boolean;
  ref: any;
}
export const Container = styled.div`
  height: 0px;
  overflow: hidden;
  ${(props: ContainerProps) =>
    css`
      ${transition('height, visibility', props.duration)};
    `};
  visibility: ${props => (props.isVisible ? 'visible' : 'hidden')};
`;
