import * as React from 'react';
import TextareaAutosize from 'react-autosize-textarea';
import EditableContentWrapper from './EditableTextarea.styled';

type EditableTextareaProps = {
  defaultValue?: string;
  onResize?(e?: any): void;
  disabled?: boolean;
  placeholder: string;
  onBlur?(e?: any): void;
  onChange(e?: any): void;
  tabIndex: number;
  rows: number;
  isAutofocus: boolean;
};

export class EditableTextarea extends React.PureComponent<EditableTextareaProps, {}> {
  textAreaNode: any;
  constructor(props: EditableTextareaProps) {
    super(props);
    this.textAreaNode = React.createRef()
  }

  static defaultProps = {
    onResize: () => { },
    tabIndex: 0,
    defaultValue: '',
    rows: 3,
    isAutofocus: false
  };

  componentDidMount() {
    if (this.props.isAutofocus) {
      this.textAreaNode.current.focus();
    }
  }

  render() {
    const {
      placeholder,
      onResize,
      onBlur,
      onChange,
      defaultValue,
      disabled,
      tabIndex,
      rows
    } = this.props;
    return (
      <EditableContentWrapper>
        <TextareaAutosize
          placeholder={placeholder}
          onResize={onResize}
          rows={rows}
          onChange={onChange}
          onBlur={onBlur}
          defaultValue={defaultValue}
          disabled={disabled}
          tabIndex={tabIndex}
          ref={this.textAreaNode}
        />
      </EditableContentWrapper>
    );
  }
}

export default EditableTextarea;
