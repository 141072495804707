import StatementChoiceResponseMapper from './StatementChoice';

export default class RankingTextResponseMapper extends StatementChoiceResponseMapper {
  map(response: any) {
    return super.map(response);
  }

  unMap(response: any) {
    return super.unMap(response);
  }
}
