import ResponseMapperBase from './ResponseMapperBase';

export default class HotspotResponseMapper extends ResponseMapperBase {
  map(question: any) {
    if (!question.isAnswered) {
      return undefined;
    }

    return this.mapResponse(question);
  }

  mapResponse(question: any) {
    if (!this.hasQuestionResponse(question)) {
      return [];
    }

    return question.response;
  }

  /**
   * @param {object} userAnswer
   * @param {object} question
   */
  unMap(userAnswer: any, { spots }: any) {
    if (this.isAnsweredCorrectly(userAnswer)) {
      return spots.map((spot: any) => spot[0]);
    }

    if (this.isEmptyAnswer(userAnswer)) {
      return [];
    }

    return userAnswer;
  }
}
