import styled, { css } from 'styled-components';
import { transparentize } from 'utils/color';
import { LINK } from 'constants/components';
import buttonDefaultStyle from 'components/mixins/normalize';
import { secondaryFont } from 'components/mixins/typography';

type LinkElementProps = {
  'data-test': string;
  href: string;
  layout: string;
  size: number;
  theme: any;
  opacity: number;
  tabIndex: number;
};

const LinkElement = styled.a.attrs((props: LinkElementProps) => ({
  href: props.href,
  tabIndex: props.tabIndex,
  'data-test': props['data-test']
}))`
  ${buttonDefaultStyle};
  text-decoration: underline;
  padding: 0;
  &:focus {
    outline: none;
  }
  ${(props: LinkElementProps) =>
    props.layout === LINK.LAYOUT.FORM &&
    css`
      ${secondaryFont};
      color: ${transparentize(props.theme.colors.textColor, props.opacity)};
      font-weight: normal;
      font-size: ${props.size}px;
      &:hover {
        color: ${props.theme.colors.textColor};
      }
      &:focus {
        color: ${props.theme.colors.textColor};
        outline: 1px solid black;
      }
      &:focus:not(:focus-visible) {
        outline: none;
      }
    `};
  ${props =>
    props.layout === LINK.LAYOUT.DEFAULT &&
    css`
      color: ${props.theme.colors.textColor};
    `};
`;

export default LinkElement;
