import logger from 'utils/logger';

class AnalyticsPlugin {
    private static analytics: any;
    private static instance: AnalyticsPlugin;
    private static timestamp: number;


    private constructor() { }

    static getInstance() {
        if (!AnalyticsPlugin.instance) {
            AnalyticsPlugin.instance = new AnalyticsPlugin();
        }
        return AnalyticsPlugin.instance;
    }

    public async init(writeKey: string) {
        const { Analytics } = await import('analytics');
        const SegmentPlugin = await import('@analytics/segment');
        AnalyticsPlugin.analytics = Analytics({
            plugins: [
                SegmentPlugin.default({
                    writeKey
                })
            ]
        })

        AnalyticsPlugin.timestamp = Date.now();
    }

    public trackEvent(eventName: string, category?: string | null, properties?: { [key: string]: string | number }) {
        const props: { [key: string]: string | number } = {};
        const integrations = {
            Amplitude: {
                session_id: AnalyticsPlugin.timestamp
            }
        }
        if (category) {
            props.category = category;
        }

        if (properties) {
            Object.assign(props, properties);
        }

        try {
            AnalyticsPlugin.analytics.track(eventName, props, { integrations })
        } catch (err) {
            logger.log(err);
            throw (err);
        }
    }
}

export default AnalyticsPlugin.getInstance();
