export enum ActionTypes {
    QUESTION_SCENARIO_BRANCHTRACK_DATA = 'QUESTION_SCENARIO_BRANCHTRACK_DATA'
}

type ScenarioBranchTrackData = {
    type: ActionTypes.QUESTION_SCENARIO_BRANCHTRACK_DATA,
    payload: {
        score: number,
        isFinished: boolean,
        questionId: string
    }
}

export type ScenarioActionTypes = ScenarioBranchTrackData;
