import { PureComponent } from 'react';

type DelayProps = {
  children?: any;
  wait?: number;
  callback?(): void;
};

type DelayState = {
  isWaiting: boolean;
}
export class Delay extends PureComponent<DelayProps, DelayState> {
  timer: any;
  constructor(props: DelayProps) {
    super(props);
    this.state = { isWaiting: true };
    this.timer = () => {};
  }

  static defaultProps = {
    wait: 250
  };

  componentDidMount = () => {
    this.timer = setTimeout(() => {
      this.setState({
        isWaiting: false
      });
      if (this.props.callback) {
        this.props.callback();
      }
    }, this.props.wait);
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };

  render() {
    return this.state.isWaiting ? null : this.props.children;
  }
}

export default Delay;
