import { schema } from 'normalizr';
import question from './question';
import { ProgressStatus } from 'constants/progressStatus';

const section = new schema.Entity(
  'sections',
  {
    questions: [question]
  },
  {
    processStrategy: (value: any) => ({
      ...value,
      score: 0,
      status: ProgressStatus.IN_PROGRESS
    })
  }
);

export default section;
