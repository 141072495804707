import idShortener from '../idShortener';

export default class OpenQuestionResponseMapper extends idShortener {
  map(response: any) {
    return { ...response, id: this.convertToShort(response.id) };
  }

  unMap(response: any) {
    return { ...response, id: this.convertToOriginal(response.id) };
  }
}
