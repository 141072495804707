export enum ProgressStatus {
  IN_PROGRESS = 0,
  PASSED = 1,
  FAILED = 2
}

export enum CourseProgressStorageStatus {
  IN_PROGRESS = 'IN_PROGRESS',
  PASSED = 'PASSED',
  FAILED = 'FAILED'
}
