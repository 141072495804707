import { deepExtend } from 'utils/object';

// eslint-disable-next-line import/prefer-default-export
export const processSettings = function (settings: { [key: string]: any}, themeSettings: { [key: string]: any }, manifest: { [key: string]: any }) {
  return deepExtend(
    processThemeSettings(themeSettings, manifest),
    processTemplateSettings(settings, manifest)
  );
};

function processThemeSettings(themeSettings: any, manifest: any) {
  let preset = manifest && Array.isArray(manifest.presets) ? manifest.presets[0] : null;
  let defaultThemeSettings = preset != null ? preset.settings : {};

  return deepExtend(defaultThemeSettings, themeSettings);
}

function processTemplateSettings(templateSettings: any, manifest: any) {
  if (!manifest.defaultTemplateSettings) {
    throw new Error("Manifest doesn't have defaultTemplateSettings");
  }
  return deepExtend(manifest.defaultTemplateSettings, templateSettings);
}
