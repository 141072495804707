
import initialState from 'store/initialState';
import { ActionTypes, ChecklistActionTypes } from './types';

export default function checklistReducer(
    state= initialState.checklist,
    action: ChecklistActionTypes
) {
    switch (action.type) {
        case ActionTypes.TOGGLE_CHECK:
        case ActionTypes.INIT_CHECKLIST:
            return { ...state, ...action.payload };
        default:
            return state;
    }
}
