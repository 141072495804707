/* eslint-disable prefer-destructuring */
const error = console.error;
const warn = console.warn;
const info = console.info;
const log = console.log;

export default {
  log,
  warn,
  info,
  error
};
