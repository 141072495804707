import { ActionTypes, ScenarioActionTypes } from './types';
import { QuestionsState } from '../types';

export default function reducer(state: QuestionsState, action: ScenarioActionTypes) {
  switch (action.type) {
    case ActionTypes.QUESTION_SCENARIO_BRANCHTRACK_DATA:
      return {
        score: action.payload.score,
        isFinished: action.payload.isFinished
      };
    default:
      return state;
  }
}
