import store from 'store';
import { getTranslations } from 'store/settings/selectors';

const applyTemplateData = (value: any, templateData: any) => {
  for (let prop in templateData) {
    const pattern = `{\\s*${prop}\\s*}`;
    const regex = new RegExp(pattern, 'gmi');
    value = value.replace(regex, templateData[prop]);
  }
  return value;
};

/* eslint-disable import/prefer-default-export */
export const localize = (key: any, templateData?: any, defaultValue?: any) => {
  const translations = getTranslations(store.getState());
  if (process.env.NODE_ENV === 'development' && !translations) {
    return defaultValue || key;
  }
  if (!translations.hasOwnProperty(key)) {
    throw new Error(
      `Localization error appeared. Translation for key '${key}' has not been found.`
    );
  }
  const value = translations[key];
  return templateData ? applyTemplateData(value, templateData) : value;
};
