export enum ActionTypes {
  RESOURCE_DATA_LOADING_STARTED = 'RESOURCE_DATA_LOADING_STARTED',
  RESOURCE_DATA_LOADED = 'RESOURCE_DATA_LOADED',
  RESOURCE_DATA_LOADING_FAILED = 'RESOURCE_DATA_LOADING_FAILED',
}

export type ResourceState = {
  id: string;
  resourceType: string; // enum
  title: string;
  createdBy: string;
  createdOn: Date;
  contentId: string;
  resourceContents: [];
};

interface ResourceDataLoadingStarted {
  type: ActionTypes.RESOURCE_DATA_LOADING_STARTED;
}

interface ResourceDataLoaded {
  type: ActionTypes.RESOURCE_DATA_LOADED;
  payload: ResourceState;
}

interface ResourceDataLoadedFailed {
  type: ActionTypes.RESOURCE_DATA_LOADING_FAILED;
  reason: any;
}

export type ResourceActionTypes =
  | ResourceDataLoadingStarted
  | ResourceDataLoaded
  | ResourceDataLoadedFailed;
