import React from 'react';
import { SkipToContentElement } from './SkipToContent.styled';
import { localize } from 'core/localization';

export interface SkipToContentProps {
  onClick(): void;
  href?: string;
}
export const SkipToContent = (props: SkipToContentProps) => {
  const { onClick, href = '#' } = props;
  return (
    <SkipToContentElement
      onClick={(event: any) => {
        event.preventDefault();
        onClick();
      }}
      onKeyPress={(event: any) => {
        event.preventDefault();
        onClick();
      }}
      href={href}
      top={10}
      left={40}
      tabIndex={0}
    >
      {localize('[skip to content]')}
    </SkipToContentElement>
  );
};

export default SkipToContent;
