import logger from 'utils/logger';
import { hasQueryStringParameter } from 'utils/window';
import configurationReader from 'core/configuration/reader';
import { getTranslations } from 'core/configuration/translationProvider';
import { processSettings } from 'core/configuration/settingsProcessor';
import { loadFonts } from 'core/fonts/loader';
import { MATERIAL_TYPES } from 'constants/common';

import { ActionTypes } from './types';
import { ThunkResult } from 'store/types';

export const load = (): ThunkResult<Promise<void>> => async dispatch => {
  try {
    const [
      manifest,
      customization,
      publishSettings,
      templateThemeSettings,
      templateSettings
    ]: [
      { [key: string]: any },
      { [key: string]: any },
      { [key: string]: any },
      any,
      any
    ] = await Promise.all([
      configurationReader.readManifest(),
      configurationReader.readCustomization(),
      configurationReader.readPublishSettings(),
      configurationReader.readThemeSettings(),
      configurationReader.readSettings()
    ]);

    const settings: { [key: string]: any } = processSettings(templateSettings, templateThemeSettings, manifest);
    const translations: { [key: string]: any } = await getTranslations(settings);
    await loadFonts(settings && settings.fonts, publishSettings.customFontPlace);

    const publishMode: { [key: string]: any } = {
      isPreview: publishSettings.publishMode === 'Preview',
      isReview: hasQueryStringParameter('reviewApiUrl'),
      isScorm: publishSettings.publishMode === 'Lms' || publishSettings.publishMode === 'Lms2004',
      isScorm2004: publishSettings.publishMode === 'Lms2004'
    };

    if (publishMode.isPreview || publishMode.isReview) {
      settings.conductLearnerSatisfactionSurvey.enabled = false;
      settings.saveProgressCrossDevice.enabled = false;
      settings.resultsTracking.enabled = false;
    }

    if(publishSettings.materialType === MATERIAL_TYPES.RESOURCE) {
      settings.saveProgressCrossDevice = false;
    }

    delete publishSettings.publishMode;

    dispatch({
      type: ActionTypes.SETTINGS_LOADED,
      settings: {
        manifest,
        publishSettings,
        settings,
        customization,
        translations,
        publishMode
      } as any
    });
  } catch (e) {
    logger.error(e);
    dispatch({ type: ActionTypes.SETTINGS_LOADING_FAILED, reason: e });
    throw e;
  }
};
