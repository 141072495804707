import { toggleArrayElement } from 'utils/object';
import { ActionTypes, MultipleChoiceActionTypes } from './types';
import { QuestionsState } from '../types';

export default function reducer(state: QuestionsState, action: MultipleChoiceActionTypes) {
  const questionState: any = state;

  switch (action.type) {
    case ActionTypes.QUESTION_MULTIPLECHOICE_TOGGLE_ANSWER_IS_SELECTED:
      return toggleArrayElement(questionState, action.payload.answerId);
    case ActionTypes.QUESTION_MULTIPLECHOICE_SET_SINGLE_ANSWER_SELECTED:
    case ActionTypes.SET_SINGLE_ANSWER_SELECTED:
      questionState.splice(0, questionState.length, action.payload.answerId);
      return state;
    default:
      return state;
  }
}
