import 'babel-polyfill';
import 'react-app-polyfill/ie11';
import 'webfontloader';
import 'react-hot-loader/patch';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { HashRouter } from 'react-router-dom';
import { AppContainer } from 'react-hot-loader';
import Shell from './components/shell';
import store, { history } from './store';
import 'react-circular-progressbar/dist/styles.css';

import BaseStyles from './assets/styles';

const render = (Component: any) => {
  ReactDOM.render(
    <React.Fragment>
      <BaseStyles />
      <AppContainer>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <HashRouter>
              <Component />
            </HashRouter>
          </ConnectedRouter>
        </Provider>
      </AppContainer>
    </React.Fragment>,
    document.querySelector('#root')
  );
};

render(Shell);

if (module.hot) {
  module.hot.accept('components/shell', () => {
    /* eslint-disable global-require */
    const NextShell = require('components/shell').default;
    render(NextShell);
  });
}

if (process.env.NODE_ENV !== 'production') {
  const axe = require('react-axe');
  const config = {
    rules: [
      {
        id: 'heading-order',
        enabled: false
      },
      {
        id: 'tabindex',
        enabled: false
      },
      {
        id: 'button-name',
        enabled: false
      },
      {
        id: 'color-contrast',
        enabled: false
      }
    ]
  };
  axe(React, ReactDOM, 1000, config);
}
