import * as React from 'react';
import { ICON, SIZES } from 'constants/components';
import Icon from 'components/common/Icon';
import { IconShape } from './ShapedIcon.styled';

interface Size {
  [SIZES.SMALL]: string;
  [SIZES.MEDIUM]: string;
  [SIZES.LARGE]: string;
}

interface Shape {
  [ICON.SHAPES.ROUND_FULL_COLOR]: string;
  [ICON.SHAPES.ROUND_OUTLINED]: string;
  [ICON.SHAPES.ROUND_SHADED]: string;
}

type ShapedIconProps = {
  name: string;
  color?: string;
  getColor?(): void;
  getHoverColor?(): void;
  className?: string;
  size: keyof Size | number;
  fontSize?: number;
  shape?: keyof Shape;
  hoverShape?: keyof Shape;
  shapeColor: string;
  getShapeColor?(): void;
};

export const ShapedIcon = (props: ShapedIconProps) => {
  const { name = 'ok', shapeColor = 'mainColor', shape = ICON.SHAPES.ROUND_FULL_COLOR, size = SIZES.SMALL, fontSize, color = 'buttonTextColor', getColor } = props;

  return (
    <IconShape shapeColor={shapeColor} shape={shape} {...props}>
      <Icon
        shapeSize={size}
        size={getIconSize(fontSize, size)}
        name={name}
        color={color}
        getColor={getColor}
      />
    </IconShape>
  );
};

function getIconSize(fontSize: any, size: any) {
  if (typeof size === 'number' && !fontSize) {
    throw new Error('fontSize must be specified when size is set as numeric value');
  }

  if (fontSize) return fontSize;
  switch (size) {
    case SIZES.SMALL:
      return 6;
    case SIZES.MEDIUM:
      return 10;
    default:
      return 12;
  }
}

ShapedIcon.SHAPES = ICON.SHAPES;
ShapedIcon.SIZES = SIZES;

export default ShapedIcon;
