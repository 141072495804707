export enum ActionTypes {
    HOTSPOT_QUESTION_ANSWER = 'HOTSPOT_QUESTION_ANSWER'
}

type QuestionAnswer = {
    type: ActionTypes.HOTSPOT_QUESTION_ANSWER,
    payload: {
        data: any,
        questionId: string
    }
}

export type HotspotActionTypes = QuestionAnswer;
