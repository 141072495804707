import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { IS_NO_ACCESS, IS_NOT_AUTHORIZED } from 'constants/typeAccess';
import { LOGIN_PATH } from 'constants/navigation';
import { MATERIAL_TYPES } from 'constants/common';
import { RootAppState } from 'store/types';
import {
  isCourseAccessLimited,
  isProgressRestoreFetched,
  isProgressRestoreFailed
} from 'store/course/selectors';
import { isLoaderVisibility } from 'store/loader/selectors';
import { isReviewMode, isPreviewMode } from 'store/settings/selectors';
import MainCircleLoader from 'components/common/MainCircleLoader/MainCircleLoader';

type PrivateRouteProps = {
  component: any;
  componentNoAccess: any;
  typeAccess: string;
  exact?: any;
  path: any;
  materialType?: string;
  isCourseProgressRestoreFetched?: boolean;
  isCourseProgressRestoreFailed?: boolean;
  courseAccessLimited?: boolean;
  isLoaderVisible?: boolean;
  reviewMode?: boolean;
  previewMode?: boolean;
};

const materialTypeCourseComponents = (displayComponents = false, Component?: any, props?: any) => {
  if (displayComponents) {
    return <Component {...props} />;
  }
  return <MainCircleLoader />;
};

export const PrivateRoute = ({
  component: Component,
  componentNoAccess: ComponentNoAccess,
  typeAccess,
  materialType,
  isCourseProgressRestoreFetched,
  isCourseProgressRestoreFailed,
  courseAccessLimited,
  isLoaderVisible,
  reviewMode,
  previewMode,
  ...rest
}: PrivateRouteProps) => (
  <Route
    {...rest}
    render={props => {
      if (typeAccess === IS_NO_ACCESS && !isLoaderVisible) {
        return <ComponentNoAccess {...props} />;
      }
      if (typeAccess === IS_NOT_AUTHORIZED) {
        return <Redirect to={LOGIN_PATH} />;
      }
      if (materialType === MATERIAL_TYPES.COURSE) {
        return materialTypeCourseComponents(
          courseAccessLimited ||
            reviewMode ||
            previewMode ||
            isCourseProgressRestoreFetched ||
            isCourseProgressRestoreFailed,
          Component,
          props
        );
      }
      if (!isLoaderVisible) {
        return <Component {...props} />;
      }
      return <></>;
    }}
  />
);

function mapStateToProps(state: RootAppState) {
  return {
    isCourseProgressRestoreFetched: isProgressRestoreFetched(state),
    isCourseProgressRestoreFailed: isProgressRestoreFailed(state),
    courseAccessLimited: isCourseAccessLimited(state),
    isLoaderVisible: isLoaderVisibility(state),
    reviewMode: isReviewMode(state),
    previewMode: isPreviewMode(state)
  };
}

export default connect(mapStateToProps)(PrivateRoute);
