import ResponseMapperBase from './ResponseMapperBase';

export default class TextMatchingResponseMapper extends ResponseMapperBase {
  /**
   * @param {object} question
   */
  mapResponse({ answers, response }: any) {
    let result: { [key: string]: any } = {};

    response.forEach(({ id, shownSelectedValue }: any) => {
      const answerIndex = answers.findIndex((answer: any) => answer.id === id);
      result[answerIndex] = shownSelectedValue;
    });

    return result;
  }

  /**
   * @param {object} userAnswer
   * @param {object} question
   */
  unMap(userAnswer: any, { answers }: any) {
    if (this.isAnsweredCorrectly(userAnswer)) {
      return answers.map(({ id, key, value }: any) => ({
        id,
        key,
        value,
        selectedKey: key,
        shownSelectedValue: value
      }));
    }

    if (this.isEmptyAnswer(userAnswer)) {
      return [];
    }

    return answers.map(({ id, key, value }: any, index: any) => {
      const selectedOption = userAnswer[index];
      const selectedKey = answers.find((answer: any) => answer.value === selectedOption);

      return {
        id,
        key,
        value,
        selectedKey: selectedKey ? selectedKey.key : null,
        shownSelectedValue: selectedOption
      };
    });
  }
}
