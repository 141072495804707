import * as React from 'react';
import { localize } from 'core/localization';
import { TextElement, Button, TextLabel } from './ButtonVote.styled';

type ButtonVoteProps = {
  rating: number;
  ratingLabelKey: string;
  tabIndex: number;
  onClick(): void;
};

const ButtonVote = ({ rating, ratingLabelKey, tabIndex, onClick }: ButtonVoteProps) => (
  <Button tabIndex={tabIndex} onClick={onClick}>
    <TextElement appearance="span" size={16} font="mainFont">
      {rating}
    </TextElement>
    {ratingLabelKey && (
      <TextLabel appearance="span" size={12} font="secondaryFont">
        {localize(ratingLabelKey)}
      </TextLabel>
    )}
  </Button>
);

export default ButtonVote;
