export const shuffle = (obj: any) => {
  let ctr = obj.length;
  let temp;
  let index;
  while (ctr > 0) {
    index = Math.floor(Math.random() * ctr);
    ctr--;
    temp = obj[ctr];
    obj[ctr] = obj[index];
    obj[index] = temp;
  }
  return obj;
};

export default shuffle;
