import { css } from 'styled-components';
import { transition } from 'components/mixins/interactivity';

const animationsCSS = css`
  /*fade*/
  .fade-enter,
  .fade-appear {
    opacity: 0;
  }

  .fade-enter.fade-enter-active,
  .fade-appear.fade-appear-active {
    ${transition('opacity', '0.4s')};
    opacity: 1;
  }

  .fade-exit {
    opacity: 1;
  }

  .fade-exit.fade-exit-active {
    ${transition('opacity', '0.4s')};
    opacity: 0.01;
  }

  /*slideInDown*/
  .slideInDown-enter,
  .slideInDown-appear {
    transform: translateY(-10px);
  }

  .slideInDown-enter.slideInDown-enter-active,
  .slideInDown-appear.slideInDown-appear-active {
    ${transition('transform', '0.4s')};
    transform: translateY(0px);
  }

  .slideInDown-exit {
    transform: translateY(0px);
  }

  .slideInDown-exit.slideInDown-exit-active {
    ${transition('transform', '0.4s')};
    transform: translateY(-10px);
  }

  /*fadeInDown*/
  .fadeInDown-enter,
  .fadeInDown-appear {
    opacity: 0;
    transform: translateY(-10px);
  }

  .fadeInDown-enter.fadeInDown-enter-active,
  .fadeInDown-appear.fadeInDown-appear-active {
    ${transition('all', '0.3s')};
    opacity: 1;
    transform: translateY(0px);
  }

  .fadeInDown-exit {
    opacity: 1;
    transform: translateY(0px);
  }

  .fadeInDown-exit.fadeInDown-exit-active {
    ${transition('all', '0.3s')};
    opacity: 0.01;
    transform: translateY(-10px);
  }
`;

export default animationsCSS;
