import * as React from 'react';
import { connect } from 'react-redux';
import CircleLoader from 'components/common/CircleLoader';
import { localize } from 'core/localization';
import { ImageItem, Image } from './PreviewGallery.styled';

type PreviewGalleryPopupProps = {
  popupSettings: { [key: string]: any };
};

type PreviewGalleryPopupState = {
  isImageLoaded: boolean
}

export class PreviewGalleryPopup extends React.Component<PreviewGalleryPopupProps, PreviewGalleryPopupState> {
  constructor(props: PreviewGalleryPopupProps) {
    super(props);
    this.state = {
      isImageLoaded: false
    };
  }

  handleImageLoaded = () => {
    this.setState({ isImageLoaded: true });
  };

  render() {
    const { imageUrls } = this.props.popupSettings;
    const { isImageLoaded } = this.state;
    return (
      <>
        {imageUrls.map((image: any, index: any) => (
          <ImageItem key={index}>
            {!isImageLoaded && <CircleLoader iconSize={48} />}
            <Image
              src={image}
              alt={localize('[alt answer image]')}
              onLoad={() => this.handleImageLoaded()}
              isImageLoaded={isImageLoaded}
            />
          </ImageItem>
        ))}
      </>
    );
  }
}

export default connect()(PreviewGalleryPopup);
