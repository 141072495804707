import { ActionTypes } from './types';
import { ThunkResult } from '../types';

export const openLoader = (): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.OPEN_LOADER });
};

export const closeLoader = (): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.CLOSE_LOADER });
};
