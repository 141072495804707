import { css } from 'styled-components';
import {
  MEDIA_XSMALL,
  MEDIA_SMALL,
  MEDIA_MEDIUM,
  MEDIA_LARGE,
  MEDIA_XLARGE,
  MEDIA_SMALL_FONT_RATIO,
  MEDIA_TABLET,
  MEDIA_MOBILE
} from 'constants/screenResolutions';

const sizes: { [key: string]: any } = {
  xsmall: MEDIA_XSMALL,
  small: MEDIA_SMALL,
  medium: MEDIA_MEDIUM,
  large: MEDIA_LARGE,
  xlarge: MEDIA_XLARGE,
  tablet: MEDIA_TABLET,
  smallFontRatio: MEDIA_SMALL_FONT_RATIO,
  mobile: MEDIA_MOBILE
};

const media = Object.keys(sizes).reduce((acc: { [key: string]: any }, label: any) => {
  acc[label] = (...args: any) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(args[0], ...args)};
    }
  `;

  return acc;
}, {});

export default media;
