import { questionResponseReducers } from 'store/rootReducer';
import { QuestionsState } from './types';

export default function responseReducer(state: QuestionsState, action?: any) {
  if (!action || !action.payload || !action.payload.hasOwnProperty('questionId')) {
    return state;
  }

  const question = state[action.payload.questionId];
  if (!question) {
    return state;
  }
  question.response = reduceQuestionResponse(question.response, action);

  return state;
}

function reduceQuestionResponse(response: any, action: any) {
  questionResponseReducers.forEach(reducer => {
    response = reducer(response, action);
  });

  return response;
}
