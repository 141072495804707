import { TimerStatus } from 'constants/timer';

export enum ActionTypes {
  START_TIMER = 'START_TIMER',
  SET_TIMER_ELAPSED = 'SET_TIMER_ELAPSED',
  SET_TIMER_STATUS = 'SET_TIMER_STATUS',
  RESET_TIMER = 'RESET_TIMER'
}

export type TimerState = {
  status: TimerStatus;
  elapsed: number;
};

type StartTimer = {
  type: ActionTypes.START_TIMER;
};

type setTimerElapsed = {
  type: ActionTypes.SET_TIMER_ELAPSED;
  payload: {
    elapsed: number;
  };
};

type setTimerStatus = {
  type: ActionTypes.SET_TIMER_STATUS;
  payload: {
    status: TimerStatus;
  };
};

type ResetTimer = {
  type: ActionTypes.RESET_TIMER;
};

export type TimerActionTypes = StartTimer | setTimerElapsed | setTimerStatus | ResetTimer;
