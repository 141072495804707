/* eslint-disable no-useless-escape */

export const toCamelCase = (value: string) =>
  value.toLowerCase().replace(/(\-\w)/g, matches => matches[1].toUpperCase());

export const normalizeColorName = (color: any) => toCamelCase(color.replace('@', ''));

export const getShortString = (str: any, countOfSymbols = 0) => {
  const eos = '...';
  if (typeof str !== 'string') {
    return str;
  }
  let strLength = str.length;
  if (strLength <= countOfSymbols) {
    return str;
  }
  return `${str.substr(0, countOfSymbols)}${eos}`;
};

export const getSizeString = (size: number) => {
  var sizeStr = '';
  if (!size || size < 1024) {
    return '0 Kb';
  }
  if (size < 1024 * 1024) {
    sizeStr = (size / 1024).toFixed(2);
    return `${sizeStr} Kb`;
  }
  sizeStr = (size / (1024 * 1024)).toFixed(2);
  return `${sizeStr} MB`;
};

export const isMeaningful = (value: string) => !!(value && value.trim().length > 0);

export const isUUID = (id: string) => {
  const regex = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  );
  return regex.test(id);
};

// temporary for learnServiceUrl courseId`s, delete after migration
export const IdToUuid = (id: string) => {
  if (isUUID(id)) {
    return id;
  }
  return `${id.slice(0, 8)}-${id.slice(8, 12)}-${id.slice(12, 16)}-${id.slice(16, 20)}-${id.slice(
    20,
    32
  )}`;
};

export const getTextContent = (value: string) => {
  const textElement = document.createElement('div');
  textElement.innerHTML = value;
  return textElement.textContent;
};

export const hasNonAscii = (value: string) => [...value].some(char => char.charCodeAt(0) > 127);

export const decodeJson = (value: string) =>
  value && !/[{}]/gm.test(value) ? decodeURIComponent(value) : value;
