import ResponseMapperBase from './ResponseMapperBase';

export default class ChoiceResponseMapperBase extends ResponseMapperBase {
  mapResponse(question: any) {
    if (!this.hasQuestionResponse(question)) {
      return [];
    }

    const result: any[] = [];
    question.answers.forEach((answer: any, index: any) => {
      if (question.response.find((e: any) => e === answer.id)) {
        result.push(index);
      }
    });

    return result;
  }

  /**
   * @param {object} userAnswer
   * @param {object} question
   */
  unMap(userAnswer: any, { answers }: { [key: string]: any }) {
    if (this.isAnsweredCorrectly(userAnswer)) {
      return answers.filter(({ isCorrect }: { [key: string]: any }) => isCorrect).map(({ id }: { [key: string]: any }) => id);
    }

    if (this.isEmptyAnswer(userAnswer)) {
      return [];
    }

    return this._unMapFailedResponse(userAnswer, answers);
  }

  _unMapFailedResponse(userAnswer?: any, answers?: any) {
    throw new Error('Not implemented');
  }
}
