export type SettingsTime = {
  hours: number;
  minutes: number;
  seconds: number;
};

export type TimeLimitFormat = 'hr' | 'min' | 'sec';

const truncate = (x: number) => {
  if (!Math.trunc) {
    return x < 0 ? Math.ceil(x) : Math.floor(x);
  }
  return Math.trunc(x);
};

export const getMillisecondsFromTime = (
  { hours, minutes, seconds }: SettingsTime = { hours: 0, minutes: 0, seconds: 0 }
) => (hours * 60 * 60 + minutes * 60 + seconds) * 1000;

export const getTimeFromMilliseconds = (
  milliSeconds: number,
  format?: TimeLimitFormat
): SettingsTime | number => {
  const totalSeconds = Math.floor(milliSeconds / 1000);
  const totalMinutes = totalSeconds / 60;
  const totalHours = totalMinutes / 60;

  if (milliSeconds < 0) {
    return 0;
  }

  switch (format) {
    case 'sec':
      return totalSeconds;
    case 'min':
      return Math.round(totalMinutes * 10) / 10;
    case 'hr':
      return totalHours;
    default:
      return {
        hours: Math.floor(totalHours % 60),
        minutes: Math.floor(totalMinutes % 60),
        seconds: Math.floor(totalSeconds % 60)
      };
  }
};

export const calculateTimeLeft = (
  timeLimit: number,
  currentTimeElapsed: number,
  previousTimeElapsed: number
) => timeLimit - (currentTimeElapsed + previousTimeElapsed);

export const calculateTimeElapsed = (startedAt: number, updatedAt: number) =>
  truncate((updatedAt - startedAt) / 100) * 100;

export const getElapsedTimeInPercentage = (timeLeft: number, timeLimit: number) =>
  Math.ceil(((timeLimit - timeLeft) / timeLimit) * 100);
