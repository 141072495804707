import * as React from 'react';
import {
  LoaderCircle,
  LoaderStroke,
  LoaderStrokeLeft,
  LoaderStrokeRight
} from './CircleLoader.styled';

export interface CircleLoader {
  iconSize?: number
}

export const CircleLoader = (props: Readonly<CircleLoader>) => {
  const { iconSize } = props;
  return (
    <LoaderCircle iconSize={iconSize}>
      <LoaderStroke iconSize={iconSize}>
        <LoaderStrokeLeft iconSize={iconSize} />
        <LoaderStrokeRight iconSize={iconSize} />
      </LoaderStroke>
    </LoaderCircle>
  );
};


export default CircleLoader;
