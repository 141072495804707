import styled from 'styled-components';
import { Text } from 'components/common';
import media from 'components/mixins/media';

export const Figure = styled.div`
  margin: 200px 0 40px;
  text-align: center;
  ${media.mobile`
    display: none;
  `};
`;

export const TextElement = styled(Text)`
  font-weight: bold;
  color: ${props => props.theme.colors.textColor};
  text-align: center;
  ${media.mobile`
  padding: 80px 35px 30px;
`};
`;

export const ButtonsListItem = styled.li`
  box-sizing: border-box;
  margin-right: 15px;
  &:last-child {
    margin-right: 0;
  }
  ${media.mobile`
    margin-right: 0;
    margin-bottom: 12px;
  `};
`;

export const ButtonsListWrapper = styled.ul`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  margin-top: 30px;
  z-index: 1;
  ${media.mobile`
    flex-flow: column-reverse nowrap;
    align-items: center;
  `};
`;

export const Wrapper = styled.div`
  flex-grow: 1;
`;
