import * as React from 'react';
import { connect } from 'react-redux';
import { localize } from 'core/localization';
import SecretLink from 'assets/figures/SecretLink';
import { Link, StatusTooltip } from 'components/common';
import { BUTTON, LINK, TOOLTIP } from 'constants/components';
import { POPUP_ID_CLOSE_COURSE } from 'constants/popups';
import { getEmail } from 'store/user/selectors';
import progressStorage from 'core/progressStorage';
import { STATUS_OK } from 'constants/progressStorage';
import { RootAppState } from 'store/types';
import CloseCoursePopup from '../closeCourse';
import {
  ButtonElement,
  EmailWrapper,
  DescriptionElement,
  DescriptionWrapper,
  TitleElement,
  Figure,
  ContinueLaterWrapper
} from './ContinueLater.styled';

const constants = {
  emailSeparator: '{email}'
};

type ContinueLaterProps = {
  actions: { [key: string]: any };
  closePopup(): void;
  email: string;
};

type ContinueLaterState = {
  emailSent: boolean;
  emailSentError: boolean;
};

export class ContinueLater extends React.Component<ContinueLaterProps, ContinueLaterState> {
  constructor(props: ContinueLaterProps) {
    super(props);

    this.state = {
      emailSent: false,
      emailSentError: false
    };
  }

  onSendSecretLink = async () => {
    this.setState({ emailSent: false, emailSentError: false });

    const status = await progressStorage.sendSecretLink();
    const emailSent = status === STATUS_OK;

    this.setState({
      emailSent,
      emailSentError: !emailSent
    });
  };

  onCloseCourse = () => {
    this.props.closePopup();
    this.props.actions.openPopup({
      popupId: POPUP_ID_CLOSE_COURSE,
      popupAriaLabelKey: '[aria label close course popup]',
      component: CloseCoursePopup,
      disablePopupClosing: true
    });
  };

  hideMessage = () => {
    this.setState({
      emailSent: false,
      emailSentError: false
    });
  };

  render() {
    return (
      <ContinueLaterWrapper>
        <Figure>
          <SecretLink />
        </Figure>
        <TitleElement appearance="span" size={35}>
          {localize('[continue on this device]')}
        </TitleElement>
        {this.renderDescription()}
        {this.renderSecretLink()}
        <Link
          text={localize('[close course]')}
          layout={LINK.LAYOUT.FORM}
          onClick={this.onCloseCourse}
        />
      </ContinueLaterWrapper>
    );
  }

  renderDescription = () => {
    const { email } = this.props;

    const localizedText = localize('[get secret link to]');
    const descriptionElements = localizedText.split(constants.emailSeparator);
    descriptionElements.splice(1, 0, <EmailWrapper key={Math.random()}>{email}</EmailWrapper>);

    return (
      <DescriptionWrapper appearance="p">
        <DescriptionElement appearance="span" size={14}>
          {descriptionElements}
        </DescriptionElement>
        <DescriptionElement appearance="span" size={14}>
          {localize('[get a secret link to explanation]')}
        </DescriptionElement>
      </DescriptionWrapper>
    );
  };

  renderSecretLink = () => {
    const { emailSent, emailSentError } = this.state;

    const showTooltip = emailSent || emailSentError;
    let tooltipType: any = TOOLTIP.CORRECT;
    let tooltipMessageKey = '[secret link email sent success]';

    if (emailSentError) {
      tooltipType = TOOLTIP.INCORRECT;
      tooltipMessageKey = '[error secret link email sending]';
    }

    return (
      <StatusTooltip
        popoverPosition={'top'}
        layout={tooltipType}
        showTooltip={showTooltip}
        message={localize(tooltipMessageKey)}
        onRequestClose={() => this.hideMessage()}
        popoverButton={
          <ButtonElement
            layout={BUTTON.LAYOUT.PRIMARY}
            onClick={this.onSendSecretLink}
            data-test="send-me-the-link-button"
            tabIndex={0}
          >
            {localize('[send me the link]')}
          </ButtonElement>
        }
      />
    );
  };
}

function mapStateToProps(state: RootAppState) {
  return {
    email: getEmail(state)
  };
}

export default connect(mapStateToProps)(ContinueLater);
