export enum ActionTypes {
    QUESTION_STATEMENT_ANSWER_IS_SELECTED = 'QUESTION_STATEMENT_ANSWER_IS_SELECTED'
}

type AnswerIsSelected = {
    type: ActionTypes.QUESTION_STATEMENT_ANSWER_IS_SELECTED,
    payload: {
        questionId: string,
        answerId: string,
        isCorrect: boolean
    }
}

export type StatementChoiceActionTypes = AnswerIsSelected;
