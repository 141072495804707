export enum ActionTypes {
    FILL_IN_THE_BLANK_ANSWER = 'FILL_IN_THE_BLANK_ANSWER'
}

type QuestionAnswer = {
    type: ActionTypes.FILL_IN_THE_BLANK_ANSWER,
    payload: {
        questionId: string,
        data: any
    }
}

export type FillInTheBlankActionTypes = QuestionAnswer;
