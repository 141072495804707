export type AppState = {
  isLoading: boolean;
  isLoaded: boolean;
  isLoadingFailed: boolean;
  isLowResolution: boolean;
  isOffline: boolean;
};

export enum ActionTypes {
  APP_LOADED = 'APP_LOADED',
  APP_LOADING_FAILED = 'APP_LOADING_FAILED',
  APP_RESOLUTION_CHANGED = 'APP_RESOLUTION_CHANGED',
  APP_OFFLINE_OR_SERVER_ERROR = 'APP_OFFLINE_OR_SERVER_ERROR',
  APP_ONLINE = 'APP_ONLINE'
}

type AppLoadedAction = {
  type: ActionTypes.APP_LOADED;
  payload?: { [key: string]: any };
};

type AppResolutionChangedAction = {
  type: ActionTypes.APP_RESOLUTION_CHANGED;
  payload: {
    isLowResolution: boolean;
  };
};

type AppLoadingFailed = {
  type: ActionTypes.APP_LOADING_FAILED;
  reason: any;
  payload?: { [key: string]: any };
};

type AppOfflineOrServerError = {
  type: ActionTypes.APP_OFFLINE_OR_SERVER_ERROR;
  payload?: {
    isOffline: boolean;
  };
};

type AppOnline = {
  type: ActionTypes.APP_ONLINE;
  payload?: {
    isOffline: boolean;
  };
};

export type AppActionTypes =
  | AppLoadedAction
  | AppResolutionChangedAction
  | AppLoadingFailed
  | AppOfflineOrServerError
  | AppOnline;
