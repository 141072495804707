import { schema } from 'normalizr';
import resourceContents from './resourceContents';

const resource = new schema.Entity(
  'resource',
  {
    resourceContents: [resourceContents]
  },
  {
    processStrategy: value => ({
      ...value
    })
  }
);

export default resource;
