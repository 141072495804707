import ResponseMapperBase from './ResponseMapperBase';

export default class FillInTheBlankResponseMapper extends ResponseMapperBase {
  mapResponse(question: any) {
    if (!this.hasQuestionResponse(question)) {
      return {};
    }

    const result: { [key: string]: any } = {};
    if (question.response.data) {
      Object.keys(question.response.data).forEach(id => {
        const index = question.answerGroups.findIndex((e: any) => e.id === id);
        result[index] = question.response.data[id];
      });
    }
    return result;
  }

  /**
   * @param {object} userAnswer
   * @param {object} question
   */
  unMap(userAnswer: any, { answerGroups }: any) {
    const data: { [key: string]: any } = {};
    if (this.isAnsweredCorrectly(userAnswer)) {
      answerGroups.forEach(({ id, answers }: any) => {
        const answerItem = answers.find(({ isCorrect }: any) => isCorrect);
        data[id] = answerItem.text;
      });
      return { data };
    }

    let questionAnswer = this.isEmptyAnswer(userAnswer) ? {} : userAnswer;
    answerGroups.forEach(({ id }: any, index: any) => {
      const itemAnswer = questionAnswer[index.toString()];
      data[id] = itemAnswer == null ? '' : itemAnswer;
    });

    return { data };
  }
}
