export const INFORMATION_CONTENT = 'informationContent';
export const SINGLE_CHOICE = 'singleSelectText';
export const MULTIPLE_CHOICE = 'multipleSelect';
export const STATEMENT_CHOICE = 'statement';
export const SINGLE_CHOICE_IMAGE = 'singleSelectImage';
export const OPEN_QUESTION = 'openQuestion';
export const RANKING_TEXT = 'rankingText';
export const SCENARIO = 'scenario';
export const FILL_IN_THE_BLANK = 'fillInTheBlank';
export const DRAG_AND_DROP_TEXT = 'dragAndDropText';
export const TEXT_MATCHING = 'textMatching';
export const HOTSPOT = 'hotspot';

export default [
  INFORMATION_CONTENT,
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  STATEMENT_CHOICE,
  SINGLE_CHOICE_IMAGE,
  OPEN_QUESTION,
  RANKING_TEXT,
  SCENARIO,
  FILL_IN_THE_BLANK,
  DRAG_AND_DROP_TEXT,
  TEXT_MATCHING,
  HOTSPOT
];
