import * as React from 'react';
import { ListCounter, ListCounterItem } from './NumericList.styled';

type NumericListProps = {
  items: any[];
}
export class NumericList extends React.Component<NumericListProps, {}> {
  render() {
    const { items } = this.props;
    return (
      <ListCounter>
        {items.map((item: any, index: any) => (
          <ListCounterItem key={index} lineHeight={item}>
            {index + 1}
          </ListCounterItem>
        ))}
      </ListCounter>
    );
  }
}

export default NumericList;
