class UrlMapper {
  unMap(url: string) {
    switch (url) {
      case 'finish':
        return 'results';
      case 'sections':
        return 'sections';
      default:
        return url
          .replace('signin', 'login')
          .replace(/section\//, 'sections/')
          .replace(/question\//, 'questions/');
    }
  }
}

export default new UrlMapper();
