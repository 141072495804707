import styled from 'styled-components';
import ReactContentLoader from 'react-content-loader';

export const Container = styled.div`
  max-width: 400px;
  width: 100%;
`;

export const ContentLoader = styled(ReactContentLoader)`
  display: flex;
  opacity: 0.3;
`;
