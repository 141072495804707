import requester from '../http/requester';
import progressStorage from '../progressStorage';

type RequestOptions = {
  credentials: string;
  data: { [key: string]: any };
  headers: { [key: string]: any };
  retryOptions: { [key: string]: any };
  method: string;
}

class Lti {
  callbackUrl: any;
  initialize(callbackUrl: any) {
    this.callbackUrl = callbackUrl;
  }

  submitResultToLti = async (score = 0) => {
    const requestOptions: RequestOptions = {
      credentials: 'include',
      data: {
        score: score / 100
      },
      headers: {
        'Content-Type': 'application/json; charset=utf-8'
      },
      method: 'POST',
      retryOptions: {}
    };

    const token = progressStorage.getToken();
    if (token) {
      requestOptions.headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json; charset=utf-8'
      };
    }

    return requester.post(this.callbackUrl, requestOptions);
  };
}

export default new Lti();
