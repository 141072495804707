import { isAuthorized } from 'store/user/selectors';
import { isResultsTrackingEnabled, isNpsEnabled } from 'store/settings/selectors';
import { RootAppState } from '../types';

// eslint-disable-next-line import/prefer-default-export
export const isXapiInitialized = (state: RootAppState) => state.modules.xapi.isInitialized;

export const shouldInitializeXapi = (state: RootAppState) =>
  isAuthorized(state) && (isResultsTrackingEnabled(state) || isNpsEnabled(state));

export const isReviewEnabled = (state: RootAppState) => state.modules.review.isReviewModeEnabled;

export const getReviewApiUrl = (state: RootAppState) => state.modules.review.reviewApiUrl;

export const isLtiInitialized = (state: RootAppState) => state.modules.lti.isInitialized;
