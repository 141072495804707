import initialState from 'store/initialState';
import { TimerStatus } from 'constants/timer';
import { ActionTypes, TimerActionTypes } from './types';

export default function timerReducer(state = initialState.timer, action: TimerActionTypes) {
  switch (action.type) {
    case ActionTypes.START_TIMER: {
      return {
        ...state,
        status: TimerStatus.STARTED
      };
    }
    case ActionTypes.SET_TIMER_STATUS: {
      const { status } = action.payload;
      return {
        ...state,
        status
      };
    }
    case ActionTypes.SET_TIMER_ELAPSED: {
      const { elapsed } = action.payload;
      return {
        ...state,
        elapsed
      };
    }
    case ActionTypes.RESET_TIMER:
      return {
        ...state,
        status: TimerStatus.RESET,
        elapsed: 0,
        startedAt: 0,
        updatedAt: 0
      };
    default:
      return state;
  }
}
