import { schema } from 'normalizr';

function getAnswerId(answerList: any, explanationBlockId: any) {
  const answer = answerList.find(
    (item: any) => item.explanationBlocks.findIndex((block: any) => block.id === explanationBlockId) >= 0
  );
  return answer.id;
}

const explanationBlock = new schema.Entity(
  'contents',
  {},
  {
    processStrategy: (value: any, parent: any, key: string) => {
      let answerId;
      const result = {
        ...value,
        value: undefined
      };
      switch (key) {
        case 'explanationBlocks':
          answerId = getAnswerId(parent.answers, value.id);
          result.url = `${parent.sectionId}/${parent.id}/${answerId}/${value.id}.html`;
          result.sectionId = parent.sectionId;
          result.questionId = parent.id;
          result.answerId = answerId;
          break;
        case 'children':
          result.url = `${parent.sectionId}/${parent.questionId}/${parent.answerId}/${value.id}.html`;
          break;
        default:
          throw new Error('Content entity key is not supported');
      }
      return result;
    }
  }
);

explanationBlock.define({
  children: [explanationBlock]
});

export default explanationBlock;
