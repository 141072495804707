import initialState from 'store/initialState';
import { ActionTypes, ModulesActionTypes} from './types';

export default function modulesReducer(state = initialState.modules, action: ModulesActionTypes) {
  switch (action.type) {
    case ActionTypes.MODULE_XAPI_INITIALIZED:
      return {
        ...state,
        xapi: { ...state.xapi, isInitialized: true }
      };
    case ActionTypes.MODULE_XAPI_LRS_ON:
      return {
        ...state,
        xapi: { ...state.xapi, isLrsTrackingOn: true }
      };
    case ActionTypes.MODULE_XAPI_LRS_OFF:
      return {
        ...state,
        xapi: { ...state.xapi, isLrsTrackingOn: false }
      };
    case ActionTypes.MODULE_XAPI_NPS_ON:
      return {
        ...state,
        xapi: { ...state.xapi, isNpsTrackingOn: true }
      };
    case ActionTypes.MODULE_XAPI_NPS_OFF:
      return {
        ...state,
        xapi: { ...state.xapi, isNpsTrackingOn: false }
      };
    case ActionTypes.MODULE_SCORM_INITIALIZED:
      return {
        ...state,
        scorm: { ...state.scorm, isInitialized: true }
      };
    case ActionTypes.MODULE_PROGRESS_STORAGE_ON:
      return {
        ...state,
        progressStorage: { ...state.progressStorage, isProgressStorageOn: true }
      };
    case ActionTypes.MODULE_PROGRESS_STORAGE_OFF:
      return {
        ...state,
        progressStorage: { ...state.progressStorage, isProgressStorageOn: false }
      };
    case ActionTypes.ENABLE_REVIEW_MODE:
      return {
        ...state,
        review: {
          ...{
            ...state.review,
            isReviewModeEnabled: true,
            reviewApiUrl: action.payload.reviewApiUrl
          }
        }
      };
    case ActionTypes.MODULE_LTI_INITIALIZED:
      return {
        ...state,
        lti: { isInitialized: true }
      };
    default:
      return state;
  }
}
