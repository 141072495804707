import StatementChoice from './StatementChoice';

export default class TextMatchingResponseMapper extends StatementChoice {
  map(response: any) {
    return super.map(response);
  }

  unMap(response: any) {
    return super.unMap(response);
  }
}
