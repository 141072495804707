import eventEmitter, { events } from 'core/events/eventEmitter';
import { TimerStatus } from 'constants/timer';
import { RootAppState } from 'store/types';
import { ActionTypes } from './types';
import { ThunkResult } from '../types';
import { getTimerElapsed, getTimerStatus } from './selectors';

const emitTimerProgress = async (state: RootAppState) => {
  await eventEmitter.emit(events.COURSE_TIMER_PROGRESSED, {
    ...state,
    timer: {
      status: getTimerStatus(state),
      elapsed: getTimerElapsed(state)
    }
  });
};

export const startTimer = (): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.START_TIMER
  });
};

export const setTimerStatus = (status: TimerStatus): ThunkResult => dispatch => {
  dispatch({
    type: ActionTypes.SET_TIMER_STATUS,
    payload: {
      status
    }
  });
};

export const setTimerElapsed = (elapsed: number): ThunkResult => async dispatch => {
  dispatch({
    type: ActionTypes.SET_TIMER_ELAPSED,
    payload: {
      elapsed
    }
  });
};

export const resetTimer = (): ThunkResult => async dispatch => {
  dispatch({
    type: ActionTypes.RESET_TIMER
  });
  await eventEmitter.emit(events.RESET_TIMER_PROGRESS);
};

export const updateTimerProgress = (): ThunkResult<Promise<void>> => async (dispatch, getState) => {
  await emitTimerProgress(getState());
};
