import { normalize } from 'normalizr';
import resourceSchema from '../schemas/resource/resource';

export default {
  map: (data: any) => {
    const normalized: any = normalize(data, resourceSchema);
    const resource: any = normalized.entities.resource[normalized.result];
    const { resourceContents } = normalized.entities;
    return {
      resource,
      resourceContents
    };
  }
};
