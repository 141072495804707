class Color {
  r: number;
  g: number;
  b: number;
  a: number | undefined;

  constructor(r: number, g: number, b: number, a?: number) {
    this.r = r;
    this.g = g;
    this.b = b;
    this.a = a;
  }

  toString() {
    return this.a !== undefined
      ? `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`
      : `rgb(${this.r}, ${this.g}, ${this.b})`;
  }
}

/* eslint-disable import/prefer-default-export */
export const transparentize = (hex: string, opacity?: number, options: { [key: string]: any } = { solidColor: false }): string => {
  let color = hex2rgba(hex, opacity);
  if (!color) {
    return '';
  }
  if (options.solidColor) {
    const white = new Color(255, 255, 255);
    color = rgba2rgb(white, color);
  }

  return color.toString();
};

function hex2rgba(hexColor: string, alpha = 1) {
  // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = hexColor.replace(shorthandRegex, (m, r, g, b) => r + r + g + g + b + b);

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  if (!result) {
    return null;
  }
  return new Color(
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
    alpha
  );
}

function rgba2rgb(rgbBackground: any, rgbaColor: any) {
  const alpha = rgbaColor.a;

  return new Color(
    parseInt(((1 - alpha) * rgbBackground.r + alpha * rgbaColor.r).toString(), 10),
    parseInt(((1 - alpha) * rgbBackground.g + alpha * rgbaColor.g).toString(), 10),
    parseInt(((1 - alpha) * rgbBackground.b + alpha * rgbaColor.b).toString(), 10)
  );
}
