import contentLoader from 'core/http/contentLoader';
import { ActionTypes, ContentState } from './types';
import { ThunkResult } from '../types';

export const contentsLoaded = (contents: any): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.CONTENTS_LOADED, payload: contents });
  loadAll(contents, dispatch); // TEMPORARY SOLUTION
};

const loadContent = async (content: ContentState, dispatch: any) => {
  if (content.value) {
    return;
  }
  try {
    const value = await contentLoader.loadStaticHtmlContent(content.url);
    dispatch({ type: ActionTypes.CONTENT_LOADED, payload: { contentId: content.id, value } });
  } catch (e) {
    dispatch({ type: ActionTypes.CONTENT_LOADING_FAILED, payload: { contentId: content.id } });
  }
};

export const loadContents = (contents: any): ThunkResult => async dispatch => {
  const promises: any[] = [];
  contents.forEach((content: ContentState) => {
    promises.push(loadContent(content, dispatch));
  });

  return Promise.all(promises);
};

async function loadAll(contents: any, dispatch: any) {
  const promises = [];
  for (const content in contents) {
    promises.push(loadContent(contents[content], dispatch));
  }
  return Promise.all(promises);
}
