import { ActionTypes, OpenQuestionActionTypes } from './types';
import { QuestionsState } from '../types';

export default function reducer(state: QuestionsState, action: OpenQuestionActionTypes) {
  switch (action.type) {
    case ActionTypes.OPEN_QUESTION_ANSWER_IS_SELECTED:
      return {
        id: action.payload.questionId,
        answeredText: action.payload.answeredText
      };
    default:
      return state;
  }
}
