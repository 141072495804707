import getStringHash from 'utils/getStringHash';

export const sortById = (prev: any, next: any) => {
  if (getStringHash(prev.id) > getStringHash(next.id)) {
    return -1;
  }
  return 1;
};

export default sortById;
