import styled from 'styled-components';

export const OverLayContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: white;
  z-index: 200;
`;
