export enum TimerStatus {
  NOT_STARTED = 'NOT_STARTED',
  STARTED = 'STARTED',
  STOPPED = 'STOPPED',
  TIMED_OUT = 'TIMED_OUT',
  RESET = 'RESET'
}
export const COURSE_TIMER_PROGRESS_KEY = 'course_timer_progress';
export const PROGRESS_STORAGE_TIMER_UPDATE_INTERVAL = 60000;
export const APP_STORE_TIMER_UPDATE_INTERVAL = 5000;
