import styled from 'styled-components';
import { Text, Button } from 'components/common';
import { transparentize } from 'utils/color';

export const TitleElement = styled(Text)`
  font-weight: 300;
  line-height: 1.43;
  text-align: center;
  margin-bottom: 48px;
  color: ${props => props.theme.colors.textColor};
`;

export const ButtonElement = styled(Button)`
  background-color: ${props => props.theme.colors.mainColor};
  margin: 48px 0 32px 0;
  &:hover,
  &:focus {
    background-color: ${props => transparentize(props.theme.colors.mainColor, 0.9)};
  }
`;

export const ContinueLaterWrapper = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  z-index: 1;
`;
