import styled from 'styled-components';
import media from 'components/mixins/media';
import { transparentize } from 'utils/color';
import { getCustomScrollbars } from 'components/mixins/customScrollbars';

export const EditableContentWrapper = styled.div`
  overflow-y: auto;
  max-height: 300px;
  padding-top: 27px;
  padding-bottom: 60px;
  min-height: 120px;
  ${getCustomScrollbars()};
  
  textarea {
    width: 100%;
    height: 160px;
    background: transparent;
    border: none;
    box-sizing: border-box;
    outline: none;
    font-size: 16px;
    line-height: 39px;
    font-weight: bold;
    color: ${props => props.theme.colors.textColor};
    overflow: hidden;
    resize: none;

    background-image: linear-gradient(
      transparent,
      transparent 38px,
      ${props => transparentize(props.theme.colors.textColor, 0.1)} 37px
    );
    background-size: 30px 39px;
    background-attachment: local;

    &:disabled {
      opacity: 0.5;
    }

    &::placeholder {
      color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
    }

    ${media.mobile`
      padding: 0 60px 0 70px;
  `};
  }
`;

export default EditableContentWrapper;
