import {
  SINGLE_CHOICE,
  MULTIPLE_CHOICE,
  STATEMENT_CHOICE,
  SINGLE_CHOICE_IMAGE,
  RANKING_TEXT,
  DRAG_AND_DROP_TEXT,
  TEXT_MATCHING
} from 'constants/questionTypes';
import shuffle from 'lodash.shuffle';
import { objectToArray, arrayToObject } from 'utils/object';
import shuffleAnswerOptions from 'utils/questions';

const OPTION_ID_LENGTH = 6;
const OPTION_MAP_ORDER_KEY = 'id';

const truncate = (id: string, maxLength: number) =>
  id.length > maxLength ? id.slice(0, maxLength) : id;

const shortenOptionIds = (options: any[]) =>
  options.map((option: { id: string }) => truncate(option.id, OPTION_ID_LENGTH));

const mapOrder = (array: any[] = [], order: any[] = []) =>
  [...array].sort((a, b) =>
    order.indexOf(truncate(a[OPTION_MAP_ORDER_KEY], OPTION_ID_LENGTH)) >
    order.indexOf(truncate(b[OPTION_MAP_ORDER_KEY], OPTION_ID_LENGTH))
      ? 1
      : -1
  );

export const mapRandomizedOptionsOrder = (
  questions: any = {},
  randomizedOptionsArray: any[][] = []
) =>
  objectToArray(questions).map((question, index) => {
    let questionWithRandomizedOptions;
    switch (question.type) {
      case DRAG_AND_DROP_TEXT:
        questionWithRandomizedOptions = {
          ...question,
          ...{ randomizedOptions: mapOrder(question.dropspots, randomizedOptionsArray[index]) }
        };
        break;
      case SINGLE_CHOICE:
      case MULTIPLE_CHOICE:
      case STATEMENT_CHOICE:
      case SINGLE_CHOICE_IMAGE:
      case TEXT_MATCHING:
      case RANKING_TEXT:
        questionWithRandomizedOptions = {
          ...question,
          ...{ randomizedOptions: mapOrder(question.answers, randomizedOptionsArray[index]) }
        };
        break;
      default:
        questionWithRandomizedOptions = { ...question };
    }
    return questionWithRandomizedOptions;
  });

export const buildRandomizedOptions = (questions: any) =>
  objectToArray(questions).reduce((randomizedOptionsArray, question) => {
    switch (question.type) {
      case DRAG_AND_DROP_TEXT:
        randomizedOptionsArray.push(shuffle(shortenOptionIds([...question.dropspots])));
        break;
      case SINGLE_CHOICE:
      case MULTIPLE_CHOICE:
      case STATEMENT_CHOICE:
      case SINGLE_CHOICE_IMAGE:
      case TEXT_MATCHING:
        randomizedOptionsArray.push(shuffle(shortenOptionIds([...question.answers])));
        break;
      case RANKING_TEXT:
        randomizedOptionsArray.push(shuffleAnswerOptions(shortenOptionIds([...question.answers])));
        break;
      default:
        randomizedOptionsArray.push([]);
    }
    return randomizedOptionsArray;
  }, []);

export const buildQuestionWithRandomizedOptions = (
  questions: any = {},
  randomizedOptionsArray: any[][] = []
) =>
  arrayToObject(
    mapRandomizedOptionsOrder(questions, randomizedOptionsArray).map(question => [
      question.id,
      { ...question }
    ])
  );
