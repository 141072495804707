import * as React from 'react';
import scrollIntoView from 'scroll-into-view';
import { Container, Content } from './Collapse.styled';

export type CollapseProps = {
  isExpanded: boolean,
  scrollIntoView: boolean,
  duration: number,
  opacityDuration: string,
  delayOpacityTransition?: boolean,
  children: React.ReactNode,
  onExpanded?(): void,
  onCollapsed?(): void
  animateAppear?: boolean,
}

export class Collapse extends React.PureComponent<CollapseProps, {}> {
  isExpanded: boolean;
  containerNode: any;
  contentNode: any;
  timeout: any;
  raf: any;

  constructor(props: CollapseProps) {
    super(props);
    this.isExpanded = false;
    this.containerNode = React.createRef();
    this.contentNode = React.createRef();
  }

  static defaultProps = {
    isExpanded: true,
    animateAppear: true,
    scrollIntoView: false,
    duration: 300,
    opacityDuration: 100,
    delayOpacityTransition: false
  }

  componentDidMount() {
    if (this.props.animateAppear) {
      this.updateWithAnimation();
    } else {
      this.update();
    }
    this.isExpanded = this.props.isExpanded;
  }

  componentDidUpdate() {
    this.updateWithAnimation();
    this.isExpanded = this.props.isExpanded;
  }

  componentWillUnmount() {
    this.clear();
  }

  render() {
    const { isExpanded, children, opacityDuration, duration } = this.props;
    return (
      <Container
        isVisible={isExpanded}
        duration={duration}
        ref={this.containerNode}
      >
        {
          <Content
            defaultOpacity={this.getDefaultOpacity()}
            opacityDuration={opacityDuration}
            ref={this.contentNode}
          >
            {children}
          </Content>
        }
      </Container>
    );
  }

  update() {
    const { isExpanded } = this.props;
    this.setHeight(isExpanded ? 'auto' : 0);
    this.setOpacity(isExpanded ? 1 : 0);
  }

  updateWithAnimation() {
    const { isExpanded, duration, delayOpacityTransition } = this.props;
    if (this.isExpanded === isExpanded) {
      return;
    }

    this.clear();
    if (!isExpanded) {
      this.fadeOut();
      this.collapse();
      this.call(this.props.onCollapsed);
      return;
    }

    this.expand();
    if (this.props.scrollIntoView) {
      scrollIntoView(this.containerNode, { time: this.props.duration, align: { top: 0 } });
    }

    if (!delayOpacityTransition) {
      this.fadeIn();
    }
    this.timeout = setTimeout(() => {
      this.fadeIn();
      this.setHeight('auto');
      this.call(this.props.onExpanded);
    }, duration);
  }

  call(func: any) {
    if (func) {
      func();
    }
  }

  clear() {
    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = null;
    }
    if (this.raf) {
      window.cancelAnimationFrame(this.raf);
      this.raf = null;
    }
  }

  expand() {
    this.animateHeight(true);
  }

  collapse() {
    this.animateHeight(false);
  }

  animateHeight(isExpanded: boolean) {
    if (this.raf) {
      window.cancelAnimationFrame(this.raf);
    }

    if (!isExpanded && this.isHeightAuto()) {
      this.setHeight(this.getContentHeight());
    }

    this.raf = window.requestAnimationFrame(() => {
      const height = isExpanded ? this.getContentHeight() : 0;
      this.raf = window.requestAnimationFrame(() => {
        this.raf = window.requestAnimationFrame(() => {
          this.setHeight(height);
          this.raf = null;
        });
      });
    });
  }

  fadeIn() {
    this.setOpacity(1);
  }

  fadeOut() {
    this.setOpacity(this.getDefaultOpacity());
  }

  getContentHeight() {
    return this.contentNode.current && this.contentNode.current.offsetHeight;
  }

  setHeight(height: number | string) {
    if (this.containerNode.current) {
      this.containerNode.current.style.height = typeof height === 'string' ? height : `${height}px`;
    }
  }

  isHeightAuto() {
    return this.containerNode.current && this.containerNode.current.style.height === 'auto';
  }

  setOpacity(opacity: number) {
    if (this.contentNode.current) {
      this.contentNode.current.style.opacity = opacity;
    }
  }

  getDefaultOpacity() {
    return this.props.delayOpacityTransition ? 0.3 : 0;
  }
}

export default Collapse;
