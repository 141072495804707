import styled from 'styled-components';
import media from 'components/mixins/media';

type LogoProps = {
  altText: string;
  logoUrl: string;
}
export const Logo = styled.img.attrs((props: LogoProps) => ({
  alt: props.altText,
  src: props.logoUrl
})) <LogoProps>`
  display: block;
  margin-top: 24px;
  margin-bottom: 35px;
  max-width: 200px;
  max-height: 80px;

  ${media.mobile`
    max-width: 160px;
    max-height: 60px;
    margin-left: 10px;
  `};
`;

export default Logo;
