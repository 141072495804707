import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { INDEX_PATH, RESULTS_PATH } from 'constants/navigation';
import { TRANSITION_TIME_DURATION_MS } from 'constants/common';
import { getChain, getPrevUrl } from 'store/navigation/selectors';
import * as actions from 'store/navigation/actions';
import { RootAppState } from 'store/types';
import scrollIntoView from 'scroll-into-view';

type NavigationProps = {
  chain: any[];
  prevUrl: string;
  actions: { [key: string]: any };
  history: { [key: string]: any };
  match: { [key: string]: any };
  navigateToUrl?: any;
  checkHasPrevPage?: any;
  navigateToPrevPage?: any;
  navigateToNextPage?: any;
  navigateToIndex?: any;
  checkIsNextPageResults?: any;
  scrollToId: any;
};

export function withNavigation<T>(WrappedComponent: React.ComponentType<T>): any {
  class Navigation extends React.Component<T & NavigationProps, any> {
    getNextPageUrl = () => {
      const { chain, match } = this.props;
      const { url } = match;
      let currentUrlIndex = chain.indexOf(url);
      if (currentUrlIndex === chain.length - 1) {
        return INDEX_PATH;
      }

      return chain[++currentUrlIndex];
    };

    checkHasPrevPage = () => this.props.prevUrl;

    navigateToPrevPage = () => {
      this.navigateToUrl(this.props.prevUrl);
    };

    navigateToNextPage = () => {
      this.navigateToUrl(this.getNextPageUrl());
    };

    navigateToIndex = () => {
      this.navigateToUrl(INDEX_PATH);
    };

    navigateToUrl = async (url: string, isReplace = false) => {
      if (this.props.history.location.pathname === url) {
        return;
      }

      await this.props.actions.navigatedToUrl(this.props.history.location.pathname, url);
      if (isReplace) {
        this.props.history.replace(url);
      } else {
        this.props.history.push(url);
      }
    };

    checkIsNextPageResults = () => this.getNextPageUrl() === RESULTS_PATH;
    checkIsPreviousPageResults = () => this.props.prevUrl === RESULTS_PATH;

    updateElementIdInViewPort = async (id: string) => {
      await this.props.actions.updateElementIdInViewPort(id);
    };

    scrollToId = async (id: string, offset?: number) => {
      if (id === undefined) {
        await this.navigateToUrl(RESULTS_PATH);
      }
      await this.updateElementIdInViewPort(id);
      await this.scrollIntoViewElement(id, offset);
    };

    scrollIntoViewElement = async (elementId: any, offset = 100) => {
      const scrollElement: HTMLElement | null = document.getElementById(elementId);

      if (scrollElement) {
        scrollIntoView(scrollElement, {
          time: TRANSITION_TIME_DURATION_MS,
          align: { top: 0, topOffset: offset }
        });
      }
    };

    render() {
      const combinedProps = {
        ...this.props,
        navigateToUrl: this.navigateToUrl,
        checkHasPrevPage: this.checkHasPrevPage,
        navigateToPrevPage: this.navigateToPrevPage,
        navigateToNextPage: this.navigateToNextPage,
        navigateToIndex: this.navigateToIndex,
        checkIsNextPageResults: this.checkIsNextPageResults,
        checkIsPreviousPageResults: this.checkIsPreviousPageResults,
        scrollToId: this.scrollToId,
        updateElementIdInViewPort: this.updateElementIdInViewPort
      };
      return <WrappedComponent {...combinedProps} />;
    }
  }

  function mapStateToProps(state: RootAppState) {
    return {
      state,
      chain: getChain(state),
      prevUrl: getPrevUrl(state)
    };
  }

  function mapDispatchToProps(dispatch: Dispatch) {
    return { actions: bindActionCreators(actions, dispatch) };
  }

  return connect(mapStateToProps, mapDispatchToProps)(Navigation as any);
}

export default withNavigation;
