import * as React from 'react';
import { connect } from 'react-redux';
import { isReviewEnabled } from 'store/modules/selectors';
import { Wrapper } from './Reviewable.styled';
import { RootAppState } from 'store/types';

type ReviewableProps = {
  isGeneral?: boolean;
  context?: { [key: string]: any };
  reviewMode?: boolean;
  isVoiceOver?: boolean;
  children?: any;
  ReviewSpot?(e?: any): void;
  hintName?: string;
  isCourseOnOnePage?: boolean;
};

type ReviewableState = {
  ReviewSpot: any;
};

export class Reviewable extends React.Component<ReviewableProps, ReviewableState> {
  constructor(props: ReviewableProps) {
    super(props);
    this.state = { ReviewSpot: '' };
  }

  renderSpot = async () => {
    const { default: ReviewSpot } = await import('components/review/ReviewSpot');
    this.setState({ ReviewSpot });
  };

  componentDidMount() {
    if (!this.props.reviewMode) {
      return;
    }
    this.renderSpot();
  }

  render() {
    const {
      reviewMode,
      isGeneral,
      context,
      children,
      hintName,
      isVoiceOver = false,
      isCourseOnOnePage = false
    } = this.props;
    const { ReviewSpot } = this.state;
    return (
      <Wrapper
        isVoiceOver={isVoiceOver}
        isCourseOnOnePage={isCourseOnOnePage}
        className="reviewable-container"
      >
        {children}
        {reviewMode && ReviewSpot && (
          <ReviewSpot
            isVoiceOver={isVoiceOver}
            hintName={hintName}
            isGeneral={isGeneral}
            context={context}
          />
        )}
      </Wrapper>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    reviewMode: isReviewEnabled(state)
  };
}

export default connect(mapStateToProps)(Reviewable);
