import initialState from 'store/initialState';
import settingsMapper from 'core/mappers/settings';
import { ActionTypes, SettingsActionTypes } from './types';

export default function settingsReducer(
  state = initialState.settings,
  action: SettingsActionTypes
) {
  switch (action.type) {
    case ActionTypes.SETTINGS_LOADED: {
      return { ...state, ...settingsMapper.map(action.settings) };
    }
    case ActionTypes.SETTINGS_LOADING_FAILED:
      return {
        ...state,
        ...{
          isLoadingFailed: true
        }
      };
    default:
      return state;
  }
}
