import * as React from 'react';
import AnalogTimer from 'components/timer/Analog';
import DigitalTimer from 'components/timer/Digital';
import { getElapsedTimeInPercentage } from 'utils/timer';
import { PauseIcon } from './Timer.styled';
import { TimerBlockProps } from './types';

export const TimerBlock: React.FunctionComponent<TimerBlockProps> = ({
  isTimerStopped,
  isLowResolution,
  showDigitalTimer,
  timeLeft,
  timeLimit,
  onClick
}: TimerBlockProps) => (
  <>
    {isTimerStopped && <PauseIcon size={10} name="pause" />}
    {!isLowResolution || showDigitalTimer ? (
      <DigitalTimer isTimerStopped={isTimerStopped} timeLeft={timeLeft} />
    ) : (
      <AnalogTimer isTimerStopped={isTimerStopped}
        timeElapsed={getElapsedTimeInPercentage(timeLeft, timeLimit)}
        onClick={onClick}
      />
    )}
  </>
);

export default TimerBlock;
