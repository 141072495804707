import EventHandler from './eventHandler';

class Lti {
  eventHandler: EventHandler;
  constructor() {
    this.eventHandler = new EventHandler();
  }

  subscribe(callbackUrl: any) {
    this.eventHandler.on();
    this.eventHandler.initialize(callbackUrl);
  }

  unsubscribe() {
    this.eventHandler.off();
  }
}

export default new Lti();
