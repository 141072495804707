import styled from 'styled-components';
import Text from 'components/common/Text';
import Icon from 'components/common/Icon';
import { transparentize } from 'utils/color';
import { TOOLTIP } from 'constants/components';
import { secondaryFont } from 'components/mixins/typography';
import media from 'components/mixins/media';
import { LayoutType } from './StatusTooltip';

export const IconElement = styled(Icon)`
  ${secondaryFont};
  position: absolute;
  display: inline;
  top: 50%;
  left: 16px;
  transform: translateY(-50%);
  font-weight: bold;
  line-height: 1.5px;
`;

export const MessageContainer = styled(Text)`
  && {
    color: ${props => props.theme.colors.textColor};
    font-size: 10px;
    font-weight: normal;
    line-height: 1.5;
    white-space: pre-line;
    word-break: break-word;
    word-wrap: break-word;
  }
`;

type ContainerMessageProps = {
  layout: keyof LayoutType
}
export const ContainerMessage = styled.div<ContainerMessageProps>`
  position: relative;
  display: block;
  width: 320px;
  padding: 10px 32px 10px 37px;
  background: ${props =>
    transparentize(
      (props.layout === TOOLTIP.INCORRECT && props.theme.colors.incorrectColor) ||
      (props.layout === TOOLTIP.CORRECT && props.theme.colors.correctColor),
      0.1
    )};
  border: 1px solid
    ${props =>
    transparentize(
      (props.layout === TOOLTIP.INCORRECT && props.theme.colors.incorrectColor) ||
      (props.layout === TOOLTIP.CORRECT && props.theme.colors.correctColor),
      0.3
    )};
  ${media.mobile`
      width: 260px;
    `};
`;

export const PopoverButton = styled.div`
  display: inline-block;
`;

export const PopoverWrapper = styled.div`
  display: block;
`;
