import { getQuestion } from 'store/questions/selectors';
import { isRandomizeAnswersEnabled } from 'store/settings/selectors';
import { NO_SELECTED_ID } from 'constants/common';
import { sortById } from 'utils/sort';
import { RootAppState } from '../../types';

export const getUserAnswers = (state: RootAppState, questionId: string) => {
  const question = getQuestion(state, questionId);
  const randomizeAnswersEnabled = isRandomizeAnswersEnabled(state);
  const { dropspots: options, randomizedOptions = [] } = question;
  const dropspots = randomizeAnswersEnabled ? randomizedOptions : options;
  return dropspots.map((dropspot: any) => ({
    id: dropspot.id,
    text: dropspot.text,
    x: dropspot.x,
    y: dropspot.y
  }));
};

export const getBackground = (state: RootAppState, questionId: string) => getQuestion(state, questionId).background;

export const getUserResponse = (state: RootAppState, questionId: string) => getQuestion(state, questionId).response;

export const getScore = (state: RootAppState, question: any) => {
  const userAnswers: { [key: string]: any }[] = [];
  const originalAnswers = question.dropspots.map((answer: any) => ({
    id: answer.id,
    text: answer.text
  }));

  if (question.response.length && !question.response[0].items.length) {
    question.response.forEach((list: any) => {
      if (list.id !== NO_SELECTED_ID) {
        const answerId = list.id;
        const answerText = list.items[0].text;
        userAnswers.push({
          id: answerId,
          text: answerText
        });
      }
    });
  }

  originalAnswers.sort(sortById);
  userAnswers.sort(sortById);

  if (JSON.stringify(originalAnswers) === JSON.stringify(userAnswers)) {
    return 100;
  }

  return 0;
};
