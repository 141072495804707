import styled from 'styled-components';
import { shake } from 'components/mixins/interactivity';

type TooltipContentProps = {
  templateTheme: any;
};
export const TooltipContentWrapper = styled.div.attrs({
  'data-test': 'tooltip-wrapper'
})<TooltipContentProps>`
  padding: 5px 7px 7px;
  background: ${(props: any) => props.templateTheme.colors.contentBodyColor};
  outline: none;

  &.dropdown-theme,
  &.status-theme {
    padding: 0;
  }
  .dropdown-theme & {
    pointer-events: all;
  }

  .tippy-popper[x-placement^='bottom'] .hint-popover-theme &,
  .tippy-popper[x-placement^='top'] .hint-popover-theme & {
    ${shake('vertical')}
  }

  .tippy-popper[x-placement^='left'] .hint-popover-theme &,
  .tippy-popper[x-placement^='right'] .hint-popover-theme & {
    ${shake('horizontal')}
  }
`;

export const TooltipContentArrow = styled.div.attrs({
  'x-arrow': 'true'
})<TooltipContentProps>`
  .tippy-popper[x-placement^='top'] .tippy-tooltip ${TooltipContentWrapper} &[x-arrow] {
    border-top-color: ${(props: any) => props.templateTheme.colors.contentBodyColor};
  }

  .tippy-popper[x-placement^='bottom'] .tippy-tooltip ${TooltipContentWrapper} &[x-arrow] {
    border-bottom-color: ${(props: any) => props.templateTheme.colors.contentBodyColor};
  }

  .tippy-popper[x-placement^='left'] .tippy-tooltip ${TooltipContentWrapper} &[x-arrow] {
    border-left-color: ${(props: any) => props.templateTheme.colors.contentBodyColor};
  }

  .tippy-popper[x-placement^='right'] .tippy-tooltip ${TooltipContentWrapper} &[x-arrow] {
    border-right-color: ${(props: any) => props.templateTheme.colors.contentBodyColor};
  }
`;
