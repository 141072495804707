import styled from 'styled-components';
import { Text, Icon } from 'components/common';
import { transparentize } from 'utils/color';
import { secondaryFontText } from 'components/mixins/typography';
import media from 'components/mixins/media';

export const Wrapper = styled.article`
  display: flex;
  max-width: 368px;
  padding: 36px 24px 32px;
  background-color: ${props => transparentize(props.theme.colors.mainColor, 0.05)};
  border-top: 4px solid ${props => transparentize(props.theme.colors.mainColor, 0.3)};

  ${media.small`
    padding: 32px 16px 32px;
  `};
`;

export const DescriptionText = styled(Text)`
  ${secondaryFontText};
  padding-left: 13px;
`;

export const DescriptionIcon = styled(Icon)`
  &:before {
    color: ${props => props.theme.colors.mainColor};
    line-height: 2;
  }
`;
