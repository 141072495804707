import responseMappers from './responseMappers';

class ResponseMapper {
  map(question: any) {
    return responseMappers[question.type].map(question);
  }

  unMap(userAnswers: any, question: any) {
    return responseMappers[question.type].unMap(userAnswers, question);
  }
}

export default new ResponseMapper();
