export enum ActionTypes {
  OPEN_LOADER = 'OPEN_LOADER',
  CLOSE_LOADER = 'CLOSE_LOADER'
}

type OpenLoader = {
  type: ActionTypes.OPEN_LOADER
}

type CloseLoader = {
  type: ActionTypes.CLOSE_LOADER
}

export type LoaderActionTypes = OpenLoader | CloseLoader;
