export type ContentState = {
    id: string;
    children: ContentState[];
    value: string;
    url: string;
}

export type ContentsState = {
    [key: string]: ContentState
}

export enum ActionTypes {
    CONTENTS_LOADED = 'CONTENTS_LOADED',
    CONTENT_LOADED = "CONTENT_LOADED",
    CONTENT_LOADING_FAILED = "CONTENT_LOADING_FAILED"
}

type ContentsLoaded = {
    type: ActionTypes.CONTENTS_LOADED,
    payload: {
        contents: any
    }
}
type ContentLoadedSuccess = {
    type: ActionTypes.CONTENT_LOADED;
    payload: {
        contentId: string;
        value: any;
    }
}

type ContentLoadedFailed = {
    type: ActionTypes.CONTENT_LOADING_FAILED;
    payload?: {
        contentId: string;
    }
}


export type ContentsActionTypes =  ContentsLoaded | ContentLoadedSuccess | ContentLoadedFailed;
