import React, { Fragment, PureComponent } from 'react';
import { connect } from 'react-redux';
import { localize } from 'core/localization';
import { ThemeProvider } from 'styled-components';
import {
  isSettingsLoadingFailed,
  getBackground,
  getAlternativeLogo,
  getColors,
  getFonts,
  isTrackingEnabled,
  isScormMode,
  isResource
} from 'store/settings/selectors';
import { getPopupVisibility } from 'store/popup/selectors';
import { isAuthenticated } from 'store/user/selectors';
import { isReviewEnabled } from 'store/modules/selectors';
import { isCourseAccessLimited } from 'store/course/selectors';
import {
  LayoutWrapper,
  PageSidebar,
  PageContentWrapper,
  LogoSidebar,
  LogoSidebarWrapper,
  PageSection,
  ErrorContainer,
  ErrorIcon,
  ErrorTitle,
  ErrorDescription
} from './SomethingWentWrongPage.styled';
import { RootAppState } from 'store/types';
import {
  DEFAULT_SOMETHING_WENT_WRONG_TITLE_TEXT,
  DEFAULT_LOGO_TEXT,
  DEFAULT_PLEASE_TRY_TY_REFRESH_THE_PAGE_TEXT
} from 'constants/defaults';

type SomethingWentWrongPageProps = {
  settingsLoadingFailed: boolean;
  background: { [key: string]: any };
  alternativeLogoUrl: string;
  colors: any;
  fonts: any;
  isUserAuthenticated: boolean;
  popupExpanded: boolean;
  trackingEnabled: boolean;
  isAccessLimited: boolean;
  isScorm: boolean;
  reviewMode: boolean;
  hasUnhandledError: boolean;
  isResourceType: boolean;
};

export class SomethingWentWrongPage extends PureComponent<SomethingWentWrongPageProps, {}> {
  componentDidMount() {
    document.title = !this.props.settingsLoadingFailed
      ? localize('[something went wrong title]')
      : DEFAULT_SOMETHING_WENT_WRONG_TITLE_TEXT;
  }

  render() {
    const { settingsLoadingFailed, background, alternativeLogoUrl, colors, fonts } = this.props;
    return (
      <Fragment>
        <ThemeProvider theme={{ colors, fonts }}>
          <LayoutWrapper {...this.props}>
            <PageSidebar background={background}>
              <LogoSidebarWrapper>
                <LogoSidebar
                  altText={!settingsLoadingFailed ? localize('[logo]') : DEFAULT_LOGO_TEXT}
                  logoUrl={alternativeLogoUrl}
                />
              </LogoSidebarWrapper>
            </PageSidebar>
            <PageContentWrapper background={background}>
              <PageSection>
                <ErrorContainer>
                  <ErrorIcon name="attention" size={64} color="textColor" />
                  <ErrorTitle>
                    {!settingsLoadingFailed
                      ? localize('[something went wrong title]')
                      : DEFAULT_SOMETHING_WENT_WRONG_TITLE_TEXT}
                  </ErrorTitle>
                  <ErrorDescription>
                    {!settingsLoadingFailed
                      ? localize('[please try to refresh the page]')
                      : DEFAULT_PLEASE_TRY_TY_REFRESH_THE_PAGE_TEXT}
                  </ErrorDescription>
                </ErrorContainer>
              </PageSection>
            </PageContentWrapper>
          </LayoutWrapper>
        </ThemeProvider>
      </Fragment>
    );
  }
}

function mapStateToProps(state: RootAppState) {
  return {
    settingsLoadingFailed: isSettingsLoadingFailed(state),
    background: getBackground(state),
    alternativeLogoUrl: getAlternativeLogo(state),
    colors: getColors(state),
    fonts: getFonts(state),
    popupExpanded: getPopupVisibility(state),
    isUserAuthenticated: isAuthenticated(state),
    trackingEnabled: isTrackingEnabled(state),
    isAccessLimited: isCourseAccessLimited(state),
    isScorm: isScormMode(state),
    reviewMode: isReviewEnabled(state),
    isResourceType: isResource(state)
  };
}

export default connect(mapStateToProps)(SomethingWentWrongPage);
