import { events } from 'core/events/eventEmitter';
import BaseEventHandler from 'core/events/eventHandler';
import store from 'store';
import { ActionTypes } from 'store/app/types';

export default class EventHandler extends BaseEventHandler {
  handlers: any[];

  constructor() {
    super();
    this.handlers = [
      {
        event: events.APP_OFFLINE_OR_SERVER_ERROR,
        instance: this.appOfflineOrServerError.bind(this)
      },
      { event: events.APP_ONLINE, instance: this.appOnline.bind(this) }
    ];
  }

  appOfflineOrServerError() {
    store.dispatch({
      type: ActionTypes.APP_OFFLINE_OR_SERVER_ERROR
    });
  }

  appOnline() {
    store.dispatch({
      type: ActionTypes.APP_ONLINE
    });
  }
}
