import { css } from 'styled-components';

const backgroundColor = (color: any) => css`
  background-color: ${color};
`;

const backgroundImage = (
  imageUrl: string,
  position = '0px 0px',
  size = 'auto',
  repeat = 'repeat',
  color = 'transparent'
) => css`
  background-image: url(${imageUrl});
  background-position: ${position};
  background-size: ${size};
  background-repeat: ${repeat};
  ${backgroundColor(color)};
`;

const headerBackgroundImage = (image: any) => {
  let position = '0px 0px';
  let size = 'auto';
  let repeat = 'no-repeat';

  if (image.option === 'fullscreen') {
    position = 'top center';
    size = 'cover';
    repeat = 'no-repeat';
  } else if (image.option === 'repeat') {
    repeat = 'repeat';
  }

  return backgroundImage(image.url, position, size, repeat);
};

export function bodyBackground(body: any) {
  return body.color ? backgroundColor(body.color) : backgroundImage(body.texture);
}

export function headerBackground(header: any) {
  return header.color ? backgroundColor(header.color) : headerBackgroundImage(header.image);
}

export default {
  bodyBackground,
  headerBackground
};
