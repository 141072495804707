import { schema } from 'normalizr';

const resourceContents = new schema.Entity(
  'resourceContents',
  {},
  {
    processStrategy: (value: any, parent: any, key: string) => {
      const result = {
        ...value,
        value: undefined
      };
      result.url = `resourceContent/${value.id}.html`;
      return result;
    }
  }
);

resourceContents.define({
  children: [resourceContents]
});

export default resourceContents;
