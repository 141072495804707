import initialState from 'store/initialState';
import { ActionTypes, PopupActionTypes } from './types';

export default function popupReducer(state = initialState.popup, action: PopupActionTypes) {
  switch (action.type) {
    case ActionTypes.OPEN_POPUP:
      return {
        ...state,
        ...{ isExpanded: !state.isExpanded, settings: action.payload.settings }
      };
    case ActionTypes.CLOSE_POPUP:
      delete state.settings;
      return { ...state, ...{ isExpanded: !state.isExpanded } };
    default:
      return state;
  }
}
