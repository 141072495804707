export enum ActionTypes {
    QUESTION_MATCHING_ANSWERS_ARE_SELECTED = 'QUESTION_MATCHING_ANSWERS_ARE_SELECTED'
}

type AnswersSelected = {
    type: ActionTypes.QUESTION_MATCHING_ANSWERS_ARE_SELECTED,
    payload: {
        answers: any,
        questionId: string
    }
}

export type TextMatchingActionTypes = AnswersSelected;
