import styled from 'styled-components';
import { transparentize } from 'utils/color';
import * as typography from 'components/mixins/typography';
import media from 'components/mixins/media';

export default styled.input`
  min-width: 210px;
  max-width: 280px;
  min-height: 40px;
  padding: 0 20px 0 13px;
  margin: 0  8px;
  border: 1px solid transparent;
  border-radius: 2px;
  box-shadow: inset 0 2px 0 0 ${props => transparentize(props.theme.colors.textColor, 0.08)};
  background-color: ${props => transparentize(props.theme.colors.textColor, 0.02)};
  color: ${props => props.theme.colors.textColor};
  ${typography.secondaryFontText};
  white-space: nowrap;
  word-wrap: normal;
  outline: none;
  overflow: hidden;
  text-overflow: ellipsis;
  text-overflow: ellipsis ellipsis; // fix for firefox
  transition: all 0.1s ease-out;
  
  &:hover,
  &:focus {
    box-shadow: inset 0 2px 0 0 ${props => transparentize(props.theme.colors.textColor, 0.05)};
    border: 1px solid ${props => transparentize(props.theme.colors.mainColor, 0.7)};
    background-color: ${props => props.theme.colors.contentBodyColor};
    outline: none;
  }
  &:active {
    box-shadow: inset 0 2px 0 0 ${props => transparentize(props.theme.colors.textColor, 0.05)};
    border: 1px solid ${props => transparentize(props.theme.colors.mainColor, 0.7)};
    background-color: ${props => props.theme.colors.contentBodyColor};
  }

  &::placeholder {
    color: ${props => transparentize(props.theme.colors.textColor, 0.5)};
  }

  ${media.xsmall`
    max-width: 100%;
    width: 100%;
  `};
}`;
