import progressStorage from 'core/progressStorage';
import { isMeaningful as isMeaningfulString } from 'utils/string';
import {
  isCrossDeviceSavingEnabled,
  isTrackingEnabled,
  isPublishMode
} from '../settings/selectors';
import { RootAppState } from 'store/types';

export const getUser = (state: RootAppState) => state.user;

export const getEmail = (state: RootAppState) => state.user.email;

export const getName = (state: RootAppState) => state.user.name;

export const getAccount = (state: RootAppState) => state.user.account;

export const isAnonymous = (state: RootAppState) => !!state.user.authorizationSkipped;

export const isIntroduced = (state: RootAppState) => isMeaningfulString(getEmail(state));

export const shouldSaveCrossDevice = (state: RootAppState) =>
  isCrossDeviceSavingEnabled(state) && !isAnonymous(state);

export const isAuthenticated = (state: RootAppState) =>
  progressStorage.isAuthenticated() ||
  (!isCrossDeviceSavingEnabled(state) && isAnonymous(state)) ||
  (!isCrossDeviceSavingEnabled(state) && isTrackingEnabled(state) && isIntroduced(state)) ||
  isAnonymous(state) ||
  !isPublishMode(state) ||
  !isTrackingEnabled(state);

export const isAuthorized = (state: RootAppState) => isAuthenticated(state) && !isAnonymous(state);

export const getAvatarLetter = (state: RootAppState) =>
  (state.user.name && state.user.name[0]) || (state.user.email && state.user.email[0]);

export const getUserId = (state: RootAppState) => btoa(state.user?.email || '');
