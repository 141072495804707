import { isLowerResolution } from 'utils/window';
import { MEDIA_TABLET } from 'constants/screenResolutions';
import * as questionActions from 'store/questions/actions';
import { getTreeOfContentVisibility } from 'store/treeOfContent/selectors';

import { ActionTypes } from './types';
import { ThunkResult } from '../types';

export const toggleTreeOfContentVisibility = (): ThunkResult => dispatch => {
  dispatch({ type: ActionTypes.TOC_TOGGLE_VISIBILITY });
};
export const submitAllAnswerOnce = (): ThunkResult => dispatch => {
  dispatch(questionActions.submitAllAtOnce());
};

export const adaptToResolution = (): ThunkResult => (dispatch, getState) => {
  const isTocExpanded = getTreeOfContentVisibility(getState());
  const isLowResolution = isLowerResolution(MEDIA_TABLET);
  if ((isLowResolution && isTocExpanded) || (!isLowResolution && !isTocExpanded)) {
    dispatch({ type: ActionTypes.TOC_TOGGLE_VISIBILITY });
  }
};
