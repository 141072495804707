import { normalize } from 'normalizr';
import questionTypes, { INFORMATION_CONTENT } from 'constants/questionTypes';
import courseSchema from '../schemas/course/course';

/* eslint-disable import/prefer-default-export */
export default {
  map: (data: any, showContentPages: boolean) => {
    const normalized: any = normalize(filterCourseData(data, showContentPages), courseSchema);
    const course: any = normalized.entities.course[normalized.result];
    const { sections, questions, contents } = normalized.entities;

    return {
      course,
      sections,
      questions,
      contents
    };
  }
};

function filterCourseData(data: any, showContentPages: boolean) {
  data.sections = data.sections.filter((section: any) => {
    section.questions = section.questions.filter((question: any) => {
      question.attemptNumber = 0;
      return questionTypes.includes(question.type);
    });
    if (!showContentPages) {
      section.questions = section.questions.filter(
        (question: any) => question.type !== INFORMATION_CONTENT
      );
    }
    return section.questions.length > 0;
  });

  return data;
}
