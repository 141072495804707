import * as React from 'react';
import { localize } from 'core/localization';
import Logo from './CourseLogo.styled';

type CourseLogoProps = {
  logoUrl: string
};
const CourseLogo = (props: CourseLogoProps) => <Logo altText={localize('[alt logo login]')} logoUrl={props.logoUrl} />;

export default CourseLogo;
